import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, OverrideContext, Scope } from "aurelia-framework";
import { OnBenachrichtigungAnzahlRequestEvent, OnBenachrichtigungGelesenEvent } from "../../../framework-data/events";
import { DataSourceService, IViewScrollInfo, ScopeContainer, WebEventService } from "../../../framework/base/export";
import { IListViewOptions } from "../../../framework/forms/elements/list-view/list-view-options";
import { ICommandData } from "../../../framework/forms/export";
import { HeaderCommandEvent, NotificationTyp } from "../../enumerations/export";
import { IdxPopoverComponent } from "../../interfaces/export";
import { CustomEditPopupService, HeaderCommandService, VerknuepfteEntitaetService } from "../../services/export";

@autoinject
export class BenachrichtigungCommand {
  private _commandShowingSubscription: Subscription;
  private _anzahlChangedSubscription: Subscription;

  constructor(
    private _customEditPopupService: CustomEditPopupService,
    private _dataSourceService: DataSourceService,
    private _eventAggregator: EventAggregator,
    private _headerCommandService: HeaderCommandService,
    private _verknuepfteEntitaetService: VerknuepfteEntitaetService,
    private _webEventService: WebEventService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  benachrichtigung: IdxPopoverComponent;
  benachrichtigungOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: 400
  };

  benachrichtigungListviewOptions: IListViewOptions = {
    showReloadButton: false,
    height: "350px",
    hoverStateEnabled: true,
    onItemClick: (e) => {
      const benachrichtigung = e.item;
      this.goToBenachrichtigung(benachrichtigung);
    }
  };

  gelesenBenachrichtigungButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-check fa-fw",
    onClick: (e) => {
      this.onBenachrichtigungGelesen(e.model.bindingContext.item);
      const event: Event = <any>e.event;
      event.stopPropagation();
    }
  };
  verknuepfteEntitaetButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-link fa-fw",
    onClick: (e) => {
      this.onVerknuepfteEntitaetCommandClicked(e.model.bindingContext.item);
      const event: Event = <any>e.event;
      event.stopPropagation();
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.benachrichtigungListviewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/Benachrichtigung",
        webApiExpand: {
          "BenutzerVon": {
            columns: ["Id", "Name"]
          }
        }
      },
      {
        getCustomFilters: () => {
          return [{
            webApiCustomKey: "BenachrichtigungBenutzer",
            webApiCustomValue: "true"
          }];
        }
      }
    );

    this.loadAnzahl();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  attached() {
    this._headerCommandService.benachrichtigung = this;

    this._anzahlChangedSubscription = this._eventAggregator
      .subscribe(
        NotificationTyp.benachrichtigungAnzahlChanged,
        this.loadAnzahl.bind(this)
      );

    this._commandShowingSubscription = this._eventAggregator
      .subscribe(
        HeaderCommandEvent.onShowingPopover,
        (command: ICommandData) => {
          if (this._headerCommandService.benachrichtigungCommand.id != command.id) {
            this.benachrichtigung.instance.hide();
          }
        }
      );
  }
  detached() {
    this._anzahlChangedSubscription.dispose();
    this._commandShowingSubscription.dispose();
  }

  goToBenachrichtigung(benachrichtigung) {
    this._customEditPopupService.benachrichtigungEditPopup.show({
      mappings: { "$id": benachrichtigung.Id },
      viewScrollInfo: this.getViewScrollInfo(benachrichtigung)
    });

    this.benachrichtigung.instance.hide();
  }

  onBenachrichtigungGelesen(benachrichtigung: any) {
    if (benachrichtigung == void 0 || benachrichtigung.Id == void 0) {
      return;
    }

    const webEvent = new OnBenachrichtigungGelesenEvent({ IdBenachrichtigung: benachrichtigung.Id });
    this._webEventService
      .execute(webEvent, true)
      .then(() => {
        this._webEventService
          .execute(new OnBenachrichtigungAnzahlRequestEvent({}), true)
          .then((r) => {
            if (r.Anzahl == 0) {

              this.benachrichtigung.instance.hide();
            } else {

              this.benachrichtigungListviewOptions.dataSource.reload();
            }

            this.loadAnzahl();
          });

      });
  }
  async onVerknuepfteEntitaetCommandClicked(benachrichtigung: any) {
    if (benachrichtigung.IdVerknuepfteEntitaet == void 0) {
      return;
    }

    this.benachrichtigung.instance.hide();

    this._verknuepfteEntitaetService
      .goTo(benachrichtigung.IdVerknuepfteEntitaet);
  }

  showPopover(targetElement: Element) {
    if (this._headerCommandService.benachrichtigungCommand.isEnabled === false) {
      return;
    }
    this.benachrichtigungListviewOptions
      .dataSource
      .reload()
      .done((r) => {
        if (r == void 0 || r.length == 0) {
          return;
        }

        this.benachrichtigung.instance
          .show(targetElement);
      });
  }

  private loadAnzahl() {
    const benachrichtigungEvent = new OnBenachrichtigungAnzahlRequestEvent({ Anzahl: 0 });
    this._webEventService
      .execute(benachrichtigungEvent)
      .then((r) => {
        if (r == void 0 || r.Anzahl == 0) {
          this._headerCommandService.benachrichtigungCommand.isEnabled = false;
          this._headerCommandService.benachrichtigungCommand.badgeText = null;
          return;
        }

        this._headerCommandService.benachrichtigungCommand.isEnabled = true;
        this._headerCommandService.benachrichtigungCommand.badgeText = {
          key: "base.param_1",
          parameters: [r.Anzahl]
        };
      });
  }

  private getViewScrollInfo(benachrichtigung): IViewScrollInfo {
    const dataSource = this.benachrichtigungListviewOptions.dataSource;
    if (!dataSource) {
      return null;
    }

    const lastLoadInfo = (<any>dataSource).lastLoadInfo;
    if (!lastLoadInfo) {
      return null;
    }

    const totalCount = dataSource.totalCount();
    const pageIndex = dataSource.pageIndex();
    const pageSize = dataSource.pageSize();
    const items = dataSource.items();
    const indexOf = items.indexOf(benachrichtigung);

    return {
      lastLoadInfo: lastLoadInfo,
      index: (pageIndex * pageSize) + indexOf,
      maxCount: totalCount
    };
  }
}
