import {
  autoinject,
  bindable,
  computedFrom,
  observable,
  OverrideContext,
  Scope,
  TaskQueue
} from "aurelia-framework";
import {
  KalkulationInputItem,
  KalkulationOutputItem,
  KonditionsfeldWaehrung,
  OnDoKalkulieren,
  OnDoKalkulierenEvent,
  OnKalkulationFeldRequestEvent,
  OnKalkulationItemRequest,
  OnKalkulationItemRequestEvent
} from "../../../framework-data/events";
import {
  BindingService,
  LocalizationService,
  ScopeContainer,
  WebEventService
} from "../../../framework/base/export";
import {
  ModelUtilsService
} from "../../../framework/forms/export";
import {
  KalkulationFeldTyp
} from "../../enumerations/export";
import {
  IKalkulationFeld
} from "../../interfaces/export";
import {
  KonditionService,
  WaehrungService
} from "../../services/export";

@autoinject
export class Kalkulation {
  private DEFAULT_ANZ_KOMMASTELLEN: number = 2;

  constructor(
    private webEvent: WebEventService,
    private binding: BindingService,
    private waehrung: WaehrungService,
    private kondition: KonditionService,
    private taskqueue: TaskQueue,
    private modelUtils: ModelUtilsService,
    private localizationService: LocalizationService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  kalkulationOptions: OnDoKalkulieren;

  @bindable @observable mainModel: any;
  @bindable @observable idBerechnungsversion: number;
  @bindable idFirma: number;
  @bindable idFiliale: number;
  @bindable idLager: number;
  @bindable idArtikel: number;
  @bindable idKunde: number;
  @bindable idLieferant: number;
  @bindable datum: Date;
  @bindable({ attribute: "menge-verp-eh" }) mengeVerpEH: number;
  @bindable idArtikelEinheit: number;
  @bindable idWaehrung: number;
  @bindable steuersatz: number;
  @bindable isGratis: boolean;
  @bindable isFromWebshop: boolean;
  @bindable isKalkulationKopf: boolean;
  @bindable typeName: string;
  @bindable idKopf: number;
  @bindable idPos: number;
  @bindable @observable isReadOnly: boolean;

  @computedFrom("mainModel.IdKalkulationContainer")
  get idKalkulationContainer(): number {
    if (!this.mainModel) {
      return null;
    }

    return this.mainModel.IdKalkulationContainer;
  }
  @computedFrom("isKalkulationKopf", "steuersatz")
  get showNettoSteuerBrutto() {
    return this.isKalkulationKopf
      || this.steuersatz != void (0);
  }

  feldInfo: any = {};
  kalkulationLayoutData: IKalkulationFeld[];

  waehrungOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "ISO4217",
    valueExpr: "ISO4217",
    placeholder: "",
    hint: this.localizationService.translateOnce("kalkulation.waehrung"),
    onInitialized: (e) => {
      this.taskqueue.queueMicroTask(() => {
        const model = (<any>e).model;

        if (model == void 0 || model.bindingContext == void 0 || model.bindingContext.feld == void 0) {
          return;
        }

        const waehrung = model.bindingContext.feld._waehrung;
        const dataSource = this.waehrung.getWaehrungList(waehrung);

        e.component.option("dataSource", dataSource);
      });
    },
    onValueChangedByUser: (e) => {
      this.onFeldValueChanged(e, "WaehrungISO4217");
    },
    bindingOptions: {
      value: "feld.waehrung",
      readOnly: "feld._isReadOnly"
    }
  };
  wertOptions: DevExpress.ui.dxNumberBoxOptions = {
    hint: this.localizationService.translateOnce("kalkulation.wert"),
    onKeyDown: (e: any) => {
      if (!e.event) {
        return;
      }
      if (!e.event.ctrlKey) {
        return;
      }
      if (e.event.keyCode != 46) {
        return;
      }

      const component: DevExpress.ui.dxNumberBox = e.component;
      if (component.option("readOnly")) {
        return;
      }
      if (component.option("disabled")) {
        return;
      }

      e.component.option("value", null);

      if (e.event.preventDefault) {
        e.event.preventDefault();
      }
      if (e.event.stopPropagation) {
        e.event.stopPropagation();
      }

      return;
    },
    onValueChangedByUser: (e) => {
      this.onFeldValueChanged(e, "Wert");
    },
    bindingOptions: {
      format: "feld._format",
      value: "feld.wert",
      readOnly: "feld._isReadOnly"
    }
  };
  nettoWertOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: "#,##0.00",
    readOnly: true,
    bindingOptions: {
      value: "kalkulationOptions.WertNetto"
    }
  };
  steuerWertOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: "#,##0.00",
    readOnly: true,
    bindingOptions: {
      value: "kalkulationOptions.WertSteuer"
    }
  };
  bruttoWertOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: "#,##0.00",
    readOnly: true,
    bindingOptions: {
      value: "kalkulationOptions.WertBrutto"
    }
  };
  customWaehrungOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "ISO4217",
    valueExpr: "Id",
    readOnly: true,
    placeholder: "",
    onInitialized: (e) => {
      this.taskqueue.queueMicroTask(() => {
        const dataSource = this.waehrung.getWaehrungList(KonditionsfeldWaehrung.Alles);
        e.component.option("dataSource", dataSource);
      });
    },
    bindingOptions: {
      value: "idWaehrung"
    }
  };
  wertPerOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: "#,##0",
    hint: this.localizationService.translateOnce("kalkulation.wertper"),
    onValueChangedByUser: (e) => {
      this.onFeldValueChanged(e, "WertPer");
    },
    bindingOptions: {
      value: "feld.wertPer",
      visible: "feld._isWertPerVisible",
      readOnly: "feld._isReadOnly"
    }
  };
  staffelOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: "#,##0",
    hint: this.localizationService.translateOnce("kalkulation.staffel"),
    onValueChangedByUser: (e) => {
      this.onFeldValueChanged(e, "Staffel");
    },
    bindingOptions: {
      value: "feld.staffel",
      visible: "feld._isStaffelVisible",
      readOnly: "feld._isReadOnly"
    }
  };

  async bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    if (this.mainModel && this.mainModel.Id) {
      if (this.idBerechnungsversion) {
        await this.idBerechnungsversionChanged(this.idBerechnungsversion);
      }

      this.mainModelChanged(this.mainModel);
    }
    this.binding.observe({
      scopeContainer: this.scopeContainer,
      expression: "mainModel.CanSave",
      callback: () => {
        this.checkReadOnly();
      }
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  mainModelChanged(newValue) {
    this.taskqueue.queueMicroTask(() => {
      this.doLoadItemsFromContainer();
    });
  }
  isReadOnlyChanged() {
    this.checkReadOnly();
  }
  async idBerechnungsversionChanged(newValue) {
    await this.loadKalkulationFelder(newValue);
    this.restoreKalkulationLayout();
  }

  async doKalkulieren(needsLoadKonditionen: boolean) {
    if (!this.idArtikel && !this.isKalkulationKopf) {
      return;
    }

    const mainModel = this.mainModel;
    const idArtikel = this.idArtikel;

    const kalkulationEventOptions = this.getKalkulationEventOptions(needsLoadKonditionen);
    const kalkulationOptions = await this.webEvent.execute(kalkulationEventOptions, true);

    const hasMainModelOrArtikelChanged = mainModel != this.mainModel
      || idArtikel != this.idArtikel;

    if (this.mainModel && !hasMainModelOrArtikelChanged) {
      const shouldSetInputItemList = needsLoadKonditionen
        || kalkulationOptions.InputList;

      if (shouldSetInputItemList) {
        this.mainModel._InputItemList = kalkulationOptions.InputList;
      }
    } else {
      return;
    }

    if (needsLoadKonditionen) {
      this.kalkulationOptions = kalkulationOptions;
      this.restoreKalkulationLayout();
    } else {
      if (this.kalkulationOptions) {
        this.kalkulationOptions.OutputList = kalkulationOptions.OutputList;
        this.kalkulationOptions.WertNetto = kalkulationOptions.WertNetto;
        this.kalkulationOptions.WertSteuer = kalkulationOptions.WertSteuer;
        this.kalkulationOptions.WertBrutto = kalkulationOptions.WertBrutto;
        this.updateBerechnung();
      }
    }
  }
  async doLoadItemsFromContainer() {
    const idKalkulationContainer = this.idKalkulationContainer;
    if (!idKalkulationContainer) {
      this.kalkulationOptions = null;
      this.restoreKalkulationLayout();
      return;
    }

    const result: OnKalkulationItemRequest = await this.webEvent.execute(new OnKalkulationItemRequestEvent({
      IdKalkulationContainer: idKalkulationContainer
    }), true);

    if (idKalkulationContainer != this.idKalkulationContainer) {
      return;
    }

    const options = this.getKalkulationEventDataOptions(true);
    options.WertNetto = result.WertNetto;
    options.WertSteuer = result.WertSteuer;
    options.WertBrutto = result.WertBrutto;
    options.InputList = result.InputItemList;
    options.FixList = result.FixItemList;
    options.OutputList = result.OutputItemList;
    options.PosList = result.PosItemList;
    options.SteuerPosList = result.SteuerPosList;

    this.kalkulationOptions = options;
    this.restoreKalkulationLayout();
  }
  reset() {
    this.kalkulationLayoutData = [];
  }

  /** Lädt die Beschreibung für die Anzeige der Kalkulation vom Server */
  private async loadKalkulationFelder(idBerechnungsversion) {
    if (idBerechnungsversion == void 0 || idBerechnungsversion == KalkulationFeldTyp.Konditionsfeld) {
      return;
    }

    const kalkulationEvent = new OnKalkulationFeldRequestEvent({
      IdBerechnungsversion: idBerechnungsversion,
      IsKalkulationKopf: this.isKalkulationKopf,
      FeldList: []
    });

    return this.webEvent.execute(kalkulationEvent, true)
      .then((r) => {
        this.feldInfo = {};
        if (r == void 0 || r.length == 0) {
          return;
        }

        r.FeldList.forEach(
          (c) => {
            this.feldInfo[c.Code] = c;
          }
        );
      });
  }

  /** Erzeugt das Layout aufgrund der Felder und Inhalte */
  private restoreKalkulationLayout() {
    this.kalkulationLayoutData = [];

    if (!this.kalkulationOptions || (!this.kalkulationOptions.IdArtikel && !this.isKalkulationKopf)) {
      this.kalkulationLayoutData = null;
      return;
    }

    this.addKondition(this.kalkulationOptions.InputList);
    this.addBerechnung(this.kalkulationOptions.OutputList);
    this.addFehlendeFelder();
    this.checkReadOnly();
    this.sortLayout();
  }
  /** Fügt die Konditionen aus der InputList in das Layout hinzu */
  private addKondition(konditionList: KalkulationInputItem[]) {
    konditionList.forEach((kondition) => {
      const feld = this.createLayoutFeld(kondition.Code);
      if (!feld) {
        return;
      }

      feld.wert = kondition.Wert;
      feld.waehrung = kondition.WaehrungISO4217;
      feld.wertPer = kondition.WertPer;
      feld.staffel = kondition.Staffel;

      feld._inputKondition = kondition;

      this.kalkulationLayoutData.push(feld);
    });
  }
  /** Fügt die Berechnungsergebnisse aus der OutputList in das Layout hinzu */
  private addBerechnung(berechnungList: KalkulationOutputItem[]) {
    berechnungList.forEach((berechnung) => {
      const feld = this.createLayoutFeld(berechnung.Code);
      if (!feld) {
        return;
      }

      feld.wert = berechnung.Wert;
      feld.waehrung = berechnung.WaehrungISO4217;
      feld.wertPer = berechnung.WertPer;
      feld.staffel = berechnung.Staffel;
      feld._isWertPerVisible = berechnung.WertPer != void (0);
      feld._isStaffelVisible = berechnung.Staffel != void (0);

      this.kalkulationLayoutData.push(feld);
    });
  }
  /** Ergänzt das Layout um fehlende Konditionsfelder */
  private addFehlendeFelder() {
    for (const code in this.feldInfo) {
      const feldInfo = this.feldInfo[code];

      if (!feldInfo.IsSichtbar) {
        continue;
      }
      if (feldInfo.Typ != KalkulationFeldTyp.Konditionsfeld) {
        continue;
      }

      const exists = this.kalkulationLayoutData.some((c) => c._code == code);
      if (exists) {
        continue;
      }

      const feld = this.createLayoutFeld(code);
      this.kalkulationLayoutData.push(feld);
    }
  }
  /** Prüft welche Felder editierbar sind und setzt entsprechend die Eigenschaft */
  private checkReadOnly() {
    if (!this.kalkulationLayoutData) {
      return;
    }

    for (const item of this.kalkulationLayoutData) {
      item._isReadOnly = this.isReadOnly
        || (this.mainModel && this.mainModel.CanSave === false)
        || !item._isAenderbar;
    }
  }
  /** Sortiert die Elemente im Layout */
  private sortLayout() {
    this.kalkulationLayoutData.sort((a, b) => {
      if (a._sortNr < b._sortNr) {
        return -1;
      } else if (a._sortNr > b._sortNr) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  /** Erzeugt ein Feld für das Layout (ohne Wert, Wert per, Staffel und Währung) */
  private createLayoutFeld(code: string): IKalkulationFeld {
    const feldInfo = this.feldInfo[code];
    if (!feldInfo || !feldInfo.IsSichtbar) {
      return null;
    }

    return {
      bezeichnung: feldInfo.Bezeichnung,
      waehrung: feldInfo.VorbWaehrungISO4217 || null,
      _waehrung: feldInfo.Waehrung,
      _isWertPerVisible: feldInfo.HasWertPer,
      _isStaffelVisible: feldInfo.HasStaffel,
      _sortNr: feldInfo.SortNr,
      _code: feldInfo.Code,
      _typ: feldInfo.Typ,
      _isAenderbar: feldInfo.IsAenderbar,
      _format: this.getFormat(feldInfo.AnzKommastellen)
    };
  }

  private getKalkulationEventOptions(needsLoadKonditionen: boolean) {
    return new OnDoKalkulierenEvent(this.getKalkulationEventDataOptions(needsLoadKonditionen));
  }
  private getKalkulationEventDataOptions(needsLoadKonditionen: boolean): OnDoKalkulieren {
    return {
      IdFirma: this.idFirma,
      IdFiliale: this.idFiliale,
      IdLager: this.idLager,
      IdWaehrung: this.idWaehrung,
      IdArtikel: this.idArtikel,
      IdKunde: this.idKunde,
      IdLieferant: this.idLieferant,
      IdBerechnungsversion: this.idBerechnungsversion,
      MengeVerrVerpEH: this.mengeVerpEH,
      Steuersatz: this.steuersatz,
      IdArtikelEinheit: this.idArtikelEinheit,
      LoadKonditionen: needsLoadKonditionen,
      IsKalkulationKopf: !!this.isKalkulationKopf,
      TypeName: this.typeName,
      IdKopf: this.idKopf,
      IdPos: this.idPos,
      IsGratis: !!this.isGratis,
      IsFromWebshop: !!this.isFromWebshop,
      Datum: this.datum || new Date(),
      PosList: this.getPosList(),
      SteuerPosList: this.getSteuerPosList(),
      InputList: this.getInputList(),
      FixList: this.getFixList()
    };
  }
  private getPosList(): any[] {
    const posList = this.kalkulationOptions != void 0
      ? this.kalkulationOptions.PosList
      : null;

    if (posList == void 0 || !posList.length) {
      return null;
    }

    return posList;
  }
  private getSteuerPosList(): any[] {
    const steuerPosList = this.kalkulationOptions != void 0
      ? this.kalkulationOptions.SteuerPosList
      : null;

    if (steuerPosList == void 0 || !steuerPosList.length) {
      return null;
    }

    return steuerPosList;
  }
  private getInputList(): any[] {
    const inputList = this.kalkulationOptions != void 0
      ? this.kalkulationOptions.InputList
      : null;

    if (inputList == void 0 || !inputList.length) {
      return null;
    }

    return inputList;
  }
  private getFixList(): any[] {
    const fixList = this.kalkulationOptions != void 0
      ? this.kalkulationOptions.FixList
      : null;

    if (fixList == void 0 || !fixList.length) {
      return null;
    }

    return fixList;
  }
  private getFormat(anzKommastellen: number) {
    if (anzKommastellen == void (0)) {
      anzKommastellen = this.DEFAULT_ANZ_KOMMASTELLEN;
    }

    let format = "#,##0";

    if (anzKommastellen > 0) {
      format += ".".concat("0".repeat(anzKommastellen));
    }

    return format;
  }

  private updateKalkulation(feld: IKalkulationFeld, propertyNameToUpdate: string, newFeldValue: any) {
    this.updateFeldInputList(feld, propertyNameToUpdate, newFeldValue);

    if (this.mainModel) {
      this.mainModel._InputItemList = this.getInputList();
      this.modelUtils.setDirty(this.mainModel);
    }

    this.doKalkulieren(false);
  }
  private updateBerechnung() {
    const updatedOutput = {};
    this.kalkulationOptions.OutputList.map((c) => updatedOutput[c.Code] = c);

    this.kalkulationLayoutData.map((feld, index, arr) => {
      if (feld._typ != KalkulationFeldTyp.Berechnungsfeld) {
        return;
      }

      this.updateFeldWerte(feld, updatedOutput[feld._code]);
    });
  }
  private updateFeldWerte(feld: IKalkulationFeld, kalkulationOutput: KalkulationOutputItem) {
    if (kalkulationOutput == void 0 || feld == void 0) {
      return;
    }

    if (feld.wert != kalkulationOutput.Wert) {
      feld.wert = kalkulationOutput.Wert;
    }

    feld._isWertPerVisible = kalkulationOutput.WertPer != void (0);
    feld._isStaffelVisible = kalkulationOutput.Staffel != void (0);

    if (feld.wertPer != kalkulationOutput.WertPer) {
      feld.wertPer = kalkulationOutput.WertPer;
    }
    if (feld.waehrung != kalkulationOutput.WaehrungISO4217) {
      feld.waehrung = kalkulationOutput.WaehrungISO4217;
    }
    if (feld.staffel != kalkulationOutput.Staffel) {
      feld.staffel = kalkulationOutput.Staffel;
    }
  }
  private updateFeldInputList(feld: IKalkulationFeld, propertyNameToUpdate: string, newValue: any) {
    if (feld == void 0 || propertyNameToUpdate == void 0) {
      return;
    }

    let konditionToUpdate = this.kalkulationOptions
      .InputList
      .find((f, index, arr) => {
        return f === feld._inputKondition;
      });

    if (konditionToUpdate == void 0) {
      konditionToUpdate = {
        Id: 0,
        Code: feld._code,
        Wert: feld.wert,
        WaehrungISO4217: feld.waehrung,
        WertPer: feld.wertPer,
        Staffel: feld.staffel,
        IsManuell: true
      };

      feld._inputKondition = konditionToUpdate;
      this.kalkulationOptions.InputList.push(konditionToUpdate);

    }

    konditionToUpdate[propertyNameToUpdate] = newValue;
    konditionToUpdate.IsManuell = true;
  }

  private onFeldValueChanged(e: any, propertyNameToChange: string) {
    const feld = e.model.bindingContext.feld;

    if (feld._typ == KalkulationFeldTyp.Berechnungsfeld) {
      return;
    }

    this.updateKalkulation(feld, propertyNameToChange, e.value);
  }
}
