import { autoinject, OverrideContext, Scope } from "aurelia-framework";
import { OnAufgabeErledigenRequestEvent } from "../../../../framework-data/events";
import { DataSourceService, IViewScrollInfo, ScopeContainer, WebEventService } from "../../../../framework/base/export";
import { IListViewOptions, ListView } from "../../../../framework/forms/elements/list-view/export";
import { Statustyp } from "../../../enumerations/export";
import { CustomEditPopupService, EntitaetInfoService } from "../../../services/export";
import { IWidgetModel } from '../../interfaces/widget-model';
import * as moment from "moment";

@autoinject
export class AufgabeTags {
  private _aufgabeBenutzer: boolean = true;

  constructor(
    private _customEditPopupService: CustomEditPopupService,
    private _dataSourceService: DataSourceService,
    private _entitaetInfoService: EntitaetInfoService,
    private _webEventService: WebEventService
  ) { }

  idVerknuepfteEntitaet: number;
  aufgabeUrl: string;

  scope: Scope;
  scopeContainer: ScopeContainer;
  heute: Date;

  widgetModel: IWidgetModel;

  aufgabeListView: ListView;
  aufgabeListViewOptions: IListViewOptions = {
    showReloadButton: false,
    height: "100%",
    pageSize: 15,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      const aufgabe = e.item;
      this.showAufgabePopup(aufgabe);
    }
  }

  aufgabeStatusAendernButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-check fa-fw",
    onClick: (e) => {
      const event: Event = <any>e.event;
      event.stopPropagation();

      this.erledigenAufgabe(e.model.bindingContext.item);
    }
  }

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.aufgabeListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiColumns: ["Id", "Betreff", "FaelligAm", "ErinnernAm", "IdCboGeschaeftspartner", "IdSachbearbeiter", "IsPrioritaet"],
        webApiAction: "ERP/Stammdaten/Aufgabe",
        webApiWhere: ["Status.Typ", Statustyp.Offen],
        webApiExpand: {
          CboGeschaeftspartner: {
            columns: ["Id", "CboBezeichnung"]
          },
          Sachbearbeiter: {
            columns: ["Id", "Name"]
          }
        },
        filters: [{
          webApiCustomKey: "InklIdStatusFarbe",
          webApiCustomValue: "true"
        }, {
          webApiCustomKey: "AddTags",
          webApiCustomValue: "true"
        }]
      },
      {
        getCustomFilters: () => {
          if (!this._aufgabeBenutzer) {
            return;
          }

          return [{ webApiCustomKey: "AufgabeBenutzer", webApiCustomValue: "true" }];
        }
      }
    );

    this._entitaetInfoService.getUrl("TIP.ERP.Business.Entitaeten.Stammdaten.Aufgabe")
      .then(r => {
        this.aufgabeUrl = r;
      });

    this.heute = moment().startOf("day").toDate();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }
  showAufgabePopup(aufgabe) {
    this._customEditPopupService
      .aufgabeEditPopup
      .show({
        mappings: { "$id": aufgabe.Id },
        viewScrollInfo: this.getViewScrollInfo(aufgabe)
      });
  }

  private erledigenAufgabe(aufgabe: any) {
    if (aufgabe == void 0 || aufgabe.Id == void 0) {
      return;
    }

    const aufgabeErledigenEvent = new OnAufgabeErledigenRequestEvent({ IdAufgabe: aufgabe.Id });

    this._webEventService
      .execute(aufgabeErledigenEvent, true)
      .then(r => {
        this.aufgabeListViewOptions.dataSource.reload();
      })
  }
  private getViewScrollInfo(aufgabe): IViewScrollInfo {
    const dataSource = this.aufgabeListViewOptions.dataSource;
    if (!dataSource) {
      return null;
    }

    const lastLoadInfo = (<any>dataSource).lastLoadInfo;
    if (!lastLoadInfo) {
      return null;
    }

    const totalCount = dataSource.totalCount();
    const pageIndex = dataSource.pageIndex();
    const pageSize = dataSource.pageSize();
    const items = dataSource.items();
    const indexOf = items.indexOf(aufgabe);

    return {
      lastLoadInfo: lastLoadInfo,
      index: (pageIndex * pageSize) + indexOf,
      maxCount: totalCount
    };
  }
}
