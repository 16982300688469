import {
  autoinject,
  computedFrom,
  transient
} from "aurelia-framework";
import {
  LocalizationService,
  LocationService,
  WebEventService
} from "../../../../framework/base/export";
import {
  FormBase, PopupInfoService
} from "../../../../framework/forms/export";
import { CalcLiefertermin } from '../../../elements/calc-liefertermin/calc-liefertermin';
import { BelegImportTyp } from '../../../enumerations/beleg-import-typ';
import { IdxSelectBoxComponent } from '../../../interfaces/export';
import { ArtikelService, DynFelderDataService, EntitaetInfoService, StartupService } from '../../../services/export';
import {
  OnArtikelartAendern,
  OnArtikelartAendernEvent,
  OnBVRechnen,
  OnBVRechnenEvent,
  OnErstelleBestellungEvent,
  OnErstelleProdAuftragEvent
} from "./../../../../framework-data/events";
import { ICommandData } from './../../../../framework/forms/interfaces/command-data';
import { IdxPopupComponent } from './../../../interfaces/dx-components';
import { BelegExportService } from './../../../services/beleg-export-service';
import { ArtikelBezugTyp } from "../../../enumerations/artikel-bezug-typ";
import { AsyncService } from "../../../../framework/base/services/async-service";

@autoinject
@transient()
export class ArtikelEditUtils {

  constructor(
    public dynFelderData: DynFelderDataService,
    public startup: StartupService,
    private _asyncService: AsyncService,
    private _artikelService: ArtikelService,
    private _webEventService: WebEventService,
    private _localizationService: LocalizationService,
    private _entitaetInfoService: EntitaetInfoService,
    private _popupInfoService: PopupInfoService,
    private _locationService: LocationService,
    private _belegExportService: BelegExportService
  ) { }

  form: FormBase;
  isBelegExportMandantAktiv: boolean;
  doCopyCommandExecute: { (idSource: number): void };

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "beleg-export",
    sortIndex: 999,
    isVisibleExpression: "functions.$f_ArtikelEditUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_ArtikelEditUtils.isBelegExportMandantAktiv && models.data.$m_Artikel.Id > 0",
    title: "erp.beleg-export",
    execute: async () => {
      const id = this.form.models.data.$m_Artikel.Id;
      this._belegExportService.export(BelegImportTyp.Artikel, [id]);
    }
  };
  artikelBestellungErstellenCommand: ICommandData = {
    id: "artikelBestellungErstellen",
    idCategory: "artikel",
    icon: "plus-circle",
    title: "artikel-edit-utils.bestellung_erstellen",
    sortIndex: 0,
    isVisibleExpression: "models.data.$m_Artikel.CanSave && models.data.$m_Artikel.Id > 0 && models.data.$m_Artikel.BezugTyp != 3 && models.data.$m_Artikel.BezugTyp != 1 && models.data.$m_Artikel.BezugTyp != 2",
    execute: async (e) => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const idArtikel = this.form.models.data.$m_Artikel.Id
      const webEvent = new OnErstelleBestellungEvent({ IdArtikel: idArtikel });

      const webEventResult = await this._webEventService.execute(webEvent, true);
      if (!webEventResult) {
        return;
      }
      this._locationService.goTo({
        url: `ERP/Einkauf/Bestellung/${webEventResult.IdBestellung}`,
        clearStack: false
      });
    }
  }
  artikelProdAuftragErstellenCommand: ICommandData = {
    id: "artikelProdAuftragErstellen",
    idCategory: "artikel",
    icon: "plus-circle",
    title: "artikel-edit-utils.prod_auftrag_erstellen",
    sortIndex: 0,
    isVisibleExpression: "models.data.$m_Artikel.CanSave && models.data.$m_Artikel.Id > 0 && (models.data.$m_Artikel.BezugTyp == 1 || models.data.$m_Artikel.BezugTyp == 2)",
    execute: async (e) => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const idArtikel = this.form.models.data.$m_Artikel.Id
      const webEvent = new OnErstelleProdAuftragEvent({ IdArtikel: idArtikel });

      const webEventResult = await this._webEventService.execute(webEvent, true);
      if (!webEventResult) {
        return;
      }
      this._locationService.goTo({
        url: `ERP/Produktion/ProdAuftrag/${webEventResult.IdProdAuftrag}`,
        clearStack: false
      });
    }
  }
  doCopyCommand: ICommandData = {
    id: "doCopyCommand",
    icon: "clone",
    title: "artikel-edit-utils.artikel-kopie-erstellen",
    isEnabledExpression: "models.data.$m_ArtikelCopy.IdArtikelart",
    execute: null
  };
  doBestellvorschlagBerechnenCommand: ICommandData = {
    id: "doBestellvorschlagBerechnen",
    idCategory: "artikel_bestellvorschlag",
    icon: "calculator",
    title: "artikel-edit-utils.bestellvorschlag_berechnen",
    sortIndex: 950,
    isEnabledExpression: "models.data.$m_Artikel.IsLagerfuehrend == true  && models.data.$m_Artikel.BestellvorschlagTyp > 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const onBVRechnenEvent = new OnBVRechnenEvent({
        IdArtikelList: [this.form.models.data.$m_Artikel.Id]
      });

      const webEventResult: OnBVRechnen = await this._webEventService.execute(onBVRechnenEvent, true);
      return DevExpress.ui.notify(this._localizationService.translateOnce("artikel-edit-utils.berechn_ausführ_weitergeleitet"), "SUCCESS", 3000);
    }
  };
  doArtikelartAendernCommand: ICommandData = {
    id: "doArtikelartAendern",
    icon: "floppy-o",
    title: "base.save",
    sortIndex: 955,
    execute: async () => {
      this.doArtikelartaendern();
    }
  }
  showArtikelartAendernCommand: ICommandData = {
    id: "showArtikelartAendern",
    idCategory: "artikel_art",
    icon: "pencil-square-o",
    title: "artikel-edit-utils.artikelart_aendern",
    sortIndex: 955,
    isEnabledExpression: "models.data.$m_Artikel.Id > 0",
    execute: this.onShowArtikelartAendernClicked.bind(this)
  }
  calcLieferterminCommand: ICommandData = {
    id: "calcLieferterminCommand",
    idCategory: "artikel_bestellvorschlag",
    icon: "calendar",
    title: "calc-liefertermin.lieferdatum-berechnen",
    sortIndex: 955,
    isEnabledExpression: "models.data.$m_Artikel.Id > 0",
    execute: () => {
      const calcLiefertermin: CalcLiefertermin = this.form["r_calcLiefertermin"];
      calcLiefertermin.show(this.form.models.data.$m_Artikel.Id);
    }
  }

  @computedFrom("form.r_tabSelected")
  get isDashboardVisible() {
    if (!this.form) {
      return false;
    }

    const options: DevExpress.ui.dxTabsOptions = this.form["r_tabOptions"];
    if (!options || !options.items) {
      return false;
    }

    const indexOf = options.items.findIndex(p => {
      return !!p["__options"]
        && p["__options"].id == "r_tabDashboard"
    });

    if (indexOf < 0) {
      return false;
    }

    return indexOf == this.form["r_tabSelected"];
  }

  bind(form: FormBase) {
    this.form = form;

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Artikel",
      idArtPropertyName: "IdArtikelart"
    });

    form.models.onLoaded.register(async (r) => {
      if (r == void 0 || r.model.id != "$m_Artikel") {
        return;
      }

      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Artikel);

      return Promise.resolve();
    });

    this.form.onSaving.register(async (r) => {
      if (r.form.models.hasChangedData()) {
        if (this.form.models.data.$m_Artikel.Id > 0 && (this.form.models.data.$m_Artikel.BezugTyp == ArtikelBezugTyp.Setartikel || this.form.models.data.$m_Artikel.BezugTyp == ArtikelBezugTyp.Eigenfertigung)) {
          const hasBeistellartikel = await this._artikelService.hasArtikelBeistellartikel(this.form.models.data.$m_Artikel.Id);

          if (hasBeistellartikel) {
            const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
              this._localizationService.translateOnce("artikel-edit-utils.hinweis-setartikel-beistellartikel"),
              this._localizationService.translateOnce("base.question")));

            if (!doExecute) {
              return Promise.reject();
            }
          }
          return Promise.resolve();
        }

        return Promise.resolve();
      }
    });

    this.form.binding.observe({
      scopeContainer: this.form.scopeContainer,
      expression: "models.data.$m_EinstandspreisEdit",
      callback: async (newValue) => {
        if (!newValue) {
          return;
        }
        if (!newValue.VerknuepfteEntitaet) {
          return;
        }

        this._entitaetInfoService
          .getUrl(newValue.VerknuepfteEntitaet.TypeName, newValue.VerknuepfteEntitaet.Id)
          .then(r => {
            this._popupInfoService.closeAllPopups().then(pr => {
              if (!pr) {
                return;
              }

              this._locationService.goTo({
                url: r
              });
            });
          });
      }
    });
  }

  copyArtikel($delegate: { (customData: any): Promise<any> }) {
    const copyPopup: IdxPopupComponent = this.form["r_copyPopup"];

    this.form.models.data.$m_ArtikelCopy = {
      IdArtikelart: this.form.models.data.$m_Artikel.IdArtikelart,
      DoKopierenNotizen: false,
      DoKopierenKonditionen: false
    };

    copyPopup.instance
      .show()
      .then(() => {
        this.form["r_artikelCopyNummer"].instance.focus();
      });

    this.doCopyCommand.execute = () => {
      $delegate(this.form.models.data.$m_ArtikelCopy)
        .then(r => {
          copyPopup.instance.hide();
        });
    };
  }

  onEinstandspreisVerknEntitaetCellPrepared(e) {
    if (e.rowType != "data") {
      return;
    }

    if (!e.data || !e.data.VerknuepfteEntitaet) {
      return;
    }

    const cell: HTMLTableCellElement = e.cellElement;
    const verkn = e.data.VerknuepfteEntitaet;

    const html = `<div class="erp--text-with-leading-label-badge">
      <div class="${verkn.TypeBezeichnung ? "" : "erp--empty-badge"}">
        ${verkn.TypeBezeichnung ? verkn.TypeBezeichnung : ""}
      </div>
      <div>
        ${verkn.CboBezeichnung ? verkn.CboBezeichnung : ""}
      </div>
    </div>`;

    cell.innerHTML = html;
  }

  async onShowArtikelartAendernClicked() {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const selectBox: IdxSelectBoxComponent = this.form["r_artikelartEdit"];
    if (selectBox) {
      const dataSource = selectBox.instance.getDataSource();
      dataSource.reload();

      selectBox.setOption({ value: null });
    }

    const popup: IdxPopupComponent = this.form["r_artikelArtPopup"];
    popup.instance.show();
  }

  async onArtikelartChanged(e) {
    if (this.form.models.data.$m_Artikel.CanSave && this.form.models.data.$m_Artikel.Id > 0) {
      return;
    }

    if (this.form.models.data.$m_Artikel.IdArtikelart == null) {
      return;
    }

    const artikelart = await this._artikelService.getArtikelartById(this.form.models.data.$m_Artikel.IdArtikelart);
    if (artikelart != null) {
      this.form.models.data.$m_Artikel.IsLagerfuehrend = artikelart.IsLagerfuehrendVorbelegung;
    }
  }

  async doArtikelartaendern() {
    const popup: IdxPopupComponent = this.form["r_artikelArtPopup"];
    const onArtikelartAendernEvent = new OnArtikelartAendernEvent({
      IdArtikel: this.form.models.data.$m_Artikel.Id,
      IdArtikelart: this.form["r_artikelartEdit"].instance.option("value")
    });

    if (!this.form["r_artikelartEdit"].instance.option("value")) {
      DevExpress.ui.notify(this._localizationService.translateOnce("artikel-edit-utils.keine_artikelart_ausgewaehlt"), "error", 3000);
      return;
    }

    const result: OnArtikelartAendern = await this._webEventService.execute(onArtikelartAendernEvent, true);

    this.form.models.reloadAll();

    DevExpress.ui.notify(this._localizationService.translateOnce("artikel-edit-utils.artikelart_geandert"), "SUCCESS", 3000);
    popup.instance.hide();
  }
}
