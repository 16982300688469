import { autoinject, Scope, OverrideContext } from "aurelia-framework";
import { FormBase } from "./../../../framework/forms/classes/form-base";
import { RestService } from "./../../../framework/base/services/rest-service";

@autoinject
export class Infobar {
  constructor(
    private rest: RestService
  ) { }

  scope: Scope;
  form: FormBase;

  data: any;
  idGeschaeftspartner: number;
  idRechnungsort: number;
  idArtikel: number;
  idFiliale: number;
  idProjekt: number;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.form = bindingContext;

    this.form.callOnBind(() => {
      if (this.form.models.modelWithKeyId) {
        this.observe(null);
        this.observe("IdKunde");
        this.observe("IdLieferant");
        this.observe("IdGeschaeftspartner");
        this.observe("IdRechnungsort");
        this.observe("IdArtikel");
        this.observe("IdLieferfiliale");
        this.observe("IdCboProjekt");

        switch (this.form.models.modelWithKeyId.webApiAction) {
          case "ERP/Verkauf/Angebotsposition": {
            this.observe("IdAngebot");
            break;
          }
          case "ERP/Verkauf/Auftragsposition": {
            this.observe("IdAuftrag");
            break;
          }
          case "ERP/Verkauf/Lieferscheinposition": {
            this.observe("IdLieferschein");
            break;
          }
          case "ERP/Verkauf/Fakturaposition": {
            this.observe("IdFaktura");
            break;
          }
          case "ERP/Verkauf/Kundenreklamationsposition": {
            this.observe("IdKundenreklamation");
            break;
          }
          case "ERP/Einkauf/Bestellungsposition": {
            this.observe("IdBestellung");
            break;
          }
          case "ERP/Einkauf/Warenuebernahmeposition": {
            this.observe("IdWarenuebernahme");
            break;
          }
          case "ERP/Einkauf/Eingangsrechnungsposition": {
            this.observe("IdEingangsrechnung");
            break;
          }
          case "ERP/Lager/Artikelverleih": {
            this.observe("IdFiliale");
            break;
          }
        }
      }
    });
  }
  unbind() {
    this.scope = null;
  }

  async dataChanged(propertyName: string) {
    if (!this.data || !propertyName) {
      this.idGeschaeftspartner = null;
      this.idArtikel = null;
      this.idFiliale = null;
      this.idProjekt = null;
      this.idRechnungsort = null;
    }

    if (!this.data) {
      return;
    }

    switch (this.data.TypeName) {
      case "TIP.ERP.Business.Entitaeten.Verkauf.Angebot":
      case "TIP.ERP.Business.Entitaeten.Verkauf.Auftrag":
      case "TIP.ERP.Business.Entitaeten.Verkauf.Lieferschein":
      case "TIP.ERP.Business.Entitaeten.Verkauf.Kundenreklamation": {
        if (!propertyName || propertyName == "IdKunde") {
          this.idGeschaeftspartner = this.data.IdKunde;
        }
        if (!propertyName || propertyName == "IdLieferfiliale") {
          this.idFiliale = this.data.IdLieferfiliale;
        }
        if (!propertyName || propertyName == "IdCboProjekt") {
          this.idProjekt = this.data.IdCboProjekt;
        }
        if (!propertyName || propertyName == "IdRechnungsort") {
          this.idRechnungsort = this.data.IdRechnungsort;
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Angebotsposition": {
        if (!propertyName || propertyName == "IdArtikel") {
          this.idArtikel = this.data.IdArtikel;
        }
        if ((!propertyName || propertyName == "IdAngebot") && this.data.IdAngebot) {
          this.idFiliale = await this.getIdLieferfiliale("ERP/Verkauf/Angebot", this.data.IdAngebot);
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Auftragsposition": {
        if (!propertyName || propertyName == "IdArtikel") {
          this.idArtikel = this.data.IdArtikel;
        }
        if ((!propertyName || propertyName == "IdAuftrag") && this.data.IdAuftrag) {
          this.idFiliale = await this.getIdLieferfiliale("ERP/Verkauf/Auftrag", this.data.IdAuftrag);
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Lieferscheinposition": {
        if (!propertyName || propertyName == "IdArtikel") {
          this.idArtikel = this.data.IdArtikel;
        }
        if ((!propertyName || propertyName == "IdLieferschein") && this.data.IdLieferschein) {
          this.idFiliale = await this.getIdLieferfiliale("ERP/Verkauf/Lieferschein", this.data.IdLieferschein);
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Kundenreklamationsposition": {
        if (!propertyName || propertyName == "IdArtikel") {
          this.idArtikel = this.data.IdArtikel;
        }
        if ((!propertyName || propertyName == "IdKundenreklamation") && this.data.IdKundenreklamation) {
          this.idFiliale = await this.getIdLieferfiliale("ERP/Verkauf/Kundenreklamation", this.data.IdKundenreklamation);
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Faktura":
      case "TIP.ERP.Business.Entitaeten.Verkauf.Mahnung": {
        if (!propertyName || propertyName == "IdRechnungsort") {
          this.idGeschaeftspartner = this.data.IdRechnungsort;
          this.idRechnungsort = this.data.IdRechnungsort;
        }
        if (!propertyName || propertyName == "IdCboProjekt") {
          this.idProjekt = this.data.IdCboProjekt;
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Einkauf.Bestellung":
      case "TIP.ERP.Business.Entitaeten.Einkauf.Warenuebernahme":
      case "TIP.ERP.Business.Entitaeten.Einkauf.Eingangsrechnung": {
        if (!propertyName || propertyName == "IdLieferant") {
          this.idGeschaeftspartner = this.data.IdLieferant;
        }
        if (!propertyName || propertyName == "IdLieferfiliale") {
          this.idFiliale = this.data.IdLieferfiliale;
        }
        if (!propertyName || propertyName == "IdCboProjekt") {
          this.idProjekt = this.data.IdCboProjekt;
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Einkauf.Bestellungsposition": {
        if (!propertyName || propertyName == "IdArtikel") {
          this.idArtikel = this.data.IdArtikel;
        }
        if ((!propertyName || propertyName == "IdBestellung") && this.data.IdBestellung) {
          this.idFiliale = await this.getIdLieferfiliale("ERP/Einkauf/Bestellung", this.data.IdBestellung);
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Einkauf.Warenuebernahmeposition": {
        if (!propertyName || propertyName == "IdArtikel") {
          this.idArtikel = this.data.IdArtikel;
        }
        if ((!propertyName || propertyName == "IdWarenuebernahme") && this.data.IdWarenuebernahme) {
          this.idFiliale = await this.getIdLieferfiliale("ERP/Einkauf/Warenuebernahme", this.data.IdWarenuebernahme);
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Produktion.ProdAuftrag": {
        if (!propertyName || propertyName == "IdArtikel") {
          this.idArtikel = this.data.IdArtikel;
        }
        if (!propertyName || propertyName == "IdLieferfiliale") {
          this.idFiliale = this.data.IdLieferfiliale;
        }
        if (!propertyName || propertyName == "IdCboProjekt") {
          this.idProjekt = this.data.IdCboProjekt;
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Einkauf.Bestellvorschlag": {
        if (!propertyName || propertyName == "IdArtikel") {
          this.idArtikel = this.data.IdArtikel;
        }
        if (!propertyName || propertyName == "IdLieferfiliale") {
          this.idFiliale = this.data.IdLieferfiliale;
        }
        if (!propertyName || propertyName == "IdCboProjekt") {
          this.idProjekt = this.data.IdCboProjekt;
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Lager.Artikelverleih": {
        if (!propertyName || propertyName == "IdArtikel") {
          this.idArtikel = this.data.IdArtikel;
        }
        if (!propertyName || propertyName == "IdFiliale") {
          this.idFiliale = this.data.IdFiliale;
        }
        if (!propertyName || propertyName == "IdCboProjekt") {
          this.idProjekt = this.data.IdCboProjekt;
        }
        if (!propertyName || propertyName == "IdKunde") {
          this.idGeschaeftspartner = this.data.IdKunde;
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner": {
        if (!propertyName) {
          this.idGeschaeftspartner = this.data.Id;
        }
        if (!propertyName && this.data && this.data.Id && this.data.Geschaeftspartnerart && this.data.Geschaeftspartnerart.IsRechnungsadresse) {
          this.idRechnungsort = this.data.Id;
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel": {
        if (!propertyName) {
          this.idArtikel = this.data.Id;
        }
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Stammdaten.MailImportItem": {
        if (!propertyName || propertyName == "IdGeschaeftspartner") {
          this.idGeschaeftspartner = this.data.IdGeschaeftspartner;
        }
        if (!propertyName || propertyName == "IdCboProjekt") {
          this.idProjekt = this.data.IdCboProjekt;
        }
        break;
      }
      default: break;
    }
  }

  private observe(propertyName: string) {
    let property = `models.data.${this.form.models.modelWithKeyId.id}`;

    if (propertyName) {
      property += ".".concat(propertyName);
    }

    this.form.binding.observe({
      scopeContainer: this.form.scopeContainer,
      expression: property,
      callback: (newVal) => {
        if (!propertyName) {
          this.data = newVal;
        }

        this.dataChanged(propertyName);
      }
    });
  }
  private async getIdLieferfiliale(action: string, id: number): Promise<number> {
    const r = await this.rest.get({
      url: this.rest.getWebApiUrl(`${action}/${id}`),
      getOptions: {
        columns: ["IdLieferfiliale"]
      }
    });

    if (r && r.IdLieferfiliale) {
      return r.IdLieferfiliale;
    } else {
      return null;
    }
  }
}
