import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  autoinject
} from "aurelia-framework";
import {
  OnOffice365AuthTokenAbfrage,
  OnOffice365AuthTokenAbfrageEvent,
  OnOffice365TokenAnfordern,
  OnOffice365TokenAnfordernEvent,
  OnVersionHasUpdate,
  OnVersionHasUpdateEvent
} from "../../framework-data/events";
import {
  LocalizationService,
  RestService,
  WebEventService
} from "../../framework/base/export";
import {
  HeaderService
} from "../../framework/default-ui/services/export";
import {
  ICommandData
} from "../../framework/forms/export";
import {
  ArbeitszeitStempelnCommand
} from "../elements/arbeitszeit-stempeln-command/arbeitszeit-stempeln-command";
import {
  AufgabeCommand
} from "../elements/aufgabe-command/aufgabe-command";
import {
  BenachrichtigungCommand
} from "../elements/benachrichtigung-command/benachrichtigung-command";
import {
  PasswortAendern
} from "../elements/passwort-aendern/passwort-aendern";
import {
  ProjektTaetigkeitBuchungCommand
} from "../elements/projekt-taetigkeit-buchung-command/projekt-taetigkeit-buchung-command";
import {
  ZugriffHistorieCommand
} from "../elements/zugriff-historie-command/zugriff-historie-command";
import {
  HeaderCommandEvent, NotificationTyp
} from "../enumerations/export";
import {
  LocationService
} from "./../../framework/base/services/location-service";
import {
  MailCommand
} from "./../elements/mail-command/mail-command";
import {
  CustomEditPopupService
} from "./custom-edit-popup-service";
import {
  ModuleService
} from "./module-service";
import {
  StartupService
} from "./startup-service";

@autoinject
export class HeaderCommandService {
  constructor(
    private _eventAggregator: EventAggregator,
    private _headerService: HeaderService,
    private _localizationService: LocalizationService,
    private _customEditPopupService: CustomEditPopupService,
    private _startupService: StartupService,
    private _locationService: LocationService,
    private _moduleService: ModuleService,
    private _restService: RestService,
    private _webEventService: WebEventService) { }

  passwortAendern: PasswortAendern;
  aufgabeCommand: ICommandData = {
    id: "aufgabe",
    title: this._localizationService.translateOnce("form-command.aufgabe-command-icon-text"),
    icon: "tasks",
    badgeColor: "#C1392D",
    execute: (options) => {
      this._eventAggregator.publish(HeaderCommandEvent.onShowingPopover, this.aufgabeCommand);

      this.aufgabe.showPopover(this.getCommandTarget(options.event), null);
    }
  };
  aufgabe: AufgabeCommand;

  benachrichtigungCommand: ICommandData = {
    id: "benachrichtigung",
    title: this._localizationService.translateOnce("form-command.nachrichten-command-icon-text"),
    icon: "bell-o",
    badgeColor: "#C1392D",
    execute: (options) => {
      this._eventAggregator.publish(HeaderCommandEvent.onShowingPopover, this.benachrichtigungCommand);

      this.benachrichtigung.showPopover(this.getCommandTarget(options.event));
    }
  };
  benachrichtigung: BenachrichtigungCommand;

  mailCommand: ICommandData = {
    id: "mail",
    title: this._localizationService.translateOnce("form-command.mail-command-icon-text"),
    icon: "envelope-o",
    badgeColor: "#17A086",
    execute: (options) => {
      this._eventAggregator.publish(HeaderCommandEvent.onShowingPopover, this.mailCommand);

      this.mail.showPopover(this.getCommandTarget(options.event));
    }
  };
  mail: MailCommand;

  arbeitszeitStempelnCommand: ICommandData = {
    id: "arbeitszeitStempeln",
    title: this._localizationService.translateOnce("form-command.arbeitszeit"),
    icon: "clock-o",
    badgeColor: "#17A086",
    execute: (options) => {
      this._eventAggregator.publish(HeaderCommandEvent.onShowingPopover, this.arbeitszeitStempelnCommand);
      this.arbeitszeitStempeln.showPopover(this.getCommandTarget(options.event));
    }
  };
  arbeitszeitStempeln: ArbeitszeitStempelnCommand;

  projektTaetigkeitBuchungCommand: ICommandData = {
    id: "projektTaetigkeitBuchung",
    title: this._localizationService.translateOnce("form-command.projekt-taetigkeit-buchung"),
    icon: "calendar-check-o ",
    badgeColor: "#17A086",
    execute: (options) => {
      this._eventAggregator.publish(HeaderCommandEvent.onShowingPopover, this.projektTaetigkeitBuchungCommand);

      this.projektTaetigkeitBuchung.showPopover(this.getCommandTarget(options.event));
    }
  };
  projektTaetigkeitBuchung: ProjektTaetigkeitBuchungCommand;

  zugriffHistorieCommand: ICommandData = {
    id: "zugriffHistorie",
    title: this._localizationService.translateOnce("form-command.zugriff-historie-command-icon-text"),
    icon: "stack-overflow",
    execute: (options) => {
      this.zugriffHistorie.showPopover(this.getCommandTarget(options.event));
    }
  };
  zugriffHistorie: ZugriffHistorieCommand;

  office365AuthCommand: ICommandData = {
    id: "office365Auth",
    title: this._localizationService.translateOnce("erp_header.office365_auth"),
    icon: "lock",
    execute: () => {
      this.onAvatarCommandClicked(
        this.office365AuthCommand,
        async () => {
          const r: OnOffice365TokenAnfordern = await this._webEventService.execute(new OnOffice365TokenAnfordernEvent({}));
          window.open(r.AuthUrl, "_blank");
        });
    }
  };

  benutzereinstellungenAendernCommand: ICommandData = {
    id: "benutzereinstellungenAendern",
    title: this._localizationService.translateOnce("erp_header.benutzereinstellungen_aendern"),
    execute: () => {
      if (!this._startupService.startupInfo || !this._startupService.startupInfo.Benutzer) {
        return;
      }

      this.onAvatarCommandClicked(
        this.benutzereinstellungenAendernCommand,
        () => {
          const benutzer = this._startupService.startupInfo.Benutzer;
          this._customEditPopupService.benutzerEinstellungenEditPopup.show({
            mappings: { "$id": benutzer.Id }
          });
        });
    }
  };
  mandantWechselnCommand: ICommandData = {
    id: "mandantWechseln",
    title: this._localizationService.translateOnce("erp_header.mandant_wechseln"),
    execute: () => {
      if (!this._startupService.startupInfo || !this._startupService.startupInfo.Benutzer) {
        return;
      }

      this.onAvatarCommandClicked(
        this.benutzereinstellungenAendernCommand,
        () => {
          const mandant = this._startupService.startupInfo.Mandant;
          this._customEditPopupService.mandantWechselnPopup.show({
            mappings: { "$id": mandant.Id }
          });
        });
    }
  };
  impressumCommand: ICommandData = {
    id: "tipInfo",
    title: this._localizationService.translateOnce("erp_header.impressum"),
    execute: () => {
      this.onAvatarCommandClicked(
        this.impressumCommand,
        () => {
          this._customEditPopupService.impressumEditPopup.show({
            mappings: {}
          });
        });
    }
  };
  passwortAendernCommand: ICommandData = {
    id: "passwortAendern",
    title: this._localizationService.translateOnce("erp_header.passwort_aendern"),
    execute: () => {
      this.onAvatarCommandClicked(
        this.passwortAendernCommand,
        () => {
          this.passwortAendern.passwortAendernPopup.instance.show();
        });
    }
  };
  versionsinfoCommand: ICommandData = {
    id: "versionsinfo",
    title: this._localizationService.translateOnce("erp_header.versionsinfo"),
    execute: () => {
      this.onAvatarCommandClicked(
        this.versionsinfoCommand,
        () => {
          this._locationService.goTo({
            url: "ERP/Ticket/Version"
          });
        });
    }
  };
  supportCenterCommand: ICommandData = {
    id: "supportCenter",
    title: this._localizationService.translateOnce("erp_header.support_center"),
    execute: () => {
      this.onAvatarCommandClicked(
        this.supportCenterCommand,
        () => {
          this._locationService.goTo({
            url: "ERP/Ticket/Ticket"
          });
        });
    }
  };
  hewNewVersionCommand: ICommandData = {
    id: "hasNewVersionCommand",
    title: this._localizationService.translateOnce("erp_header.neue_version"),
    isVisible: false,
    icon: "circle-o",
    className: "t--header-command-has-new-version",
    execute: () => {
      this.onAvatarCommandClicked(
        this.hewNewVersionCommand,
        () => {
          this._locationService.goTo({
            url: "ERP/Ticket/Version"
          });
        });
    }
  };

  initialize() {
    if (!this.existsCommand(this.office365AuthCommand, this._headerService.commands)) {
      this._headerService.commands.push(this.office365AuthCommand);
    }

    this.updateHasOffice365();

    this.projektTaetigkeitBuchungCommand.isVisible = this._startupService.startupInfo.Lizenz.HasProjektzeiterfassung
      && this._moduleService.hasBerechtigungProjektzeit();

    if (!this.existsCommand(this.projektTaetigkeitBuchungCommand, this._headerService.commands)) {
      this._headerService.commands.push(this.projektTaetigkeitBuchungCommand);
    }

    if (!this.existsCommand(this.arbeitszeitStempelnCommand, this._headerService.commands)) {
      this._headerService.commands.push(this.arbeitszeitStempelnCommand);
    }
    this.hasArbeitszeitStempeln();

    if (!this.existsCommand(this.zugriffHistorieCommand, this._headerService.commands)) {
      this._headerService.commands.push(this.zugriffHistorieCommand);
    }
    this.mailCommand.isVisible = this._startupService.startupInfo.Lizenz.HasMailImport;
    if (!this.existsCommand(this.mailCommand, this._headerService.commands)) {
      this._headerService.commands.push(this.mailCommand);
    }
    this.aufgabeCommand.isVisible = !this._startupService.startupInfo.Benutzer.IsCloudAdministrator;
    if (!this.existsCommand(this.aufgabeCommand, this._headerService.commands)) {
      this._headerService.commands.push(this.aufgabeCommand);
    }
    if (!this.existsCommand(this.benachrichtigungCommand, this._headerService.commands)) {
      this._headerService.commands.push(this.benachrichtigungCommand);
    }
    if (!this.existsCommand(this.hewNewVersionCommand, this._headerService.commands)) {
      this._headerService.commands.push(this.hewNewVersionCommand);
    }

    this.mandantWechselnCommand.isVisible = !this._startupService.startupInfo.Benutzer.IsCloudAdministrator
      && this._startupService.startupInfo.Benutzer.CanMandantWechseln;
    if (!this.existsCommand(this.mandantWechselnCommand, this._headerService.avatarCommands)) {
      this._headerService.avatarCommands.push(this.mandantWechselnCommand);
    }
    this.updateCanMandantWechseln();

    if (!this.existsCommand(this.benutzereinstellungenAendernCommand, this._headerService.avatarCommands)) {
      this._headerService.avatarCommands.push(this.benutzereinstellungenAendernCommand);
    }
    if (!this.existsCommand(this.passwortAendernCommand, this._headerService.avatarCommands)) {
      this._headerService.avatarCommands.push(this.passwortAendernCommand);
    }
    if (!this.existsCommand(this.supportCenterCommand, this._headerService.avatarCommands)) {
      this._headerService.avatarCommands.push(this.supportCenterCommand);
    }
    if (!this.existsCommand(this.versionsinfoCommand, this._headerService.avatarCommands)) {
      this._headerService.avatarCommands.push(this.versionsinfoCommand);
    }
    if (!this.existsCommand(this.impressumCommand, this._headerService.avatarCommands)) {
      this._headerService.avatarCommands.push(this.impressumCommand);
    }

    this.updateHasNewVersion();
  }

  getCommandTarget(event: Event): Element {
    let element: any = event.target;

    while (!element.hasAttribute("click.delegate")) {
      element = element.parentElement;
    }

    return element;
  }

  private onAvatarCommandClicked(command: ICommandData, execute: { (): void }) {
    this._headerService.hideAvatarPopoper();
    this._eventAggregator.publish(HeaderCommandEvent.onShowingPopover, command);
    execute();
  }
  private existsCommand(command: ICommandData, commandList: ICommandData[]) {
    return commandList.some((cmd) => cmd.id == command.id);
  }
  private async updateHasNewVersion() {
    this._eventAggregator.subscribe("ticket-version:read", () => {
      this.hewNewVersionCommand.isVisible = false;
    });

    const r: OnVersionHasUpdate = await this._webEventService.execute(new OnVersionHasUpdateEvent({
      HasNewVersion: false
    }));

    if (r && r.HasNewVersion) {
      this.hewNewVersionCommand.isVisible = true;
    }
  }
  private async updateHasOffice365() {
    this._eventAggregator.subscribe(NotificationTyp.office365TokenChanged, () => {
      this.office365AuthCommand.isVisible = false;
    });

    if (!this._startupService.startupInfo.Benutzer.HasOffice365MailServer) {
      this.office365AuthCommand.isVisible = false;
      return;
    }

    const r: OnOffice365AuthTokenAbfrage = await this._webEventService.execute(new OnOffice365AuthTokenAbfrageEvent({
      HasAuthToken: false
    }));

    this.office365AuthCommand.isVisible = r && !r.HasAuthToken
      ? true
      : false;
  }

  private async updateCanMandantWechseln() {
    this._eventAggregator.subscribe(NotificationTyp.benutzerCanMandantWechselnChanged, (e) => {
      this.mandantWechselnCommand.isVisible = e.IsVisible;
    });
  }

  private async hasArbeitszeitStempeln() {
    const hasBerechtigungArbeitszeit = this._startupService.startupInfo.Lizenz.HasArbeitszeit
      && this._moduleService.hasBerechtigungArbeitszeit();

      if (!hasBerechtigungArbeitszeit) {
        this.arbeitszeitStempelnCommand.isVisible = false;
        return;
      }

      const r = await this._restService.post({
        url: this._restService.getApiUrl(`ERP/ArbeitszeitMitarbeiterstatus/benutzer`),
        data:{
          Stichtag: new Date,
          IdBenutzer: this._startupService.startupInfo.Benutzer.Id
        }, 
        errorCallback: () => {
          this.arbeitszeitStempelnCommand.isVisible = false;
          return true;
        }
      });
  }
}
