import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    AufgabeEditUtils
} from "./aufgabe-edit-utils";

@fwx.autoinject
export class AufgabeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_AufgabeEditUtils: AufgabeEditUtils) {
        super(element, formBaseImport);
        this.id = "aufgabe-edit";
        this.title = "aufgabe-edit.aufgabe-edit_caption";
        this.addModel({
            "id": "$m_Aufgabe",
            "webApiAction": "ERP/Stammdaten/Aufgabe",
            "webApiExpand": {
                'AufgabeInfoBenutzer': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_AufgabeInfoBenutzer",
            "webApiAction": "ERP/Stammdaten/Benutzer",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AufgabeKommentarRel",
            "webApiAction": "ERP/Stammdaten/AufgabeKommentar",
            "webApiOrderBy": [{
                columnName: 'Id',
                sortOrder: 0
            }],
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AenderungInfo",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AufgabeEditUtils.verknuepfteEntitaetCommand",
                "bindToFQ": "functions.$f_AufgabeEditUtils.verknuepfteEntitaetCommand"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_AufgabeEditUtils", $f_AufgabeEditUtils, "functions.$f_AufgabeEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "aufgabe-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Aufgabe.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "aufgabe-edit.r_faelligam_caption",
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "FaelligAm",
                "bindToFQ": "models.data.$m_Aufgabe.FaelligAm"
            },
            "validationRules": [],
            "id": "r_faelligAm",
            "options": {
                "optionsName": "r_faelligAmOptions",
                "optionsNameFQ": "r_faelligAmOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "aufgabe-edit.r_erinnernam_caption",
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "ErinnernAm",
                "bindToFQ": "models.data.$m_Aufgabe.ErinnernAm"
            },
            "validationRules": [],
            "id": "r_erinnernAm",
            "options": {
                "optionsName": "r_erinnernAmOptions",
                "optionsNameFQ": "r_erinnernAmOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "aufgabe-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Aufgabe.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "aufgabe-edit.r_isprioritaet_caption",
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "IsPrioritaet",
                "bindToFQ": "models.data.$m_Aufgabe.IsPrioritaet"
            },
            "validationRules": [],
            "id": "r_isPrioritaet",
            "options": {
                "optionsName": "r_isPrioritaetOptions",
                "optionsNameFQ": "r_isPrioritaetOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "aufgabe-edit.r_betreff_caption",
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "Betreff",
                "bindToFQ": "models.data.$m_Aufgabe.Betreff"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "125px",
            "caption": "aufgabe-edit.r_text_caption",
            "binding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "Text",
                "bindToFQ": "models.data.$m_Aufgabe.Text"
            },
            "validationRules": [],
            "id": "r_text",
            "options": {
                "optionsName": "r_textOptions",
                "optionsNameFQ": "r_textOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "aufgabe-edit.r_benutzer_caption",
            "dataContext": "$m_Aufgabe",
            "relationBinding": {
                "dataContext": "$m_Aufgabe",
                "bindTo": "AufgabeInfoBenutzer",
                "bindToFQ": "models.data.$m_Aufgabe.AufgabeInfoBenutzer"
            },
            "relationProperty": "IdBenutzer",
            "itemDataContext": "$m_AufgabeInfoBenutzer",
            "itemValueExpr": "Id",
            "itemDisplayExpr": "Name",
            "binding": {
                "dataContext": "$m_Aufgabe"
            },
            "validationRules": [],
            "id": "r_benutzer",
            "options": {
                "optionsName": "r_benutzerOptions",
                "optionsNameFQ": "r_benutzerOptions"
            }
        });
        super.onConstructionFinished();
    }
}