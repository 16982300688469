import { Artikel } from './../../../elements/artikel/artikel';
import { BelegExportService } from './../../../services/beleg-export-service';
import { autoinject, transient } from 'aurelia-framework';
import { ICommandData } from '../../../../framework/forms/interfaces/command-data';
import { FormBase } from '../../../../framework/forms/classes/form-base';
import { IdxDataGridComponent } from '../../../interfaces/export';
import { AsyncService } from '../../../../framework/base/services/async-service';
import { EximService } from '../../../services/exim-service';
import { BelegImportTyp } from '../../../enumerations/beleg-import-typ';

@autoinject
@transient()
export class ArtikelListUtils {
  constructor(
    private _asyncService: AsyncService,
    private _eximService: EximService,
    private _belegExportService: BelegExportService
  ) {}
  
  form: FormBase;
  isBelegExportMandantAktiv: boolean;

  doBelegExport: ICommandData = {
    id: "doBelegExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    isVisibleExpression: "functions.$f_ArtikelListUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_ArtikelListUtils.isBelegExportMandantAktiv",
    title: "erp.beleg-export",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["artikelGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      this._belegExportService.export(BelegImportTyp.Artikel, keys);
    }
  };
  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    title: "erp.export",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["artikelGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      this._eximService.export("ArtikelExim", keys);
    }
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.onAttached.register(async (r) => {
      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Artikel);
      return Promise.resolve();
    });
  }
}
