import { autoinject, bindable, computedFrom, Scope, OverrideContext, observable } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';
import { NotificationTyp } from '../../enumerations/notification-typ';
import { DmsViewerService } from '../../services/export';
import { FormUtilsService } from '../../../framework/forms/export';
import { RestService, LocationService } from '../../../framework/base/export';

@autoinject
export class EntitaetMenu {
  constructor(
    private eventAggregator: EventAggregator,
    private dmsViewerService: DmsViewerService,
    private formUtilsService: FormUtilsService,
    private restService: RestService,
    private locationService: LocationService
  ) { }

  @bindable @observable data: any;
  @bindable hideAenddat: boolean;

  scope: Scope;
  
  id: any;
  hasNotiz: boolean;
  anzahlDokumente: number;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.dataChanged();
  }

  dataChanged() {
    if (!this.data) {
      return;
    }

    switch (this.data.TypeName) {
      case "TIP.ERP.Business.Entitaeten.Stammdaten.VerknuepfteEntitaet": {
        this.id = this.data.IdVerknuepft;
        this.hasNotiz = this.data.HasVerknuepftNotiz;
        this.anzahlDokumente = 0;
        break;
      }
      default: {
        this.id = this.data.Id;
        this.hasNotiz = this.data.HasNotiz;
        this.anzahlDokumente = this.data.AnzahlDokumenteEx;
        break;
      }
    }
  }

  async showNotizListPopover(e: Event) {
    e.stopPropagation();

    const id = await this.getId();
    if (!id) {
      return;
    }

    this.eventAggregator.publish(
      NotificationTyp.notizShowListPopover, {
        id: id,
        target: e.target
      }
    );
  }
  async showDokumente(e: Event) {
    if (this.data.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.MailImportItem") {
      return;
    }

    e.stopPropagation();

    const idDokumentContainer = await this.getIdDokumentContainer();
    if (!idDokumentContainer) {
      return;
    }
    
    this.dmsViewerService.showDmsViewerPopup({
      id: this.data.Id,
      idDokumentContainer: idDokumentContainer,
      typeName: this.data.TypeName,
      callback: async () => {
        const r = await this.restService.get({
          url: this.restService.getWebApiUrl("ERP/Stammdaten/MandantBase/").concat(this.data.Id),
          getOptions: {
            customs: [{ "key": "InklAnzDokumente", "value": true }]
          }
        });

        this.anzahlDokumente = r.AnzahlDokumenteEx;
      }
    });
  }

  private async getId() {
    if (this.data.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.BriefZuEmpfaenger") {
      const r = await this.restService.get({
        url: this.restService.getWebApiUrl("ERP/Stammdaten/BriefZuEmpfaenger/").concat(this.id),
        getOptions: {
          columns: ["IdBrief"]
        }
      });

      if (!r) {
        return null;
      }

      return r.IdBrief;
    } else {
      return this.id;
    }
  }
  private async getIdDokumentContainer() {
    let id = await this.getId();

    if (!id) {
      return null;
    }

    const r = await this.restService.get({
      url: this.restService.getWebApiUrl("ERP/DMS/DokumentContainer"),
      getOptions: {
        columns: ["Id"],
        where: ["IdObjekt", id]
      }
    });

    if (!r || r.length == 0) {
      return null;
    }

    return r[0].Id;
  }
}
