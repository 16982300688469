import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    ProjektEditUtils
} from "./projekt-edit-utils";
import {
    AuftragService
} from "../../../services/auftrag-service";

@fwx.autoinject
export class ProjektEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_ProjektEditUtils: ProjektEditUtils,
        private $f_AuftragService: AuftragService) {
        super(element, formBaseImport);
        this.id = "projekt-edit";
        this.title = "projekt-edit.projekt-edit_caption";
        this.addModel({
            "id": "$m_Projekt",
            "webApiAction": "ERP/Stammdaten/Projekt",
            "webApiExpand": {
                'Projektart': null,
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_ProjektZuGeschaeftspartnerRel",
            "webApiAction": "ERP/Stammdaten/ProjektZuGeschaeftspartner",
            "webApiExpand": {
                'Geschaeftspartner': {
                    'expand': {
                        'Geschaeftspartnerart': null,
                        'Status': null,
                        'Adresse': {
                            'expand': {
                                'Land': null
                            }
                        }
                    }
                }
            },
            "webApiWhere": [],
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": [{
                "webApiCustomKey": "InklIdStatusFarbe",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_TaetigkeitRel",
            "webApiAction": "ERP/Stammdaten/ProjektTaetigkeit",
            "webApiExpand": {
                'Status': null,
                'Taetigkeit': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "!models.data.$m_Projekt.Status.Typ",
            "filters": []
        });
        this.addModel({
            "id": "$m_TaetigkeitEdit",
            "webApiAction": "ERP/Stammdaten/ProjektTaetigkeit",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ProjektplanungZuBenutzer",
            "webApiAction": "ERP/Stammdaten/ProjektplanungZuBenutzer",
            "webApiExpand": {
                'Projektplanung': null,
                'Benutzer': null
            },
            "webApiWhere": [
                ["Projektplanung.IdProjekt", {
                    "isBound": true,
                    "expression": "models.data.$m_Projekt.Id"
                }]
            ],
            "webApiOrderBy": [{
                "columnName": "Projektplanung.DatumVon",
                "sortOrder": 1
            }],
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BewAngebot",
            "webApiAction": "ERP/Verkauf/Angebot",
            "webApiExpand": {
                'Angebotsart': null,
                'Kunde': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Status': null,
                'Waehrung': null
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewAngebotGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewAuftrag",
            "webApiAction": "ERP/Verkauf/Auftrag",
            "webApiExpand": {
                'Auftagsart': null,
                'Kunde': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Status': null,
                'Waehrung': null
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewAuftragGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewLieferschein",
            "webApiAction": "ERP/Verkauf/Lieferschein",
            "webApiExpand": {
                'Waehrung': null,
                'Kunde': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Status': null
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewLieferscheinGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewFaktura",
            "webApiAction": "ERP/Verkauf/Faktura",
            "webApiExpand": {
                'Waehrung': null,
                'Rechnungsort': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Status': null,
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewFakturaGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewMahnung",
            "webApiAction": "ERP/Verkauf/Mahnung",
            "webApiExpand": {
                'Waehrung': null,
                'Rechnungsort': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Status': null
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewMahnungGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewKundenreklamation",
            "webApiAction": "ERP/Verkauf/Kundenreklamation",
            "webApiExpand": {
                'Waehrung': null,
                'Kunde': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Status': null,
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewKundenreklamationGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewBestellung",
            "webApiAction": "ERP/Einkauf/Bestellung",
            "webApiExpand": {
                'Waehrung': null,
                'Lieferant': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Status': null,
                'Auftrag': {
                    'columns': [
                        'Id',
                        'Nummer'
                    ]
                }
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewBestellungGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewWarenuebernahme",
            "webApiAction": "ERP/Einkauf/Warenuebernahme",
            "webApiExpand": {
                'Waehrung': null,
                'Lieferant': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Status': null,
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewWarenuebernahmeGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewEingangsrechnung",
            "webApiAction": "ERP/Einkauf/Eingangsrechnung",
            "webApiExpand": {
                'Waehrung': null,
                'Lieferant': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Status': null,
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewEingangsrechnungGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_Brief",
            "webApiAction": "ERP/Stammdaten/Brief",
            "webApiExpand": {
                'Status': null,
                'Sachbearbeiter': null,
                'Firma': null
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_kommunikationBrief'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_MailImportItem",
            "webApiAction": "ERP/Stammdaten/MailImportItem",
            "webApiExpand": {
                'MailImportDefinition': null,
                Status: null,
                Geschaeftspartner: {
                    expand: {
                        Adresse: null
                    }
                },
                Person: null
            },
            "webApiWhere": ["IdCboProjekt", {
                "isBound": true,
                "expression": "models.data.$m_Projekt.Id"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_kommunikationMail'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ProjektEditUtils.projektdateienCommand",
                "bindToFQ": "functions.$f_ProjektEditUtils.projektdateienCommand"
            }
        });
        this.addEditPopup({
            "idContent": "projektTaetigkeitEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_TaetigkeitEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_TaetigkeitEdit.Id"
                }
            }],
            "id": "projektTaetigkeitEditPopup",
            "options": {
                "optionsName": "projektTaetigkeitEditPopupOptions",
                "optionsNameFQ": "projektTaetigkeitEditPopupOptions"
            },
            "caption": "projekt-edit.projekttaetigkeiteditpopup_caption",
            "height": "auto",
            "width": "800px",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_ProjektEditUtils", $f_ProjektEditUtils, "functions.$f_ProjektEditUtils");
        this.addFunction("$f_AuftragService", $f_AuftragService, "functions.$f_AuftragService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "projekt-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Projekt",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Projekt.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "projektart",
            "filters": [],
            "caption": "projekt-edit.r_projektart_caption",
            "binding": {
                "dataContext": "$m_Projekt",
                "bindTo": "IdProjektart",
                "bindToFQ": "models.data.$m_Projekt.IdProjektart"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_projektart",
            "options": {
                "optionsName": "r_projektartOptions",
                "optionsNameFQ": "r_projektartOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "projekt-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Projekt",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Projekt.IdSachbearbeiter"
            },
            "validationRules": [],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "projekt-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Projekt",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Projekt.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "projekt-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Projekt",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Projekt.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabGeschaeftspartner",
                "caption": "projekt-edit.r_tabgeschaeftspartner_caption"
            }, {
                "id": "r_tabTaetigkeiten",
                "caption": "projekt-edit.r_tabtaetigkeiten_caption",
                "if": "functions.$f_ProjektEditUtils.showTaetigkeiten"
            }, {
                "id": "r_tabProjektplanung",
                "caption": "projekt-edit.r_tabprojektplanung_caption",
                "if": "functions.$f_ProjektEditUtils.startupService.startupInfo.Lizenz.HasProjektplanung"
            }, {
                "id": "r_tabKommunikationMail",
                "caption": "projekt-edit.r_tabkommunikationmail_caption",
                "if": "functions.$f_ProjektEditUtils.startupService.startupInfo.Lizenz.HasMailImport"
            }, {
                "id": "r_tabKommunikationBrief",
                "caption": "projekt-edit.r_tabkommunikationbrief_caption"
            }, {
                "id": "r_tabBewAngebot",
                "caption": "projekt-edit.r_tabbewangebot_caption"
            }, {
                "id": "r_tabBewAuftrag",
                "caption": "projekt-edit.r_tabbewauftrag_caption"
            }, {
                "id": "r_tabBewLieferschein",
                "caption": "projekt-edit.r_tabbewlieferschein_caption"
            }, {
                "id": "r_tabBewFaktura",
                "caption": "projekt-edit.r_tabbewfaktura_caption"
            }, {
                "id": "r_tabBewMahnung",
                "caption": "projekt-edit.r_tabbewmahnung_caption",
                "if": "functions.$f_ProjektEditUtils.startupService.startupInfo.Lizenz.HasMahnung"
            }, {
                "id": "r_tabBewKundenreklamation",
                "caption": "projekt-edit.r_tabbewkundenreklamation_caption"
            }, {
                "id": "r_tabBewBestellung",
                "caption": "projekt-edit.r_tabbewbestellung_caption"
            }, {
                "id": "r_tabBewWarenuebernahme",
                "caption": "projekt-edit.r_tabbewwarenuebernahme_caption"
            }, {
                "id": "r_tabBewEingangsrechnung",
                "caption": "projekt-edit.r_tabbeweingangsrechnung_caption"
            }],
            "id": "r_tabBewegung",
            "options": {
                "optionsName": "r_tabBewegungOptions",
                "optionsNameFQ": "r_tabBewegungOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_proGeschaeftspartnerNummer",
                "caption": "projekt-edit.r_progeschaeftspartnernummer_caption",
                "bindTo": "Geschaeftspartner.Nummer",
                "width": "80px"
            }, {
                "id": "r_proGeschaeftspartnerGeschaeftspartnerart",
                "caption": "projekt-edit.r_progeschaeftspartnergeschaeftspartnerart_caption",
                "bindTo": "Geschaeftspartner.Geschaeftspartnerart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_proGeschaeftspartnerFirmenbez1",
                "caption": "projekt-edit.r_progeschaeftspartnerfirmenbez1_caption",
                "bindTo": "Geschaeftspartner.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_proGeschaeftspartnerFirmenbez2",
                "caption": "projekt-edit.r_progeschaeftspartnerfirmenbez2_caption",
                "bindTo": "Geschaeftspartner.Adresse.Firmenbez2",
                "minWidth": 100
            }, {
                "id": "r_proGeschaeftspartnerStrasse",
                "caption": "projekt-edit.r_progeschaeftspartnerstrasse_caption",
                "bindTo": "Geschaeftspartner.Adresse.Strasse",
                "minWidth": 100
            }, {
                "id": "r_proGeschaeftspartnerLand",
                "caption": "projekt-edit.r_progeschaeftspartnerland_caption",
                "bindTo": "Geschaeftspartner.Adresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_proGeschaeftspartnerPLZ",
                "caption": "projekt-edit.r_progeschaeftspartnerplz_caption",
                "bindTo": "Geschaeftspartner.Adresse.PLZ",
                "width": "80px"
            }, {
                "id": "r_proGeschaeftspartnerOrt",
                "caption": "projekt-edit.r_progeschaeftspartnerort_caption",
                "bindTo": "Geschaeftspartner.Adresse.Ort",
                "width": "120px"
            }, {
                "id": "r_proGeschaeftspartnerStatus",
                "caption": "projekt-edit.r_progeschaeftspartnerstatus_caption",
                "bindTo": "Geschaeftspartner.Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }, {
                "id": "r_proGeschaeftspartnerIsManuell",
                "caption": "projekt-edit.r_progeschaeftspartnerismanuell_caption",
                "bindTo": "IsManuell",
                "width": "100px"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_proGeschaeftspartnerGridToolbarOptions",
                "optionsNameFQ": "r_proGeschaeftspartnerGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Projekt"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_ProjektZuGeschaeftspartnerRel",
                "bindTo": "IdProjekt",
                "bindToFQ": "models.data.$m_ProjektZuGeschaeftspartnerRel.IdProjekt"
            },
            "dataModel": "$m_Projekt",
            "editUrl": "ERP/Stammdaten/Geschaeftspartner",
            "editUrlIdProperty": "IdGeschaeftspartner",
            "selectionMode": 2,
            "pageSize": 10,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_Projekt",
                    "bindTo": "$f_ProjektEditUtils.geschaeftspartnerZuordnenCommand",
                    "bindToFQ": "functions.$f_ProjektEditUtils.geschaeftspartnerZuordnenCommand"
                }
            }, {
                "binding": {
                    "dataContext": "$m_Projekt",
                    "bindTo": "$f_ProjektEditUtils.doGeschaeftspartnerZuordnungenEntfernen",
                    "bindToFQ": "functions.$f_ProjektEditUtils.doGeschaeftspartnerZuordnungenEntfernen"
                }
            }],
            "id": "r_proGeschaeftspartnerGrid",
            "options": {
                "optionsName": "r_proGeschaeftspartnerGridOptions",
                "optionsNameFQ": "r_proGeschaeftspartnerGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_taetTaetigkeit",
                "caption": "projekt-edit.r_taettaetigkeit_caption",
                "bindTo": "Taetigkeit.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_taetStundenGeplant",
                "caption": "projekt-edit.r_taetstundengeplant_caption",
                "bindTo": "StundenGeplant",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_taetStundenGeleistet",
                "caption": "projekt-edit.r_taetstundengeleistet_caption",
                "bindTo": "StundenGeleistet",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_taetStatus",
                "caption": "projekt-edit.r_taetstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_proTaetigkeitGridToolbarOptions",
                "optionsNameFQ": "r_proTaetigkeitGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Projekt"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_TaetigkeitRel",
                "bindTo": "IdProjekt",
                "bindToFQ": "models.data.$m_TaetigkeitRel.IdProjekt"
            },
            "dataModel": "$m_Projekt",
            "editDataContext": "$m_TaetigkeitEdit",
            "idEditPopup": "projektTaetigkeitEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_proTaetigkeitGrid",
            "options": {
                "optionsName": "r_proTaetigkeitGridOptions",
                "optionsNameFQ": "r_proTaetigkeitGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_projektplanungDatumVon",
                "caption": "projekt-edit.r_projektplanungdatumvon_caption",
                "bindTo": "Projektplanung.DatumVon",
                "width": "110px",
                "format": "g"
            }, {
                "id": "r_projektplanungDatumBis",
                "caption": "projekt-edit.r_projektplanungdatumbis_caption",
                "bindTo": "Projektplanung.DatumBis",
                "width": "110px",
                "format": "g"
            }, {
                "id": "r_projektplanungBenutzer",
                "caption": "projekt-edit.r_projektplanungbenutzer_caption",
                "bindTo": "Benutzer.Name"
            }],
            "optionsToolbar": {
                "optionsName": "r_projektplanungGridToolbarOptions",
                "optionsNameFQ": "r_projektplanungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_ProjektplanungZuBenutzer"
            },
            "dataModel": "$m_ProjektplanungZuBenutzer",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_projektplanungGrid",
            "options": {
                "optionsName": "r_projektplanungGridOptions",
                "optionsNameFQ": "r_projektplanungGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_maItMailImportDefinition",
                "caption": "projekt-edit.r_maitmailimportdefinition_caption",
                "bindTo": "MailImportDefinition.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_maItDatum",
                "caption": "projekt-edit.r_maitdatum_caption",
                "bindTo": "Datum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_maItBetreff",
                "caption": "projekt-edit.r_maitbetreff_caption",
                "bindTo": "Betreff",
                "minWidth": 100
            }, {
                "id": "r_maItPerson",
                "caption": "projekt-edit.r_maitperson_caption",
                "bindTo": "Person.Name",
                "width": "140px"
            }, {
                "id": "r_maItStatus",
                "caption": "projekt-edit.r_maitstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_kommunikationMailToolbarOptions",
                "optionsNameFQ": "r_kommunikationMailToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_MailImportItem"
            },
            "dataModel": "$m_MailImportItem",
            "editUrl": "ERP/Stammdaten/MailImportItem",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kommunikationMail",
            "options": {
                "optionsName": "r_kommunikationMailOptions",
                "optionsNameFQ": "r_kommunikationMailOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_briefFirma",
                "caption": "projekt-edit.r_brieffirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_briefSachbearbeiter",
                "caption": "projekt-edit.r_briefsachbearbeiter_caption",
                "bindTo": "Sachbearbeiter.Name",
                "width": "140px"
            }, {
                "id": "r_briefDatum",
                "caption": "projekt-edit.r_briefdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_briefEmpfaenger",
                "caption": "projekt-edit.r_briefempfaenger_caption",
                "bindTo": "EmpfaengerDisplayText",
                "minWidth": 100
            }, {
                "id": "r_briefBetreff",
                "caption": "projekt-edit.r_briefbetreff_caption",
                "bindTo": "Betreff",
                "width": "100px"
            }, {
                "id": "r_briefText",
                "caption": "projekt-edit.r_brieftext_caption",
                "bindTo": "Text",
                "minWidth": 100
            }, {
                "id": "r_briefAnzAnhaenge",
                "caption": "projekt-edit.r_briefanzanhaenge_caption",
                "bindTo": "AnzAnhaenge",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_briefStatus",
                "caption": "projekt-edit.r_briefstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_kommunikationBriefToolbarOptions",
                "optionsNameFQ": "r_kommunikationBriefToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Brief"
            },
            "dataModel": "$m_Brief",
            "editUrl": "ERP/Stammdaten/Brief",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kommunikationBrief",
            "options": {
                "optionsName": "r_kommunikationBriefOptions",
                "optionsNameFQ": "r_kommunikationBriefOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_angNummer",
                "caption": "projekt-edit.r_angnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_angDatum",
                "caption": "projekt-edit.r_angdatum_caption",
                "bindTo": "Datum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_angGeschaeftspartner",
                "caption": "projekt-edit.r_anggeschaeftspartner_caption",
                "bindTo": "Kunde.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_angGueltigBis",
                "caption": "projekt-edit.r_anggueltigbis_caption",
                "bindTo": "GueltigBis",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_angWertNetto",
                "caption": "projekt-edit.r_angwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_angWaehrung",
                "caption": "projekt-edit.r_angwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_angStatus",
                "caption": "projekt-edit.r_angstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewAngebotGridToolbarOptions",
                "optionsNameFQ": "r_bewAngebotGridToolbarOptions"
            },
            "caption": "projekt-edit.r_bewangebotgrid_caption",
            "binding": {
                "dataContext": "$m_BewAngebot"
            },
            "dataModel": "$m_BewAngebot",
            "editUrl": "ERP/Verkauf/Angebot",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewAngebotGrid",
            "options": {
                "optionsName": "r_bewAngebotGridOptions",
                "optionsNameFQ": "r_bewAngebotGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_aufNummer",
                "caption": "projekt-edit.r_aufnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_aufDatum",
                "caption": "projekt-edit.r_aufdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_aufGeschaeftspartner",
                "caption": "projekt-edit.r_aufgeschaeftspartner_caption",
                "bindTo": "Kunde.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_aufLieferdatum",
                "caption": "projekt-edit.r_auflieferdatum_caption",
                "bindTo": "Lieferdatum",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_AuftragService.onLieferdatumCellPrepared"
            }, {
                "id": "r_aufReservierungsgrad",
                "caption": "projekt-edit.r_aufreservierungsgrad_caption",
                "bindTo": "Reservierungsgrad",
                "width": "80px",
                "format": "p0",
                "onCellPrepared": "functions.$f_ProjektEditUtils.onReservierungsgradCellPrepared"
            }, {
                "id": "r_aufWertNetto",
                "caption": "projekt-edit.r_aufwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufWaehrung",
                "caption": "projekt-edit.r_aufwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_aufStatus",
                "caption": "projekt-edit.r_aufstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewAuftragGridToolbarOptions",
                "optionsNameFQ": "r_bewAuftragGridToolbarOptions"
            },
            "caption": "projekt-edit.r_bewauftraggrid_caption",
            "binding": {
                "dataContext": "$m_BewAuftrag"
            },
            "dataModel": "$m_BewAuftrag",
            "editUrl": "ERP/Verkauf/Auftrag",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewAuftragGrid",
            "options": {
                "optionsName": "r_bewAuftragGridOptions",
                "optionsNameFQ": "r_bewAuftragGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_liefsNummer",
                "caption": "projekt-edit.r_liefsnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_liefsDatum",
                "caption": "projekt-edit.r_liefsdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_liefGeschaeftspartner",
                "caption": "projekt-edit.r_liefgeschaeftspartner_caption",
                "bindTo": "Kunde.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_liefsWertNetto",
                "caption": "projekt-edit.r_liefswertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefsWaehrung",
                "caption": "projekt-edit.r_liefswaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_liefsStatus",
                "caption": "projekt-edit.r_liefsstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewLieferscheinGridToolbarOptions",
                "optionsNameFQ": "r_bewLieferscheinGridToolbarOptions"
            },
            "caption": "projekt-edit.r_bewlieferscheingrid_caption",
            "binding": {
                "dataContext": "$m_BewLieferschein"
            },
            "dataModel": "$m_BewLieferschein",
            "editUrl": "ERP/Verkauf/Lieferschein",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewLieferscheinGrid",
            "options": {
                "optionsName": "r_bewLieferscheinGridOptions",
                "optionsNameFQ": "r_bewLieferscheinGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_fakNummer",
                "caption": "projekt-edit.r_faknummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_fakDatum",
                "caption": "projekt-edit.r_fakdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_fakGeschaeftspartner",
                "caption": "projekt-edit.r_fakgeschaeftspartner_caption",
                "bindTo": "Rechnungsort.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_fakWertNetto",
                "caption": "projekt-edit.r_fakwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_fakWaehrung",
                "caption": "projekt-edit.r_fakwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_fakAbschlagsfaktura",
                "caption": "projekt-edit.r_fakabschlagsfaktura_caption",
                "bindTo": "IsAbschlagsfaktura",
                "width": "120px"
            }, {
                "id": "r_fakZahlungFaellig",
                "caption": "projekt-edit.r_fakzahlungfaellig_caption",
                "bindTo": "ZahlungFaelligkeit",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_ProjektEditUtils.onZahlungFaelligCellPrepared"
            }, {
                "id": "r_fakMahnstufe",
                "caption": "projekt-edit.r_fakmahnstufe_caption",
                "bindTo": "Mahnstufe",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_fakStatus",
                "caption": "projekt-edit.r_fakstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewFakturaGridToolbarOptions",
                "optionsNameFQ": "r_bewFakturaGridToolbarOptions"
            },
            "caption": "projekt-edit.r_bewfakturagrid_caption",
            "binding": {
                "dataContext": "$m_BewFaktura"
            },
            "dataModel": "$m_BewFaktura",
            "editUrl": "ERP/Verkauf/Faktura",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewFakturaGrid",
            "options": {
                "optionsName": "r_bewFakturaGridOptions",
                "optionsNameFQ": "r_bewFakturaGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_mahnDatum",
                "caption": "projekt-edit.r_mahndatum_caption",
                "bindTo": "Datum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_mahnBetragOffen",
                "caption": "projekt-edit.r_mahnbetragoffen_caption",
                "bindTo": "BetragOffen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnGeschaeftspartner",
                "caption": "projekt-edit.r_mahngeschaeftspartner_caption",
                "bindTo": "Rechnungsort.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_mahnZinsen",
                "caption": "projekt-edit.r_mahnzinsen_caption",
                "bindTo": "Zinsen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnMahnspesen",
                "caption": "projekt-edit.r_mahnmahnspesen_caption",
                "bindTo": "Mahnspesen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnMaxMahnstufe",
                "caption": "projekt-edit.r_mahnmaxmahnstufe_caption",
                "bindTo": "MaxMahnstufe",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_mahnWaehrung",
                "caption": "projekt-edit.r_mahnwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_mahnStatus",
                "caption": "projekt-edit.r_mahnstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewMahnungGridToolbarOptions",
                "optionsNameFQ": "r_bewMahnungGridToolbarOptions"
            },
            "caption": "projekt-edit.r_bewmahnunggrid_caption",
            "binding": {
                "dataContext": "$m_BewMahnung"
            },
            "dataModel": "$m_BewMahnung",
            "editUrl": "ERP/Verkauf/Mahnung",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewMahnungGrid",
            "options": {
                "optionsName": "r_bewMahnungGridOptions",
                "optionsNameFQ": "r_bewMahnungGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_reklNummer",
                "caption": "projekt-edit.r_reklnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_reklDatum",
                "caption": "projekt-edit.r_rekldatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_reklGeschaeftspartner",
                "caption": "projekt-edit.r_reklgeschaeftspartner_caption",
                "bindTo": "Kunde.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_reklWaehrung",
                "caption": "projekt-edit.r_reklwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_reklStatus",
                "caption": "projekt-edit.r_reklstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewKundenreklamationGridToolbarOptions",
                "optionsNameFQ": "r_bewKundenreklamationGridToolbarOptions"
            },
            "caption": "projekt-edit.r_bewkundenreklamationgrid_caption",
            "binding": {
                "dataContext": "$m_BewKundenreklamation"
            },
            "dataModel": "$m_BewKundenreklamation",
            "editUrl": "ERP/Verkauf/Kundenreklamation",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewKundenreklamationGrid",
            "options": {
                "optionsName": "r_bewKundenreklamationGridOptions",
                "optionsNameFQ": "r_bewKundenreklamationGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_besNummer",
                "caption": "projekt-edit.r_besnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_besDatum",
                "caption": "projekt-edit.r_besdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_besGeschaeftspartner",
                "caption": "projekt-edit.r_besgeschaeftspartner_caption",
                "bindTo": "Lieferant.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_besAuftragNr",
                "caption": "projekt-edit.r_besauftragnr_caption",
                "bindTo": "Auftrag.Nummer",
                "width": "80px"
            }, {
                "id": "r_besLieferdatum",
                "caption": "projekt-edit.r_beslieferdatum_caption",
                "bindTo": "Lieferdatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_besWertNetto",
                "caption": "projekt-edit.r_beswertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_besWaehrung",
                "caption": "projekt-edit.r_beswaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_besStatus",
                "caption": "projekt-edit.r_besstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewBestellungGridToolbarOptions",
                "optionsNameFQ": "r_bewBestellungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BewBestellung"
            },
            "dataModel": "$m_BewBestellung",
            "editUrl": "ERP/Einkauf/Bestellung",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewBestellungGrid",
            "options": {
                "optionsName": "r_bewBestellungGridOptions",
                "optionsNameFQ": "r_bewBestellungGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_waNummer",
                "caption": "projekt-edit.r_wanummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_waDatum",
                "caption": "projekt-edit.r_wadatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_waGeschaeftspartner",
                "caption": "projekt-edit.r_wageschaeftspartner_caption",
                "bindTo": "Lieferant.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_waWertNetto",
                "caption": "projekt-edit.r_wawertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_waWaehrung",
                "caption": "projekt-edit.r_wawaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_waStatus",
                "caption": "projekt-edit.r_wastatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewWarenuebernahmeGridToolbarOptions",
                "optionsNameFQ": "r_bewWarenuebernahmeGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BewWarenuebernahme"
            },
            "dataModel": "$m_BewWarenuebernahme",
            "editUrl": "ERP/Einkauf/Warenuebernahme",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewWarenuebernahmeGrid",
            "options": {
                "optionsName": "r_bewWarenuebernahmeGridOptions",
                "optionsNameFQ": "r_bewWarenuebernahmeGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_einNummer",
                "caption": "projekt-edit.r_einnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_einDatum",
                "caption": "projekt-edit.r_eindatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_einGeschaeftspartner",
                "caption": "projekt-edit.r_eingeschaeftspartner_caption",
                "bindTo": "Lieferant.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_einWaehrung",
                "caption": "projekt-edit.r_einwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_einStatus",
                "caption": "projekt-edit.r_einstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewEingangsrechnungGridToolbarOptions",
                "optionsNameFQ": "r_bewEingangsrechnungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BewEingangsrechnung"
            },
            "dataModel": "$m_BewEingangsrechnung",
            "editUrl": "ERP/Einkauf/Eingangsrechnung",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewEingangsrechnungGrid",
            "options": {
                "optionsName": "r_bewEingangsrechnungGridOptions",
                "optionsNameFQ": "r_bewEingangsrechnungGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_geschaeftspartnerZuordnenPopup",
            "options": {
                "optionsName": "r_geschaeftspartnerZuordnenPopupOptions",
                "optionsNameFQ": "r_geschaeftspartnerZuordnenPopupOptions"
            },
            "caption": "projekt-edit.r_geschaeftspartnerzuordnenpopup_caption",
            "height": "auto",
            "width": "800px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_ProjektEditUtils.doGeschaeftspartnerZuordnen",
                    "bindToFQ": "functions.$f_ProjektEditUtils.doGeschaeftspartnerZuordnen"
                }
            }]
        });
        super.onConstructionFinished();
    }
    r_tabBewegungSelected = 0;
}