import { IDataSourceCustomizationOptions } from './../../../../framework/base/interfaces/data-source-customization-options';
import { ICommandData } from './../../../../framework/forms/interfaces/command-data';
import { IdxPopupComponent } from './../../../interfaces/dx-components';
import { IPopupInfo } from './../../../../framework/forms/interfaces/popup-info';
import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  FormBase, PopupInfoService
} from "../../../../framework/forms/export";
import {
  LocationService
} from "../../../../framework/base/export";
import { EntitaetInfoService } from '../../../services/export';

@autoinject
@transient()
export class LagerbuchungInfoListNestedUtils {
  constructor(
    private _entitaetInfoService: EntitaetInfoService,
    private _popupInfoService: PopupInfoService,
    private _locationService: LocationService
  ) { }

  form: FormBase;
  
  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.form.binding.observe({
      scopeContainer: this.form.scopeContainer,
      expression: "models.data.$m_LagerbuchungInfoE",
      callback: async (newValue) => {
        if (!newValue) {
          return;
        }

        this.form.models.data.$m_LagerbuchungInfoE = null;
        if (!newValue.VerknuepfteEntitaet) {
          return;
        }

        this._entitaetInfoService
          .getUrl(newValue.VerknuepfteEntitaet.TypeName, newValue.VerknuepfteEntitaet.Id)
          .then(r => {
            this._popupInfoService.closeAllPopups().then(pr => {
              if (!pr) {
                return;
              }

              this._locationService.goTo({
                url: r
              });
            });
          });
      }
    });

    this.form.callOnBind(() => {
      const gridOptions = <DevExpress.ui.dxDataGridOptions>form["r_lagerbuchungInfoGridOptions"];
      (<DevExpress.ui.dxDataGridColumn[]>gridOptions.columns)
        .filter(c => c.dataField == "IdVerknuepfteEntitaet")
        .forEach(c => {
          c.allowHeaderFiltering = false;
          c.filterOperations = ["contains"];
        });
    });
  }

  onVerknEntitaetCellPrepared(e) {
    if (e.rowType != "data") {
      return;
    }

    if (!e.data || !e.data.VerknuepfteEntitaet) {
      return;
    }

    const cell: HTMLTableDataCellElement = e.cellElement;
    const verkn = e.data.VerknuepfteEntitaet;

    const html = `<div class="erp--text-with-leading-label-badge">
      <div class="${verkn.TypeBezeichnung ? "" : "erp--empty-badge"}">
        ${verkn.TypeBezeichnung ? verkn.TypeBezeichnung : ""}
      </div>
      <div>
        ${verkn.CboBezeichnung ? verkn.CboBezeichnung : ""}
      </div>
    </div>`;

    cell.innerHTML = html;
  }
  onVerknEntitaetCalcFilter(e) {
    return ["VerknuepfteEntitaet.CboBezeichnung", "contains", e.value];
  }
}
