import * as fwx from "../../../../framework/forms/form-export";
import {
    KonditionListUtils
} from "./kondition-list-utils";

@fwx.autoinject
export class KonditionListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_KonditionListUtils: KonditionListUtils) {
        super(element, formBaseImport);
        this.id = "kondition-list";
        this.title = "kondition-list.kondition-list_caption";
        this.addModel({
            "id": "$m_Kondition",
            "webApiAction": "ERP/Kalkulation/Kondition",
            "webApiExpand": {
                'Konditionsfeld': null,
                'GeschaeftspartnerInfo': null,
                'ArtikelInfo': null,
                'Filiale': null,
                'Lager': null,
                'Waehrung': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": [{
                "webApiCustomKey": "InklIntern",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "Waehrung",
                "webApiCustomValue": "2"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_KonditionListUtils.doExport",
                "bindToFQ": "functions.$f_KonditionListUtils.doExport"
            }
        });
        this.addFunction("$f_KonditionListUtils", $f_KonditionListUtils, "functions.$f_KonditionListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_konKonditionsfeld",
                "caption": "kondition-list.r_konkonditionsfeld_caption",
                "bindTo": "Konditionsfeld.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konFiliale",
                "caption": "kondition-list.r_konfiliale_caption",
                "bindTo": "Filiale.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konLager",
                "caption": "kondition-list.r_konlager_caption",
                "bindTo": "Lager.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konGeschaeftspartnerInfo",
                "caption": "kondition-list.r_kongeschaeftspartnerinfo_caption",
                "bindTo": "GeschaeftspartnerInfo.CboBezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konArtikelInfo",
                "caption": "kondition-list.r_konartikelinfo_caption",
                "bindTo": "ArtikelInfo.CboBezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konWert",
                "caption": "kondition-list.r_konwert_caption",
                "bindTo": "Wert",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_konWaehrung",
                "caption": "kondition-list.r_konwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_konWertPer",
                "caption": "kondition-list.r_konwertper_caption",
                "bindTo": "WertPer",
                "width": "60px",
                "format": "n0"
            }, {
                "id": "r_konStaffel",
                "caption": "kondition-list.r_konstaffel_caption",
                "bindTo": "Staffel",
                "width": "70px",
                "format": "n0"
            }, {
                "id": "r_konGueltigVon",
                "caption": "kondition-list.r_kongueltigvon_caption",
                "bindTo": "GueltigVon",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_konGueltigBis",
                "caption": "kondition-list.r_kongueltigbis_caption",
                "bindTo": "GueltigBis",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_konPrioritaet",
                "caption": "kondition-list.r_konprioritaet_caption",
                "bindTo": "Prioritaet",
                "width": "50px"
            }, {
                "id": "r_konBemerkung",
                "caption": "kondition-list.r_konbemerkung_caption",
                "bindTo": "Bemerkung",
                "minWidth": 100
            }],
            "optionsToolbar": {
                "optionsName": "r_konditionGridToolbarOptions",
                "optionsNameFQ": "r_konditionGridToolbarOptions"
            },
            "caption": "kondition-list.r_konditiongrid_caption",
            "binding": {
                "dataContext": "$m_Kondition"
            },
            "dataModel": "$m_Kondition",
            "editUrl": "ERP/Kalkulation/Kondition",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_konditionGrid",
            "options": {
                "optionsName": "r_konditionGridOptions",
                "optionsNameFQ": "r_konditionGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}