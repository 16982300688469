import * as fwx from "../../../../framework/forms/form-export";
import {
    BestellvorschlagEditUtils
} from "./bestellvorschlag-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class BestellvorschlagEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BestellvorschlagEditUtils: BestellvorschlagEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "bestellvorschlag-edit";
        this.title = "bestellvorschlag-edit.bestellvorschlag-edit_caption";
        this.addModel({
            "id": "$m_Bestellvorschlag",
            "webApiAction": "ERP/Einkauf/Bestellvorschlag",
            "webApiExpand": {
                'Artikel': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
                'Auftrag': {
                    'columns': [
                        'Id',
                        'Nummer'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_BestellvorschlagBedarf",
            "webApiAction": "ERP/Einkauf/BestellvorschlagBedarf",
            "webApiExpand": {
                'VerknuepfteEntitaet': null
            },
            "webApiWhere": [
                ["IdFiliale", {
                    "isBound": true,
                    "expression": "models.data.$m_Bestellvorschlag.IdLieferfiliale"
                }],
                ["IdArtikel", {
                    "isBound": true,
                    "expression": "models.data.$m_Bestellvorschlag.IdArtikel"
                }]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "if": "models.data.$m_Bestellvorschlag.IdAuftrag",
                "webApiCustomKey": "IdAuftrag",
                "webApiCustomValue": "models.data.$m_Bestellvorschlag.IdAuftrag"
            }]
        });
        this.addModel({
            "id": "$m_Bestellungsposition",
            "webApiAction": "ERP/Einkauf/Bestellungsposition",
            "webApiExpand": {
                'Bestellung': {
                    'expand': {
                        'Lieferlager': null
                    }
                },
                'Status': null
            },
            "webApiWhere": [
                ["Status.Typ", "!=", 1],
                ["IdArtikel", {
                    "isBound": true,
                    "expression": "models.data.$m_Bestellvorschlag.IdArtikel"
                }],
                ["Bestellung.IdLieferfiliale", {
                    "isBound": true,
                    "expression": "models.data.$m_Bestellvorschlag.IdLieferfiliale"
                }]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "if": "models.data.$m_Bestellvorschlag.IdAuftrag",
                "webApiWhere": ["Bestellung.Auftrag.Id", {
                    "isBound": true,
                    "expression": "models.data.$m_Bestellvorschlag.IdAuftrag"
                }]
            }, {
                "webApiCustomKey": "BestellungspositionenOhneIntercompanyFilter",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "BestellungspositionenOhneStreckengeschaeftFilter",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_ProdAuftrag",
            "webApiAction": "ERP/Produktion/ProdAuftrag",
            "webApiExpand": {
                'Status': null,
                'Lieferlager': null
            },
            "webApiWhere": [
                ["Status.Typ", "!=", 1],
                ["IdArtikel", {
                    "isBound": true,
                    "expression": "models.data.$m_Bestellvorschlag.IdArtikel"
                }],
                ["IdLieferfiliale", {
                    "isBound": true,
                    "expression": "models.data.$m_Bestellvorschlag.IdLieferfiliale"
                }]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "if": "models.data.$m_Bestellvorschlag.IdAuftrag",
                "webApiWhere": ["Auftragsposition.Auftrag.Id", {
                    "isBound": true,
                    "expression": "models.data.$m_Bestellvorschlag.IdAuftrag"
                }]
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellvorschlagEditUtils.doArtikelBestellenCommand",
                "bindToFQ": "functions.$f_BestellvorschlagEditUtils.doArtikelBestellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellvorschlagEditUtils.doArtikelBestellenUndVersendenCommand",
                "bindToFQ": "functions.$f_BestellvorschlagEditUtils.doArtikelBestellenUndVersendenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellvorschlagEditUtils.doArtikelIgnorierenCommand",
                "bindToFQ": "functions.$f_BestellvorschlagEditUtils.doArtikelIgnorierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellvorschlagEditUtils.doBestellvorschlagRechnenCommand",
                "bindToFQ": "functions.$f_BestellvorschlagEditUtils.doBestellvorschlagRechnenCommand"
            }
        });
        this.addFunction("$f_BestellvorschlagEditUtils", $f_BestellvorschlagEditUtils, "functions.$f_BestellvorschlagEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "bestellvorschlag-edit.r_lagereinheit_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "Artikel.Einheit.Bezeichnung",
                "bindToFQ": "models.data.$m_Bestellvorschlag.Artikel.Einheit.Bezeichnung",
                "propertyPrefix": "Artikel.Einheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_lagereinheit",
            "options": {
                "optionsName": "r_lagereinheitOptions",
                "optionsNameFQ": "r_lagereinheitOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filters": [],
            "caption": "bestellvorschlag-edit.r_lieferfiliale_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "IdLieferfiliale",
                "bindToFQ": "models.data.$m_Bestellvorschlag.IdLieferfiliale"
            },
            "validationRules": [],
            "id": "r_lieferfiliale",
            "options": {
                "optionsName": "r_lieferfilialeOptions",
                "optionsNameFQ": "r_lieferfilialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "bestellvorschlag-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Bestellvorschlag.IdStatus"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "bestellvorschlag-edit.r_auftragnr_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "Auftrag.Nummer",
                "bindToFQ": "models.data.$m_Bestellvorschlag.Auftrag.Nummer",
                "propertyPrefix": "Auftrag"
            },
            "validationRules": [],
            "id": "r_auftragNr",
            "options": {
                "optionsName": "r_auftragNrOptions",
                "optionsNameFQ": "r_auftragNrOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabErgebnisBerechnung",
                "caption": "bestellvorschlag-edit.r_tabergebnisberechnung_caption"
            }, {
                "id": "r_tabDetail",
                "caption": "bestellvorschlag-edit.r_tabdetail_caption"
            }, {
                "id": "r_tabOffeneBestellungen",
                "caption": "bestellvorschlag-edit.r_taboffenebestellungen_caption"
            }, {
                "id": "r_tabOffeneProduktionen",
                "caption": "bestellvorschlag-edit.r_taboffeneproduktionen_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bestellvorschlag-edit.r_beschaffungbis_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "BeschaffungBis",
                "bindToFQ": "models.data.$m_Bestellvorschlag.BeschaffungBis"
            },
            "validationRules": [],
            "id": "r_beschaffungBis",
            "options": {
                "optionsName": "r_beschaffungBisOptions",
                "optionsNameFQ": "r_beschaffungBisOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bestellvorschlag-edit.r_eindeckungbis_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "EindeckungBis",
                "bindToFQ": "models.data.$m_Bestellvorschlag.EindeckungBis"
            },
            "validationRules": [],
            "id": "r_eindeckungBis",
            "options": {
                "optionsName": "r_eindeckungBisOptions",
                "optionsNameFQ": "r_eindeckungBisOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 2147483647.0,
            "caption": "bestellvorschlag-edit.r_lagerbestand_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "LagerbestandLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.LagerbestandLagEH"
            },
            "validationRules": [],
            "id": "r_lagerbestand",
            "options": {
                "optionsName": "r_lagerbestandOptions",
                "optionsNameFQ": "r_lagerbestandOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 2147483647.0,
            "caption": "bestellvorschlag-edit.r_mindestbestand_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "MindestbestandLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.MindestbestandLagEH"
            },
            "validationRules": [],
            "id": "r_mindestbestand",
            "options": {
                "optionsName": "r_mindestbestandOptions",
                "optionsNameFQ": "r_mindestbestandOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 2147483647.0,
            "caption": "bestellvorschlag-edit.r_mindestbestellmenge_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "MindestbestellmengeLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.MindestbestellmengeLagEH"
            },
            "validationRules": [],
            "id": "r_mindestbestellmenge",
            "options": {
                "optionsName": "r_mindestbestellmengeOptions",
                "optionsNameFQ": "r_mindestbestellmengeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "bestellvorschlag-edit.r_mengestatistikeindecklageh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "MengeStatistikEindeckLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.MengeStatistikEindeckLagEH"
            },
            "validationRules": [],
            "id": "r_mengeStatistikEindeckLagEH",
            "options": {
                "optionsName": "r_mengeStatistikEindeckLagEHOptions",
                "optionsNameFQ": "r_mengeStatistikEindeckLagEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "bestellvorschlag-edit.r_mengeauftrageindecklageh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "MengeAuftragEindeckLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.MengeAuftragEindeckLagEH"
            },
            "validationRules": [],
            "id": "r_mengeAuftragEindeckLagEH",
            "options": {
                "optionsName": "r_mengeAuftragEindeckLagEHOptions",
                "optionsNameFQ": "r_mengeAuftragEindeckLagEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "bestellvorschlag-edit.r_mengeprodauftrageindecklageh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "MengeProdAuftragEindeckLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.MengeProdAuftragEindeckLagEH"
            },
            "validationRules": [],
            "id": "r_mengeProdAuftragEindeckLagEH",
            "options": {
                "optionsName": "r_mengeProdAuftragEindeckLagEHOptions",
                "optionsNameFQ": "r_mengeProdAuftragEindeckLagEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "bestellvorschlag-edit.r_mengebestellungoffenlageh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "MengeBestellungOffenLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.MengeBestellungOffenLagEH"
            },
            "validationRules": [],
            "id": "r_mengeBestellungOffenLagEH",
            "options": {
                "optionsName": "r_mengeBestellungOffenLagEHOptions",
                "optionsNameFQ": "r_mengeBestellungOffenLagEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "bestellvorschlag-edit.r_mengeproduktionoffenlageh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "MengeProduktionOffenLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.MengeProduktionOffenLagEH"
            },
            "validationRules": [],
            "id": "r_mengeProduktionOffenLagEH",
            "options": {
                "optionsName": "r_mengeProduktionOffenLagEHOptions",
                "optionsNameFQ": "r_mengeProduktionOffenLagEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "bestellvorschlag-edit.r_mengebenoetigtbeschaffunglageh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "MengeBenoetigtBeschaffungLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.MengeBenoetigtBeschaffungLagEH"
            },
            "validationRules": [],
            "id": "r_mengeBenoetigtBeschaffungLagEH",
            "options": {
                "optionsName": "r_mengeBenoetigtBeschaffungLagEHOptions",
                "optionsNameFQ": "r_mengeBenoetigtBeschaffungLagEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "bestellvorschlag-edit.r_mengebenoetigteindecklageh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "MengeBenoetigtEindeckLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.MengeBenoetigtEindeckLagEH"
            },
            "validationRules": [],
            "id": "r_mengeBenoetigtEindeckLagEH",
            "options": {
                "optionsName": "r_mengeBenoetigtEindeckLagEHOptions",
                "optionsNameFQ": "r_mengeBenoetigtEindeckLagEHOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bestellvorschlag-edit.r_lieferdatum_caption",
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "Lieferdatum",
                "bindToFQ": "models.data.$m_Bestellvorschlag.Lieferdatum"
            },
            "validationRules": [],
            "id": "r_lieferdatum",
            "options": {
                "optionsName": "r_lieferdatumOptions",
                "optionsNameFQ": "r_lieferdatumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "bestellvorschlag-edit.r_bestellmenge_caption",
            "binding": {
                "dataContext": "$m_Bestellvorschlag",
                "bindTo": "BestellmengeLagEH",
                "bindToFQ": "models.data.$m_Bestellvorschlag.BestellmengeLagEH"
            },
            "validationRules": [],
            "id": "r_bestellmenge",
            "options": {
                "optionsName": "r_bestellmengeOptions",
                "optionsNameFQ": "r_bestellmengeOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_besvoBedarfTyp",
                "caption": "bestellvorschlag-edit.r_besvobedarftyp_caption",
                "bindTo": "BedarfTyp",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.BestellvorschlagBedarfTyp"
            }, {
                "id": "r_besvoVerkEntBezeichnung",
                "caption": "bestellvorschlag-edit.r_besvoverkentbezeichnung_caption",
                "bindTo": "VerknuepfteEntitaet.CboBezeichnung"
            }, {
                "id": "r_besvoBenoetigtBis",
                "caption": "bestellvorschlag-edit.r_besvobenoetigtbis_caption",
                "bindTo": "BenoetigtBis",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_besvoMengeLagEH",
                "caption": "bestellvorschlag-edit.r_besvomengelageh_caption",
                "bindTo": "MengeLagEH",
                "width": "80px",
                "format": "n2"
            }],
            "optionsToolbar": {
                "optionsName": "r_bestellvorschlagBedarfGridToolbarOptions",
                "optionsNameFQ": "r_bestellvorschlagBedarfGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BestellvorschlagBedarf"
            },
            "dataModel": "$m_BestellvorschlagBedarf",
            "onItemClick": "functions.$f_BestellvorschlagEditUtils.onBestellvorschlagBedarfClick($event)",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bestellvorschlagBedarfGrid",
            "options": {
                "optionsName": "r_bestellvorschlagBedarfGridOptions",
                "optionsNameFQ": "r_bestellvorschlagBedarfGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_offBNummer",
                "caption": "bestellvorschlag-edit.r_offbnummer_caption",
                "bindTo": "Bestellung.Nummer",
                "width": "80px"
            }, {
                "id": "r_offBDatum",
                "caption": "bestellvorschlag-edit.r_offbdatum_caption",
                "bindTo": "Bestellung.Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_offBLieferdatum",
                "caption": "bestellvorschlag-edit.r_offblieferdatum_caption",
                "bindTo": "Bestellung.Lieferdatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_offBLieferlager",
                "caption": "bestellvorschlag-edit.r_offblieferlager_caption",
                "bindTo": "Bestellung.Lieferlager.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_offBMengeOffenLagEH",
                "caption": "bestellvorschlag-edit.r_offbmengeoffenlageh_caption",
                "bindTo": "MengeOffenLagEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_offBMengeWarenuebernahmeLagEH",
                "caption": "bestellvorschlag-edit.r_offbmengewarenuebernahmelageh_caption",
                "bindTo": "MengeWarenuebernahmeLagEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_offBStatus",
                "caption": "bestellvorschlag-edit.r_offbstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_offeneBestellungenGridToolbarOptions",
                "optionsNameFQ": "r_offeneBestellungenGridToolbarOptions"
            },
            "caption": "bestellvorschlag-edit.r_offenebestellungengrid_caption",
            "binding": {
                "dataContext": "$m_Bestellungsposition"
            },
            "dataModel": "$m_Bestellungsposition",
            "editUrl": "ERP/Einkauf/Bestellung",
            "editUrlIdProperty": "IdBestellung",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_offeneBestellungenGrid",
            "options": {
                "optionsName": "r_offeneBestellungenGridOptions",
                "optionsNameFQ": "r_offeneBestellungenGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_offPNummer",
                "caption": "bestellvorschlag-edit.r_offpnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_offPDatum",
                "caption": "bestellvorschlag-edit.r_offpdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_offPLieferdatum",
                "caption": "bestellvorschlag-edit.r_offplieferdatum_caption",
                "bindTo": "Lieferdatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_offPLieferlager",
                "caption": "bestellvorschlag-edit.r_offplieferlager_caption",
                "bindTo": "Lieferlager.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_offPMengeOffenLagEH",
                "caption": "bestellvorschlag-edit.r_offpmengeoffenlageh_caption",
                "bindTo": "MengeOffenLagEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_offPStatus",
                "caption": "bestellvorschlag-edit.r_offpstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_offeneProduktionenGridToolbarOptions",
                "optionsNameFQ": "r_offeneProduktionenGridToolbarOptions"
            },
            "caption": "bestellvorschlag-edit.r_offeneproduktionengrid_caption",
            "binding": {
                "dataContext": "$m_ProdAuftrag"
            },
            "dataModel": "$m_ProdAuftrag",
            "editUrl": "ERP/Produktion/ProdAuftrag",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_offeneProduktionenGrid",
            "options": {
                "optionsName": "r_offeneProduktionenGridOptions",
                "optionsNameFQ": "r_offeneProduktionenGridOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}