import * as fwx from "../../../../framework/forms/form-export";
import {
    LagerbuchungInfoListNestedUtils
} from "./lagerbuchung-info-list-nested-utils";

@fwx.autoinject
export class LagerbuchungInfoListNestedForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_LagerbuchungInfoListNestedUtils: LagerbuchungInfoListNestedUtils) {
        super(element, formBaseImport);
        this.id = "lagerbuchung-info-list-nested";
        this.title = "lagerbuchung-info-list-nested.lagerbuchung-info-list-nested_caption";
        this.addModel({
            "id": "$m_LagerbuchungInfo",
            "webApiAction": "ERP/Lager/LagerbuchungInfo",
            "webApiExpand": {
                'Artikel': null,
                'Lager': {
                    'expand': {
                        'Filiale': null
                    }
                },
                'VerknuepfteEntitaet': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiWhere": ["Artikel.Id", {
                    "isBound": true,
                    "expression": "variables.data.$v_idArtikel"
                }]
            }]
        });
        this.addModel({
            "id": "$m_LagerbuchungInfoE",
            "filters": []
        });
        this.addVariable({
            "id": "$v_idArtikel"
        });
        this.addFunction("$f_LagerbuchungInfoListNestedUtils", $f_LagerbuchungInfoListNestedUtils, "functions.$f_LagerbuchungInfoListNestedUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_lagpVerkEntBezeichnung",
                "caption": "lagerbuchung-info-list-nested.r_lagpverkentbezeichnung_caption",
                "bindTo": "IdVerknuepfteEntitaet",
                "minWidth": 100,
                "dataType": "string",
                "onCellPrepared": "functions.$f_LagerbuchungInfoListNestedUtils.onVerknEntitaetCellPrepared",
                "onCalculateFilterExpression": "functions.$f_LagerbuchungInfoListNestedUtils.onVerknEntitaetCalcFilter"
            }, {
                "id": "r_lagbFiliale",
                "caption": "lagerbuchung-info-list-nested.r_lagbfiliale_caption",
                "bindTo": "Lager.Filiale.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_lagbLager",
                "caption": "lagerbuchung-info-list-nested.r_lagblager_caption",
                "bindTo": "Lager.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_lagbAuftrag",
                "caption": "lagerbuchung-info-list-nested.r_lagbauftrag_caption",
                "bindTo": "MengeAuftrag",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_lagbReserviert",
                "caption": "lagerbuchung-info-list-nested.r_lagbreserviert_caption",
                "bindTo": "MengeReserviert",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_lagbBestellt",
                "caption": "lagerbuchung-info-list-nested.r_lagbbestellt_caption",
                "bindTo": "MengeBestellt",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_lagbProduktion",
                "caption": "lagerbuchung-info-list-nested.r_lagbproduktion_caption",
                "bindTo": "MengeProduktion",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_lagerbuchungInfoGridToolbarOptions",
                "optionsNameFQ": "r_lagerbuchungInfoGridToolbarOptions"
            },
            "caption": "lagerbuchung-info-list-nested.r_lagerbuchunginfogrid_caption",
            "binding": {
                "dataContext": "$m_LagerbuchungInfo"
            },
            "dataModel": "$m_LagerbuchungInfo",
            "editDataContext": "$m_LagerbuchungInfoE",
            "height": "auto",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lagerbuchungInfoGrid",
            "options": {
                "optionsName": "r_lagerbuchungInfoGridOptions",
                "optionsNameFQ": "r_lagerbuchungInfoGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}