import {
  autoinject
} from "aurelia-framework";
import {
  EntitaetInfoService
} from './entitaet-info-service';
import { LocationService } from '../../framework/base/services/location-service';

@autoinject
export class VerknuepfteEntitaetService {
  constructor(
    private entitaetInfo: EntitaetInfoService,
    private locationService: LocationService
  ) { }

  async goTo(id: number, clearStack: boolean = false) {
    const url = await this.entitaetInfo
      .getCommonBaseUrlById(id);

    if (url == void 0) {
      return;
    }

    this.locationService.goTo({
      url: url,
      clearStack: clearStack
    });
  }
}
