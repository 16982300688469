import * as fwx from "../../../../framework/forms/form-export";
import {
    AngebotEditUtils
} from "./angebot-edit-utils";
import {
    StatusService
} from "../../../services/status-service";
import {
    UpdateManuelleTexteUtils
} from "../../../utils/update-manuelle-texte-utils";

@fwx.autoinject
export class AngebotEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_AngebotEditUtils: AngebotEditUtils,
        private $f_StatusService: StatusService,
        private $f_UpdateManuelleTexteUtils: UpdateManuelleTexteUtils) {
        super(element, formBaseImport);
        this.id = "angebot-edit";
        this.title = "angebot-edit.angebot-edit_caption";
        this.addModel({
            "id": "$m_Angebot",
            "webApiAction": "ERP/Verkauf/Angebot",
            "webApiExpand": {
                'Steuer': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_AngebotspositionRel",
            "webApiAction": "ERP/Verkauf/Angebotsposition",
            "webApiExpand": {
                'Artikel': null,
                'Angebotsgruppe': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
                'Positionsart': null
            },
            "webApiWhere": ["IsPositionStuecklistenkomponente", false],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Angebot.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_AngebotspositionEdit",
            "webApiAction": "ERP/Verkauf/Angebotsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AngebotsgruppeRel",
            "webApiAction": "ERP/Verkauf/Angebotsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Angebot.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_AngebotsgruppeEdit",
            "webApiAction": "ERP/Verkauf/Angebotsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Angebotsposition",
            "webApiAction": "ERP/Verkauf/Angebotsposition",
            "webApiExpand": {
                'Artikel': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
                'Positionsart': null
            },
            "webApiWhere": [
                ["IdAngebot", {
                    "isBound": true,
                    "expression": "models.data.$m_Angebot.Id"
                }],
                ["Status.Typ", "0"]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AngebotEditUtils.auftragErstellenCommand",
                "bindToFQ": "functions.$f_AngebotEditUtils.auftragErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AngebotEditUtils.doExport",
                "bindToFQ": "functions.$f_AngebotEditUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AngebotEditUtils.neueKonditionenLaden",
                "bindToFQ": "functions.$f_AngebotEditUtils.neueKonditionenLaden"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AngebotEditUtils.angebotKopieErstellenCommand",
                "bindToFQ": "functions.$f_AngebotEditUtils.angebotKopieErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AngebotEditUtils.positionenSortierenCommand",
                "bindToFQ": "functions.$f_AngebotEditUtils.positionenSortierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AngebotEditUtils.refreshGewichtCommand",
                "bindToFQ": "functions.$f_AngebotEditUtils.refreshGewichtCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateKopftextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateKopftextCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateFusstextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateFusstextCommand"
            }
        });
        this.addEditPopup({
            "idContent": "angebotspositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_AngebotspositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_AngebotspositionEdit.Id"
                }
            }],
            "id": "angebotspositionEditPopup",
            "options": {
                "optionsName": "angebotspositionEditPopupOptions",
                "optionsNameFQ": "angebotspositionEditPopupOptions"
            },
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "angebotsgruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_AngebotsgruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_AngebotsgruppeEdit.Id"
                }
            }],
            "id": "angebotsgruppeEditPopup",
            "options": {
                "optionsName": "angebotsgruppeEditPopupOptions",
                "optionsNameFQ": "angebotsgruppeEditPopupOptions"
            },
            "height": "500px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_AngebotEditUtils", $f_AngebotEditUtils, "functions.$f_AngebotEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_UpdateManuelleTexteUtils", $f_UpdateManuelleTexteUtils, "functions.$f_UpdateManuelleTexteUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "angebot-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Angebot.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "angebot-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Angebot.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "angebotsart",
            "filters": [],
            "caption": "angebot-edit.r_angebotsart_caption",
            "isReadOnlyExpression": "models.data.$m_Angebot.Id > 0",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "IdAngebotsart",
                "bindToFQ": "models.data.$m_Angebot.IdAngebotsart"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_angebotsart",
            "options": {
                "optionsName": "r_angebotsartOptions",
                "optionsNameFQ": "r_angebotsartOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "angebot-edit.r_datum_caption",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Angebot.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "angebot-edit.r_gueltigvon_caption",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_Angebot.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigVon",
            "options": {
                "optionsName": "r_gueltigVonOptions",
                "optionsNameFQ": "r_gueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "angebot-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_Angebot.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPositionen",
                "caption": "angebot-edit.r_tabpositionen_caption"
            }, {
                "id": "r_tabGruppen",
                "caption": "angebot-edit.r_tabgruppen_caption"
            }, {
                "id": "r_tabAdressen",
                "caption": "angebot-edit.r_tabadressen_caption"
            }, {
                "id": "r_tabWeitere",
                "caption": "angebot-edit.r_tabweitere_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_angpPosNr",
                "caption": "angebot-edit.r_angpposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_angpArtikelNr",
                "caption": "angebot-edit.r_angpartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_angpBezeichnung",
                "caption": "angebot-edit.r_angpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_angpMengeVerpEH",
                "caption": "angebot-edit.r_angpmengeverpeh_caption",
                "bindTo": "MengeVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_angpEinheit",
                "caption": "angebot-edit.r_angpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_angpPositionsart",
                "caption": "angebot-edit.r_angppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_angpAngebotsgruppe",
                "caption": "angebot-edit.r_angpangebotsgruppe_caption",
                "bindTo": "Angebotsgruppe.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_angpVariante",
                "caption": "angebot-edit.r_angpvariante_caption",
                "bindTo": "IsVariante",
                "width": "70px"
            }, {
                "id": "r_angpWertNetto",
                "caption": "angebot-edit.r_angpwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_angpArtStatus",
                "caption": "angebot-edit.r_angpartstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_angebotspositionGridToolbarOptions",
                "optionsNameFQ": "r_angebotspositionGridToolbarOptions"
            },
            "caption": "angebot-edit.r_angebotspositiongrid_caption",
            "binding": {
                "dataContext": "$m_Angebot"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_AngebotspositionRel",
                "bindTo": "IdAngebot",
                "bindToFQ": "models.data.$m_AngebotspositionRel.IdAngebot"
            },
            "dataModel": "$m_Angebot",
            "editDataContext": "$m_AngebotspositionEdit",
            "idEditPopup": "angebotspositionEditPopup",
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "bindTo": "$f_AngebotEditUtils.doCopyPosCommand",
                    "bindToFQ": "functions.$f_AngebotEditUtils.doCopyPosCommand"
                }
            }, {
                "binding": {
                    "bindTo": "$f_AngebotEditUtils.doDeletePosCommand",
                    "bindToFQ": "functions.$f_AngebotEditUtils.doDeletePosCommand"
                }
            }],
            "id": "r_angebotspositionGrid",
            "options": {
                "optionsName": "r_angebotspositionGridOptions",
                "optionsNameFQ": "r_angebotspositionGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_anggPosNr",
                "caption": "angebot-edit.r_anggposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_anggBezeichnung",
                "caption": "angebot-edit.r_anggbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_anggIsVariante",
                "caption": "angebot-edit.r_anggisvariante_caption",
                "bindTo": "IsVariante",
                "width": "100px"
            }, {
                "id": "r_anggWertnetto",
                "caption": "angebot-edit.r_anggwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_angebotsgruppeGridToolbarOptions",
                "optionsNameFQ": "r_angebotsgruppeGridToolbarOptions"
            },
            "caption": "angebot-edit.r_angebotsgruppegrid_caption",
            "binding": {
                "dataContext": "$m_Angebot"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_AngebotsgruppeRel",
                "bindTo": "IdAngebot",
                "bindToFQ": "models.data.$m_AngebotsgruppeRel.IdAngebot"
            },
            "dataModel": "$m_Angebot",
            "editDataContext": "$m_AngebotsgruppeEdit",
            "idEditPopup": "angebotsgruppeEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_angebotsgruppeGrid",
            "options": {
                "optionsName": "r_angebotsgruppeGridOptions",
                "optionsNameFQ": "r_angebotsgruppeGridOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "angebot-edit.r_lieferorttelefon_caption",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "LieferortTelefon",
                "bindToFQ": "models.data.$m_Angebot.LieferortTelefon"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_lieferortTelefon",
            "options": {
                "optionsName": "r_lieferortTelefonOptions",
                "optionsNameFQ": "r_lieferortTelefonOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "angebot-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Angebot.Id > 0",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Angebot.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filter": ["IdFirma", {
                "isBound": true,
                "expression": "models.data.$m_Angebot.IdFirma"
            }],
            "filters": [],
            "caption": "angebot-edit.r_lieferfiliale_caption",
            "isReadOnlyExpression": "models.data.$m_Angebot.Id > 0",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "IdLieferfiliale",
                "bindToFQ": "models.data.$m_Angebot.IdLieferfiliale"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferfiliale",
            "options": {
                "optionsName": "r_lieferfilialeOptions",
                "optionsNameFQ": "r_lieferfilialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerVerkauf",
            "filter": ["IdFiliale", {
                "isBound": true,
                "expression": "models.data.$m_Angebot.IdLieferfiliale"
            }],
            "filters": [],
            "caption": "angebot-edit.r_lieferlager_caption",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "IdLieferlager",
                "bindToFQ": "models.data.$m_Angebot.IdLieferlager"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferlager",
            "options": {
                "optionsName": "r_lieferlagerOptions",
                "optionsNameFQ": "r_lieferlagerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "angebot-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Angebot.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "angebot-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Angebot.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "angebot-edit.r_preisdatum_caption",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "Preisdatum",
                "bindToFQ": "models.data.$m_Angebot.Preisdatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_preisdatum",
            "options": {
                "optionsName": "r_preisdatumOptions",
                "optionsNameFQ": "r_preisdatumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "angebot-edit.r_gewichtbrutto_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "GewichtBrutto",
                "bindToFQ": "models.data.$m_Angebot.GewichtBrutto"
            },
            "validationRules": [],
            "id": "r_gewichtBrutto",
            "options": {
                "optionsName": "r_gewichtBruttoOptions",
                "optionsNameFQ": "r_gewichtBruttoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "angebot-edit.r_gewichtverp_caption",
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "GewichtVerpackung",
                "bindToFQ": "models.data.$m_Angebot.GewichtVerpackung"
            },
            "validationRules": [],
            "id": "r_gewichtVerp",
            "options": {
                "optionsName": "r_gewichtVerpOptions",
                "optionsNameFQ": "r_gewichtVerpOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuerTyp",
            "filters": [],
            "caption": "angebot-edit.r_steuersteuertyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Angebot",
                "bindTo": "Steuer.SteuerTyp",
                "bindToFQ": "models.data.$m_Angebot.Steuer.SteuerTyp",
                "propertyPrefix": "Steuer"
            },
            "validationRules": [],
            "id": "r_steuerSteuertyp",
            "options": {
                "optionsName": "r_steuerSteuertypOptions",
                "optionsNameFQ": "r_steuerSteuertypOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}