import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class KassaBelegListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "kassa-beleg-list";
        this.title = "kassa-beleg-list.kassa-beleg-list_caption";
        this.addModel({
            "id": "$m_KassaBeleg",
            "webApiAction": "ERP/Kassa/KassaBeleg",
            "webApiExpand": {
                'Zahlungsart': null,
                'Kassa': null,
                'Firma': null,
                'Kunde': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Waehrung': null,
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "allowSave": "false",
            "allowDelete": "false",
            "custom": {
                'addTagToDataGrids': [
                    'r_kassaBelegGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_kassaBelKassa",
                "caption": "kassa-beleg-list.r_kassabelkassa_caption",
                "bindTo": "Kassa.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_kassaBelFirma",
                "caption": "kassa-beleg-list.r_kassabelfirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_kassaBelNummer",
                "caption": "kassa-beleg-list.r_kassabelnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_kassaBelDatum",
                "caption": "kassa-beleg-list.r_kassabeldatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_kassaBelTyp",
                "caption": "kassa-beleg-list.r_kassabeltyp_caption",
                "bindTo": "Typ",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.KassaBelegTyp"
            }, {
                "id": "r_kassaBelFirmenbez1",
                "caption": "kassa-beleg-list.r_kassabelfirmenbez1_caption",
                "bindTo": "Kunde.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_kassaBelText",
                "caption": "kassa-beleg-list.r_kassabeltext_caption",
                "bindTo": "Text",
                "minWidth": 100
            }, {
                "id": "r_kassaBelZahlungsart",
                "caption": "kassa-beleg-list.r_kassabelzahlungsart_caption",
                "bindTo": "Zahlungsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_kassaBelAufRechnung",
                "caption": "kassa-beleg-list.r_kassabelaufrechnung_caption",
                "bindTo": "IsAufRechnung",
                "width": "80px"
            }, {
                "id": "r_kassaBelWaehrung",
                "caption": "kassa-beleg-list.r_kassabelwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_kassaBelZahlbetrag",
                "caption": "kassa-beleg-list.r_kassabelzahlbetrag_caption",
                "bindTo": "Zahlbetrag",
                "width": "90px",
                "format": "n2"
            }, {
                "id": "r_kassaBelSignaturStatus",
                "caption": "kassa-beleg-list.r_kassabelsignaturstatus_caption",
                "bindTo": "SignaturStatus",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.KassaBelegSignaturStatus"
            }, {
                "id": "r_kassaBelStatus",
                "caption": "kassa-beleg-list.r_kassabelstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_kassaBelegGridToolbarOptions",
                "optionsNameFQ": "r_kassaBelegGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_KassaBeleg"
            },
            "dataModel": "$m_KassaBeleg",
            "editUrl": "ERP/Kassa/KassaBeleg",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kassaBelegGrid",
            "options": {
                "optionsName": "r_kassaBelegGridOptions",
                "optionsNameFQ": "r_kassaBelegGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}