import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    KundenreklamationEditUtils
} from "./kundenreklamation-edit-utils";
import {
    UpdateManuelleTexteUtils
} from "../../../utils/update-manuelle-texte-utils";

@fwx.autoinject
export class KundenreklamationEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_KundenreklamationEditUtils: KundenreklamationEditUtils,
        private $f_UpdateManuelleTexteUtils: UpdateManuelleTexteUtils) {
        super(element, formBaseImport);
        this.id = "kundenreklamation-edit";
        this.title = "kundenreklamation-edit.kundenreklamation-edit_caption";
        this.addModel({
            "id": "$m_Kundenreklamation",
            "webApiAction": "ERP/Verkauf/Kundenreklamation",
            "webApiExpand": {
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_KundenreklamationspositionRel",
            "webApiAction": "ERP/Verkauf/Kundenreklamationsposition",
            "webApiExpand": {
                'Lieferscheinposition': {
                    'expand': {
                        'Lieferschein': null
                    }
                },
                'Artikel': null,
                'Einheit': {
                    'columns': [
                        'Id',
                        'IdEinheit'
                    ],
                    'expand': {
                        'Einheit': {
                            'columns': [
                                'Bezeichnung'
                            ]
                        }
                    }
                }
            },
            "webApiWhere": ["IsPositionStuecklistenkomponente", false],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_KundenreklamationspositionEdit",
            "webApiAction": "ERP/Verkauf/Kundenreklamationsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Lieferscheinpositionen",
            "webApiAction": "ERP/Verkauf/Lieferscheinposition",
            "webApiExpand": {
                'Lieferschein': {
                    'expand': {
                        'Waehrung': null
                    }
                },
                'Status': null,
                'Artikel': null
            },
            "webApiWhere": [
                ["Lieferschein.IdKunde", {
                    "isBound": true,
                    "expression": "models.data.$m_Kundenreklamation.IdKunde"
                }],
                ["Lieferschein.Status.Typ", "1"],
                ["MengeReklamationOffenVerpEH", ">", 0],
                ["Lieferschein.IdFirma", {
                    "isBound": true,
                    "expression": "models.data.$m_Kundenreklamation.IdFirma"
                }],
                ["IsPositionStuecklistenkomponente", false]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_KundenreklamationEditUtils.warenuebernahmeErstellenCommand",
                "bindToFQ": "functions.$f_KundenreklamationEditUtils.warenuebernahmeErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_KundenreklamationEditUtils.reklamationAbschliessenCommand",
                "bindToFQ": "functions.$f_KundenreklamationEditUtils.reklamationAbschliessenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_KundenreklamationEditUtils.showImportLieferscheinpositionenPopupCommand",
                "bindToFQ": "functions.$f_KundenreklamationEditUtils.showImportLieferscheinpositionenPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_KundenreklamationEditUtils.ersatzlieferscheinErstellenCommand",
                "bindToFQ": "functions.$f_KundenreklamationEditUtils.ersatzlieferscheinErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateKopftextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateKopftextCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateFusstextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateFusstextCommand"
            }
        });
        this.addEditPopup({
            "idContent": "kundenreklamationspositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_KundenreklamationspositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_KundenreklamationspositionEdit.Id"
                }
            }, {
                "to": "$v_idGeschaeftspartner",
                "binding": {
                    "dataContext": "$m_Kundenreklamation",
                    "bindTo": "IdKunde",
                    "bindToFQ": "models.data.$m_Kundenreklamation.IdKunde"
                }
            }],
            "id": "kundenreklamationspositionEditPopup",
            "options": {
                "optionsName": "kundenreklamationspositionEditPopupOptions",
                "optionsNameFQ": "kundenreklamationspositionEditPopupOptions"
            },
            "caption": "kundenreklamation-edit.kundenreklamationspositioneditpopup_caption",
            "width": "85%",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_KundenreklamationEditUtils", $f_KundenreklamationEditUtils, "functions.$f_KundenreklamationEditUtils");
        this.addFunction("$f_UpdateManuelleTexteUtils", $f_UpdateManuelleTexteUtils, "functions.$f_UpdateManuelleTexteUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "kundenreklamation-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Kundenreklamation.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "kundenreklamation-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Kundenreklamation.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "kundenreklamation-edit.r_datum_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Kundenreklamation.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "reklamationsgrund",
            "filters": [],
            "caption": "kundenreklamation-edit.r_reklamationsgrund_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "IdReklamationsgrund",
                "bindToFQ": "models.data.$m_Kundenreklamation.IdReklamationsgrund"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_reklamationsgrund",
            "options": {
                "optionsName": "r_reklamationsgrundOptions",
                "optionsNameFQ": "r_reklamationsgrundOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPositionen",
                "caption": "kundenreklamation-edit.r_tabpositionen_caption"
            }, {
                "id": "r_tabAdressen",
                "caption": "kundenreklamation-edit.r_tabadressen_caption"
            }, {
                "id": "r_tabWeitere",
                "caption": "kundenreklamation-edit.r_tabweitere_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_reklpArtikelNummer",
                "caption": "kundenreklamation-edit.r_reklpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_reklpBezeichnug",
                "caption": "kundenreklamation-edit.r_reklpbezeichnug_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_reklpLieferscheinNummer",
                "caption": "kundenreklamation-edit.r_reklplieferscheinnummer_caption",
                "bindTo": "Lieferscheinposition.Lieferschein.Nummer",
                "width": "80px"
            }, {
                "id": "r_reklpMengelVerpEH",
                "caption": "kundenreklamation-edit.r_reklpmengelverpeh_caption",
                "bindTo": "MengeVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_reklpMengeRetourniertVerpEH",
                "caption": "kundenreklamation-edit.r_reklpmengeretourniertverpeh_caption",
                "bindTo": "MengeRetourniertVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_reklpMengeGutschriftVerpEH",
                "caption": "kundenreklamation-edit.r_reklpmengegutschriftverpeh_caption",
                "bindTo": "MengeGutschriftVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_reklpEinheit",
                "caption": "kundenreklamation-edit.r_reklpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_kundenreklamationspositionenGridToolbarOptions",
                "optionsNameFQ": "r_kundenreklamationspositionenGridToolbarOptions"
            },
            "caption": "kundenreklamation-edit.r_kundenreklamationspositionengrid_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_KundenreklamationspositionRel",
                "bindTo": "IdKundenreklamation",
                "bindToFQ": "models.data.$m_KundenreklamationspositionRel.IdKundenreklamation"
            },
            "dataModel": "$m_Kundenreklamation",
            "editDataContext": "$m_KundenreklamationspositionEdit",
            "idEditPopup": "kundenreklamationspositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kundenreklamationspositionenGrid",
            "options": {
                "optionsName": "r_kundenreklamationspositionenGridOptions",
                "optionsNameFQ": "r_kundenreklamationspositionenGridOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "kundenreklamation-edit.r_lieferorttelefon_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "LieferortTelefon",
                "bindToFQ": "models.data.$m_Kundenreklamation.LieferortTelefon"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_lieferortTelefon",
            "options": {
                "optionsName": "r_lieferortTelefonOptions",
                "optionsNameFQ": "r_lieferortTelefonOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "kundenreklamation-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Kundenreklamation.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "kundenreklamation-edit.r_firma_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Kundenreklamation.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "kundenreklamation-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Kundenreklamation.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filter": ["IdFirma", {
                "isBound": true,
                "expression": "models.data.$m_Kundenreklamation.IdFirma"
            }],
            "filters": [],
            "caption": "kundenreklamation-edit.r_lieferfiliale_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "IdLieferfiliale",
                "bindToFQ": "models.data.$m_Kundenreklamation.IdLieferfiliale"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferfiliale",
            "options": {
                "optionsName": "r_lieferfilialeOptions",
                "optionsNameFQ": "r_lieferfilialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerVerkauf",
            "filter": ["IdFiliale", {
                "isBound": true,
                "expression": "models.data.$m_Kundenreklamation.IdLieferfiliale"
            }],
            "filters": [],
            "caption": "kundenreklamation-edit.r_lieferlager_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "IdLieferlager",
                "bindToFQ": "models.data.$m_Kundenreklamation.IdLieferlager"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferlager",
            "options": {
                "optionsName": "r_lieferlagerOptions",
                "optionsNameFQ": "r_lieferlagerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "kundenreklamation-edit.r_scandokumentnr_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation",
                "bindTo": "ScanDokumentNr",
                "bindToFQ": "models.data.$m_Kundenreklamation.ScanDokumentNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_scanDokumentNr",
            "options": {
                "optionsName": "r_scanDokumentNrOptions",
                "optionsNameFQ": "r_scanDokumentNrOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_lieferscheinpositionenEditPopup",
            "options": {
                "optionsName": "r_lieferscheinpositionenEditPopupOptions",
                "optionsNameFQ": "r_lieferscheinpositionenEditPopupOptions"
            },
            "caption": "kundenreklamation-edit.r_lieferscheinpositioneneditpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_KundenreklamationEditUtils.importLieferscheinpositionenCommand",
                    "bindToFQ": "functions.$f_KundenreklamationEditUtils.importLieferscheinpositionenCommand"
                }
            }]
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_liefLieferscheinNummer",
                "caption": "kundenreklamation-edit.r_lieflieferscheinnummer_caption",
                "bindTo": "Lieferschein.Nummer",
                "width": "80px"
            }, {
                "id": "r_liefLieferscheinDatum",
                "caption": "kundenreklamation-edit.r_lieflieferscheindatum_caption",
                "bindTo": "Lieferschein.Datum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_liefArtikelNr",
                "caption": "kundenreklamation-edit.r_liefartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_liefBezeichnung",
                "caption": "kundenreklamation-edit.r_liefbezeichnung_caption",
                "bindTo": "Bezeichnung"
            }, {
                "id": "r_liefMengeIstVerpEH",
                "caption": "kundenreklamation-edit.r_liefmengeistverpeh_caption",
                "bindTo": "MengeIstVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefMengeReklamiertVerpEH",
                "caption": "kundenreklamation-edit.r_liefmengereklamiertverpeh_caption",
                "bindTo": "MengeReklamiertVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefWertNetto",
                "caption": "kundenreklamation-edit.r_liefwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefWaehrung",
                "caption": "kundenreklamation-edit.r_liefwaehrung_caption",
                "bindTo": "Lieferschein.Waehrung.ISO4217",
                "width": "70px"
            }],
            "optionsToolbar": {
                "optionsName": "r_lieferscheinpositionenGridToolbarOptions",
                "optionsNameFQ": "r_lieferscheinpositionenGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Lieferscheinpositionen"
            },
            "dataModel": "$m_Lieferscheinpositionen",
            "selectionMode": 2,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lieferscheinpositionenGrid",
            "options": {
                "optionsName": "r_lieferscheinpositionenGridOptions",
                "optionsNameFQ": "r_lieferscheinpositionenGridOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}