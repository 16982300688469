import * as fwx from "../../../../framework/forms/form-export";
import {
    EingangsrechnungEditUtils
} from "./eingangsrechnung-edit-utils";
import {
    StatusService
} from "../../../services/status-service";
import {
    UpdateManuelleTexteUtils
} from "../../../utils/update-manuelle-texte-utils";

@fwx.autoinject
export class EingangsrechnungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EingangsrechnungEditUtils: EingangsrechnungEditUtils,
        private $f_StatusService: StatusService,
        private $f_UpdateManuelleTexteUtils: UpdateManuelleTexteUtils) {
        super(element, formBaseImport);
        this.id = "eingangsrechnung-edit";
        this.title = "eingangsrechnung-edit.eingangsrechnung-edit_caption";
        this.addModel({
            "id": "$m_Eingangsrechnung",
            "webApiAction": "ERP/Einkauf/Eingangsrechnung",
            "webApiExpand": {
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_EingangsrechnungspositionRel",
            "webApiAction": "ERP/Einkauf/Eingangsrechnungsposition",
            "webApiExpand": {
                'Artikel': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Positionsart': null,
                'Warenuebernahmeposition': {
                    'expand': {
                        'Warenuebernahme': null
                    }
                },
                'Bestellungsposition': {
                    'expand': {
                        'Bestellung': null
                    }
                }
            },
            "webApiWhere": ["IsPositionStuecklistenkomponente", false],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Eingangsrechnung.CanSave && !models.data.$m_Eingangsrechnung.IsIntercompany",
            "filters": []
        });
        this.addModel({
            "id": "$m_EingangsrechnungspositionEdit",
            "webApiAction": "ERP/Einkauf/Eingangsrechnungsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Warenuebernahmeposition",
            "webApiAction": "ERP/Einkauf/Warenuebernahmeposition",
            "webApiExpand": {
                'Warenuebernahme': null,
                'Artikel': null,
                'Bestellungsposition': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Positionsart': null,
                'Status': null
            },
            "webApiWhere": [
                ["Warenuebernahme.IdLieferant", {
                    "isBound": true,
                    "expression": "models.data.$m_Eingangsrechnung.IdLieferant"
                }],
                ["IsEingangsrechnungErledigt", false],
                ["IsPositionStuecklistenkomponente", false],
                ["Warenuebernahme.IdFirma", {
                    "isBound": true,
                    "expression": "models.data.$m_Eingangsrechnung.IdFirma"
                }]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "if": "models.data.$m_WarenuebernahmepositionFilter.FilterLS",
                "webApiCustomKey": "IdEingangsrechnungFilterLS",
                "webApiCustomValue": "models.data.$m_Eingangsrechnung.Id"
            }]
        });
        this.addModel({
            "id": "$m_EingangsrechnungszuschlagRel",
            "webApiAction": "ERP/Einkauf/Eingangsrechnungszuschlag",
            "webApiExpand": {
                'Zuschlagsart': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Eingangsrechnung.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_EingangsrechnungszuschlagEdit",
            "webApiAction": "ERP/Einkauf/Eingangsrechnungszuschlag",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ProdFertigware",
            "webApiAction": "ERP/Produktion/ProdFertigware",
            "webApiExpand": {
                'ProdAuftrag': {
                    'expand': {
                        'Artikel': null,
                        'Status': null,
                        'Einheit': {
                            'expand': {
                                'Einheit': null
                            }
                        }
                    }
                }
            },
            "webApiWhere": [
                ["ProdAuftrag.Status.Typ", "0"],
                ["ProdAuftrag.IdLieferant", {
                    "isBound": true,
                    "expression": "models.data.$m_Eingangsrechnung.IdLieferant"
                }],
                ["ProdAuftrag.IdFirma", {
                    "isBound": true,
                    "expression": "models.data.$m_Eingangsrechnung.IdFirma"
                }]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_WarenuebernahmepositionFilter",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EingangsrechnungEditUtils.doAbschliessenCommand",
                "bindToFQ": "functions.$f_EingangsrechnungEditUtils.doAbschliessenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EingangsrechnungEditUtils.doExport",
                "bindToFQ": "functions.$f_EingangsrechnungEditUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EingangsrechnungEditUtils.showImportWarenuebernahmepositionenPopupCommand",
                "bindToFQ": "functions.$f_EingangsrechnungEditUtils.showImportWarenuebernahmepositionenPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EingangsrechnungEditUtils.showImportProduktionPopupCommand",
                "bindToFQ": "functions.$f_EingangsrechnungEditUtils.showImportProduktionPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EingangsrechnungEditUtils.doBezahltAendernCommand",
                "bindToFQ": "functions.$f_EingangsrechnungEditUtils.doBezahltAendernCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateKopftextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateKopftextCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateFusstextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateFusstextCommand"
            }
        });
        this.addEditPopup({
            "idContent": "eingangsrechnungpositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_EingangsrechnungspositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_EingangsrechnungspositionEdit.Id"
                }
            }, {
                "to": "$v_idGeschaeftspartner",
                "binding": {
                    "dataContext": "$m_Eingangsrechnung",
                    "bindTo": "IdLieferant",
                    "bindToFQ": "models.data.$m_Eingangsrechnung.IdLieferant"
                }
            }],
            "id": "eingangsrechnungpositionEditPopup",
            "options": {
                "optionsName": "eingangsrechnungpositionEditPopupOptions",
                "optionsNameFQ": "eingangsrechnungpositionEditPopupOptions"
            },
            "caption": "eingangsrechnung-edit.eingangsrechnungpositioneditpopup_caption",
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "eingangsrechnungszuschlagEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_EingangsrechnungszuschlagEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_EingangsrechnungszuschlagEdit.Id"
                }
            }],
            "id": "eingangsrechnungszuschlagEditPopup",
            "options": {
                "optionsName": "eingangsrechnungszuschlagEditPopupOptions",
                "optionsNameFQ": "eingangsrechnungszuschlagEditPopupOptions"
            },
            "caption": "eingangsrechnung-edit.eingangsrechnungszuschlageditpopup_caption",
            "width": "1000px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "zahlungsausgangEditPopupContent",
            "mappings": [{
                "to": "$v_IdEingangsrechnung",
                "binding": {
                    "dataContext": "$m_Eingangsrechnung",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Eingangsrechnung.Id"
                }
            }, {
                "to": "$id",
                "binding": {
                    "dataContext": "$f_EingangsrechnungEditUtils",
                    "bindTo": "id0",
                    "bindToFQ": "functions.$f_EingangsrechnungEditUtils.id0"
                }
            }],
            "id": "zahlungsausgangEditPopup",
            "options": {
                "optionsName": "zahlungsausgangEditPopupOptions",
                "optionsNameFQ": "zahlungsausgangEditPopupOptions"
            },
            "height": "auto",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_EingangsrechnungEditUtils", $f_EingangsrechnungEditUtils, "functions.$f_EingangsrechnungEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_UpdateManuelleTexteUtils", $f_UpdateManuelleTexteUtils, "functions.$f_UpdateManuelleTexteUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "eingangsrechnung-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Eingangsrechnung.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "eingangsrechnung-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Eingangsrechnung.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "eingangsrechnung-edit.r_datum_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Eingangsrechnung.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "eingangsrechnungBezahltTyp",
            "filters": [],
            "caption": "eingangsrechnung-edit.r_bezahlttyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "BezahltTyp",
                "bindToFQ": "models.data.$m_Eingangsrechnung.BezahltTyp"
            },
            "validationRules": [],
            "id": "r_bezahltTyp",
            "options": {
                "optionsName": "r_bezahltTypOptions",
                "optionsNameFQ": "r_bezahltTypOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "eingangsrechnung-edit.r_lieferantlieferscheinnr_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "LieferantLieferscheinNr",
                "bindToFQ": "models.data.$m_Eingangsrechnung.LieferantLieferscheinNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_lieferantLieferscheinNr",
            "options": {
                "optionsName": "r_lieferantLieferscheinNrOptions",
                "optionsNameFQ": "r_lieferantLieferscheinNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "eingangsrechnung-edit.r_lieferantlieferscheindatum_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "LieferantLieferscheindatum",
                "bindToFQ": "models.data.$m_Eingangsrechnung.LieferantLieferscheindatum"
            },
            "validationRules": [],
            "id": "r_lieferantLieferscheindatum",
            "options": {
                "optionsName": "r_lieferantLieferscheindatumOptions",
                "optionsNameFQ": "r_lieferantLieferscheindatumOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "eingangsrechnung-edit.r_lieferantrechnungnr_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "LieferantRechnungNr",
                "bindToFQ": "models.data.$m_Eingangsrechnung.LieferantRechnungNr"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_lieferantRechnungNr",
            "options": {
                "optionsName": "r_lieferantRechnungNrOptions",
                "optionsNameFQ": "r_lieferantRechnungNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "eingangsrechnung-edit.r_lieferantrechnungsdatum_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "LieferantRechnungsdatum",
                "bindToFQ": "models.data.$m_Eingangsrechnung.LieferantRechnungsdatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferantRechnungsdatum",
            "options": {
                "optionsName": "r_lieferantRechnungsdatumOptions",
                "optionsNameFQ": "r_lieferantRechnungsdatumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnung-edit.r_wertbrutto_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "WertBrutto",
                "bindToFQ": "models.data.$m_Eingangsrechnung.WertBrutto"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_wertBrutto",
            "options": {
                "optionsName": "r_wertBruttoOptions",
                "optionsNameFQ": "r_wertBruttoOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "eingangsrechnung-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Eingangsrechnung.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnung-edit.r_differenz_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "Differenz",
                "bindToFQ": "models.data.$m_Eingangsrechnung.Differenz"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_EingangsrechnungEditUtils.onDifferenzValueChanged",
            "id": "r_differenz",
            "options": {
                "optionsName": "r_differenzOptions",
                "optionsNameFQ": "r_differenzOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnung-edit.r_wertnettogegenrechnung_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "WertNettoGegenrechnung",
                "bindToFQ": "models.data.$m_Eingangsrechnung.WertNettoGegenrechnung"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_EingangsrechnungEditUtils.onDifferenzValueChanged",
            "id": "r_wertNettoGegenrechnung",
            "options": {
                "optionsName": "r_wertNettoGegenrechnungOptions",
                "optionsNameFQ": "r_wertNettoGegenrechnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuerTypEingangsrechnung",
            "filters": [],
            "caption": "eingangsrechnung-edit.r_steuertyp_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "SteuerTyp",
                "bindToFQ": "models.data.$m_Eingangsrechnung.SteuerTyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_steuerTyp",
            "options": {
                "optionsName": "r_steuerTypOptions",
                "optionsNameFQ": "r_steuerTypOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPositionen",
                "caption": "eingangsrechnung-edit.r_tabpositionen_caption"
            }, {
                "id": "r_tabZuschlaege",
                "caption": "eingangsrechnung-edit.r_tabzuschlaege_caption"
            }, {
                "id": "r_tabWeitere",
                "caption": "eingangsrechnung-edit.r_tabweitere_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_einpArtikelNummer",
                "caption": "eingangsrechnung-edit.r_einpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_einpBezeichnung",
                "caption": "eingangsrechnung-edit.r_einpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_einpWarenuebernahmeNummer",
                "caption": "eingangsrechnung-edit.r_einpwarenuebernahmenummer_caption",
                "bindTo": "Warenuebernahmeposition.Warenuebernahme.Nummer",
                "width": "80px"
            }, {
                "id": "r_einpMengeSollVerpEH",
                "caption": "eingangsrechnung-edit.r_einpmengesollverpeh_caption",
                "bindTo": "MengeSollVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_einpMengeIstVerpEH",
                "caption": "eingangsrechnung-edit.r_einpmengeistverpeh_caption",
                "bindTo": "MengeIstVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_einpEinheit",
                "caption": "eingangsrechnung-edit.r_einpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_wertBruttoSoll",
                "caption": "eingangsrechnung-edit.r_wertbruttosoll_caption",
                "bindTo": "WertBruttoSoll",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_wertBruttoIst",
                "caption": "eingangsrechnung-edit.r_wertbruttoist_caption",
                "bindTo": "WertBruttoIst",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_eingangsrechnungspositionGridToolbarOptions",
                "optionsNameFQ": "r_eingangsrechnungspositionGridToolbarOptions"
            },
            "caption": "eingangsrechnung-edit.r_eingangsrechnungspositiongrid_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_EingangsrechnungspositionRel",
                "bindTo": "IdEingangsrechnung",
                "bindToFQ": "models.data.$m_EingangsrechnungspositionRel.IdEingangsrechnung"
            },
            "dataModel": "$m_Eingangsrechnung",
            "editDataContext": "$m_EingangsrechnungspositionEdit",
            "idEditPopup": "eingangsrechnungpositionEditPopup",
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_Eingangsrechnung",
                    "bindTo": "$f_EingangsrechnungEditUtils.doCopyPosCommand",
                    "bindToFQ": "functions.$f_EingangsrechnungEditUtils.doCopyPosCommand"
                }
            }, {
                "binding": {
                    "dataContext": "$m_Eingangsrechnung",
                    "bindTo": "$f_EingangsrechnungEditUtils.doDeletePosCommand",
                    "bindToFQ": "functions.$f_EingangsrechnungEditUtils.doDeletePosCommand"
                }
            }],
            "id": "r_eingangsrechnungspositionGrid",
            "options": {
                "optionsName": "r_eingangsrechnungspositionGridOptions",
                "optionsNameFQ": "r_eingangsrechnungspositionGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_einzZuschlagsart",
                "caption": "eingangsrechnung-edit.r_einzzuschlagsart_caption",
                "bindTo": "Zuschlagsart.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_einzWertBrutto",
                "caption": "eingangsrechnung-edit.r_einzwertbrutto_caption",
                "bindTo": "WertBrutto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_einzKommentar",
                "caption": "eingangsrechnung-edit.r_einzkommentar_caption",
                "bindTo": "Kommentar",
                "minWidth": 100
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_eingangsrechnungszuschlaegeToolbarOptions",
                "optionsNameFQ": "r_eingangsrechnungszuschlaegeToolbarOptions"
            },
            "caption": "eingangsrechnung-edit.r_eingangsrechnungszuschlaege_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_EingangsrechnungszuschlagRel",
                "bindTo": "IdEingangsrechnung",
                "bindToFQ": "models.data.$m_EingangsrechnungszuschlagRel.IdEingangsrechnung"
            },
            "dataModel": "$m_Eingangsrechnung",
            "editDataContext": "$m_EingangsrechnungszuschlagEdit",
            "idEditPopup": "eingangsrechnungszuschlagEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_eingangsrechnungszuschlaege",
            "options": {
                "optionsName": "r_eingangsrechnungszuschlaegeOptions",
                "optionsNameFQ": "r_eingangsrechnungszuschlaegeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "eingangsrechnung-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Eingangsrechnung.Id > 0",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Eingangsrechnung.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "eingangsrechnung-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Eingangsrechnung.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "eingangsrechnung-edit.r_scandokumentnr_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "ScanDokumentNr",
                "bindToFQ": "models.data.$m_Eingangsrechnung.ScanDokumentNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_scanDokumentNr",
            "options": {
                "optionsName": "r_scanDokumentNrOptions",
                "optionsNameFQ": "r_scanDokumentNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "eingangsrechnung-edit.r_zahlungfaelligkeit_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "ZahlungFaelligkeit",
                "bindToFQ": "models.data.$m_Eingangsrechnung.ZahlungFaelligkeit"
            },
            "validationRules": [],
            "id": "r_zahlungFaelligkeit",
            "options": {
                "optionsName": "r_zahlungFaelligkeitOptions",
                "optionsNameFQ": "r_zahlungFaelligkeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "eingangsrechnung-edit.r_zahlungfaelligkeitskonto_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Eingangsrechnung",
                "bindTo": "ZahlungFaelligkeitSkonto",
                "bindToFQ": "models.data.$m_Eingangsrechnung.ZahlungFaelligkeitSkonto"
            },
            "validationRules": [],
            "id": "r_zahlungFaelligkeitSkonto",
            "options": {
                "optionsName": "r_zahlungFaelligkeitSkontoOptions",
                "optionsNameFQ": "r_zahlungFaelligkeitSkontoOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_warenuebernahmepositionEditPopup",
            "options": {
                "optionsName": "r_warenuebernahmepositionEditPopupOptions",
                "optionsNameFQ": "r_warenuebernahmepositionEditPopupOptions"
            },
            "caption": "eingangsrechnung-edit.r_warenuebernahmepositioneditpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_EingangsrechnungEditUtils.importWarenubernahmepositionenCommand",
                    "bindToFQ": "functions.$f_EingangsrechnungEditUtils.importWarenubernahmepositionenCommand"
                }
            }, {
                "binding": {
                    "bindTo": "$f_EingangsrechnungEditUtils.erledigenWarenubernahmepositionenCommand",
                    "bindToFQ": "functions.$f_EingangsrechnungEditUtils.erledigenWarenubernahmepositionenCommand"
                }
            }]
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "eingangsrechnung-edit.r_warenuebernahmefilterls_caption",
            "binding": {
                "dataContext": "$m_WarenuebernahmepositionFilter",
                "bindTo": "FilterLS",
                "bindToFQ": "models.data.$m_WarenuebernahmepositionFilter.FilterLS"
            },
            "validationRules": [],
            "id": "r_warenuebernahmeFilterLS",
            "options": {
                "optionsName": "r_warenuebernahmeFilterLSOptions",
                "optionsNameFQ": "r_warenuebernahmeFilterLSOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_warpWarenuebernahmeNummer",
                "caption": "eingangsrechnung-edit.r_warpwarenuebernahmenummer_caption",
                "bindTo": "Warenuebernahme.Nummer",
                "width": "80px"
            }, {
                "id": "r_warpDatum",
                "caption": "eingangsrechnung-edit.r_warpdatum_caption",
                "bindTo": "Warenuebernahme.Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_warpLSNummer",
                "caption": "eingangsrechnung-edit.r_warplsnummer_caption",
                "bindTo": "Warenuebernahme.LieferantLieferscheinNr",
                "width": "80px"
            }, {
                "id": "r_warpLSDatum",
                "caption": "eingangsrechnung-edit.r_warplsdatum_caption",
                "bindTo": "Warenuebernahme.LieferantLieferscheindatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_warpArtikelNummer",
                "caption": "eingangsrechnung-edit.r_warpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_warpArtikelBez",
                "caption": "eingangsrechnung-edit.r_warpartikelbez_caption",
                "bindTo": "Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_warpBezeichnung",
                "caption": "eingangsrechnung-edit.r_warpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_warpMengeEingangsrechnungOffenVerpEH",
                "caption": "eingangsrechnung-edit.r_warpmengeeingangsrechnungoffenverpeh_caption",
                "bindTo": "MengeEingangsrechnungOffenVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_warpEinheit",
                "caption": "eingangsrechnung-edit.r_warpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_warpPositionsart",
                "caption": "eingangsrechnung-edit.r_warppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }],
            "optionsToolbar": {
                "optionsName": "r_warenuebernahmepositionGridToolbarOptions",
                "optionsNameFQ": "r_warenuebernahmepositionGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Warenuebernahmeposition"
            },
            "dataModel": "$m_Warenuebernahmeposition",
            "selectionMode": 2,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_warenuebernahmepositionGrid",
            "options": {
                "optionsName": "r_warenuebernahmepositionGridOptions",
                "optionsNameFQ": "r_warenuebernahmepositionGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_prodFertigwareEditPopup",
            "options": {
                "optionsName": "r_prodFertigwareEditPopupOptions",
                "optionsNameFQ": "r_prodFertigwareEditPopupOptions"
            },
            "caption": "eingangsrechnung-edit.r_prodfertigwareeditpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_EingangsrechnungEditUtils.importProduktionCommand",
                    "bindToFQ": "functions.$f_EingangsrechnungEditUtils.importProduktionCommand"
                }
            }]
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_prodFProdAuftragNummer",
                "caption": "eingangsrechnung-edit.r_prodfprodauftragnummer_caption",
                "bindTo": "ProdAuftrag.Nummer",
                "width": "80px"
            }, {
                "id": "r_prodFDatum",
                "caption": "eingangsrechnung-edit.r_prodfdatum_caption",
                "bindTo": "ProdAuftrag.Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_prodFArtikelNummer",
                "caption": "eingangsrechnung-edit.r_prodfartikelnummer_caption",
                "bindTo": "ProdAuftrag.Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_prodFArtikelBezeichnung",
                "caption": "eingangsrechnung-edit.r_prodfartikelbezeichnung_caption",
                "bindTo": "ProdAuftrag.Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_prodFMengeVerpEH",
                "caption": "eingangsrechnung-edit.r_prodfmengeverpeh_caption",
                "bindTo": "MengeVerpEH",
                "width": "80px"
            }, {
                "id": "r_prodFEinheit",
                "caption": "eingangsrechnung-edit.r_prodfeinheit_caption",
                "bindTo": "ProdAuftrag.Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }],
            "optionsToolbar": {
                "optionsName": "r_prodFertigwareGridToolbarOptions",
                "optionsNameFQ": "r_prodFertigwareGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_ProdFertigware"
            },
            "dataModel": "$m_ProdFertigware",
            "selectionMode": 2,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_prodFertigwareGrid",
            "options": {
                "optionsName": "r_prodFertigwareGridOptions",
                "optionsNameFQ": "r_prodFertigwareGridOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}