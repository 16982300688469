import { autoinject, transient } from "aurelia-framework";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { LocalizationService, LocationService, RestService } from "../../../../framework/base/services/export";
import { FormBase, PopupInfoService } from "../../../../framework/forms/export";
import { IdxDataGridComponent, IdxPopupComponent } from "../../../interfaces/export";
import { EntitaetInfoService, StartupService } from "../../../services/export";
import { ICommandData } from "./../../../../framework/forms/interfaces/command-data";
import { ProjektDmsViewer } from "./../../../elements/projekt-dms-viewer/projekt-dms-viewer";

@autoinject
@transient()
export class ProjektEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public startupService: StartupService,
    private _entitaetInfoService: EntitaetInfoService,
    private _locationService: LocationService,
    private _localizationService: LocalizationService,
    private _popupInfoService: PopupInfoService,
    private _restService: RestService,
    private _asyncService: AsyncService) {
    this.showTaetigkeiten = startupService.startupInfo.Lizenz.HasProjektzeiterfassung;
  }

  projektDmsViewer: ProjektDmsViewer;
  idGeschaeftspartner: number;

  form: FormBase;

  showTaetigkeiten: boolean = false;

  geschaeftspartnerZuordnenCommand: ICommandData = {
    id: "geschaeftspartnerZuordnen",
    icon: "plus-circle",
    title: "projekt.geschaeftspartner-zuordnen",
    sortIndex: 1000,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      this.idGeschaeftspartner = null;

      const popup: IdxPopupComponent = this.form["r_geschaeftspartnerZuordnenPopup"];
      await this._asyncService.convertToPromise(popup.instance.show());

      const el = this.form["r_geschaeftspartnerElement"];
      el.focus();
    }
  };
  doGeschaeftspartnerZuordnen: ICommandData = {
    id: "doGeschaeftspartnerZuordnen",
    title: "projekt.zuordnen",
    icon: "plus-circle",
    execute: async () => {
      if (!this.idGeschaeftspartner) {
        return DevExpress.ui.notify(this._localizationService.translateOnce("projekt.geschaeftspartner-auswaehlen"), "ERROR", this.NOTIFY_TIMEOUT);
      }

      const r = await this._restService.post({
        url: this._restService.getWebApiUrl("ERP/Stammdaten/ProjektZuGeschaeftspartner"),
        data: {
          IdProjekt: this.form.models.data.$m_Projekt.Id,
          IdGeschaeftspartner: this.idGeschaeftspartner,
          IsManuell: true
        },
        increaseLoadingCount: true
      });

      DevExpress.ui.notify(this._localizationService.translateOnce("projekt.geschaeftspartner-erfolgreich-zugeordnet"), "SUCCESS", this.NOTIFY_TIMEOUT);
      this._popupInfoService.closeAllPopups();
      this.form.models.reloadAll();
    }
  };
  doGeschaeftspartnerZuordnungenEntfernen: ICommandData = {
    id: "doGeschaeftspartnerZuordnungenEntfernen",
    title: "projekt.zuordnungen-entfernen",
    icon: "trash",
    sortIndex: 1010,
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_proGeschaeftspartnerGrid"];
      const selectedRowsData: any[] = grid.instance.getSelectedRowsData();
      if (selectedRowsData.length === 0) {
        return DevExpress.ui.notify(this._localizationService.translateOnce("projekt.geschaeftspartner-auswaehlen"), "ERROR", this.NOTIFY_TIMEOUT);
      }

      const invalidSelectedKeys: any[] = selectedRowsData.filter((c) => c.IsManuell != true);
      if (invalidSelectedKeys.length && invalidSelectedKeys.length >= 1) {
        return DevExpress.ui.notify(this._localizationService.translateOnce("projekt.zuordnung-loeschen-nicht-moeglich"), "ERROR", this.NOTIFY_TIMEOUT);
      }

      for (const data of selectedRowsData.map((c) => c.Id)) {
        await this._restService.delete({
          url: this._restService.getWebApiUrl("ERP/Stammdaten/ProjektZuGeschaeftspartner"),
          id: data
        });
      }
      grid.instance.refresh();
    }
  };
  projektdateienCommand: ICommandData = {
    id: "projektdateienCommand",
    title: "projekt.projektdateien",
    idCategory: "projekt",
    icon: "file-o",
    isEnabledExpression: "!models.data.$m_Projekt.IsInaktiv && models.data.$m_Projekt.Id",
    execute: async () => {
      const projektDmsViewerPopup: ProjektDmsViewer = this.form["r_projektDmsViewerElement"];
      projektDmsViewerPopup.show(this.form.models.data.$m_Projekt.Id);
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.focusFirstEnabledEditor = () => {
      if (form["r_bezeichnung"].instance) {
        form["r_bezeichnung"].instance.focus();
      }
    };

    this.form.models.onLoaded.register((r) => {
      if (r.model.id == "$m_ProjektZuGeschaeftspartnerRel") {
        if (r.data && r.data.data && Array.isArray(r.data.data)) {
          for (const item of r.data.data) {
            if (!item.Geschaeftspartner || !item.Geschaeftspartner.IdStatus) {
              continue;
            }

            item["IdStatus"] = item.Geschaeftspartner.IdStatus;
          }
        }
      }
      return Promise.resolve();
    });
  }

  onBewClicked(e: any) {
    this._entitaetInfoService
      .getUrl(e.item.TypeName, e.item.Id)
      .then(async (r) => {
        if (!r) {
          DevExpress.ui.notify("Die Entität ist nicht definiert");
          return;
        }

        const where = ["IdCboProjekt", e.item.IdCboProjekt];
        const orderBy = [{ columnName: "Aenderungsdatum", sortOrder: 1 }];

        const viewScrollInfo = await this._entitaetInfoService.createViewScrollInfo(
          e.item.TypeName,
          where,
          null,
          orderBy,
          e.item.Id
        );

        this._locationService.goTo({
          url: r,
          clearStack: false,
          viewScrollInfo: viewScrollInfo
        });

        if (this._popupInfoService.isPopupOpen()) {
          this._popupInfoService.closeCurrentPopup();
        }
      });
  }
  onReservierungsgradCellPrepared(e): void {
    if (e.rowType != "data" || e.data.IsBestaetigt == false || e.data.Status.Typ !== 0) {
      return;
    }

    const cell: HTMLTableCellElement = e.cellElement;

    cell.style.color = "white";
    cell.style.backgroundColor = e.data.Reservierungsgrad < 1
      ? "#C1392D"
      : (e.data.HasArtikelNichtLagerfuehrendOffen ? "#566473" : "#2DCC70");
  }
  onZahlungFaelligCellPrepared(e): void {
    if (e.rowType != "data" || e.data.BezahltTyp == void (0) || !e.data.ZahlungFaelligkeit || !e.data.Status || e.data.Status.Typ != 1) {
      return;
    }
    if (e.data.BezahltTyp != 0 && e.data.BezahltTyp != 2) {
      return;
    }

    const cell: HTMLTableCellElement = e.cellElement;

    cell.style.color = "white";
    cell.style.backgroundColor = e.data.ZahlungFaelligkeit.getTime() >= new Date().getTime()
      ? "#2DCC70"
      : "#C1392D";
  }
}
