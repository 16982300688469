import * as fwx from "../../../../framework/forms/form-export";
import {
    AuftragEditUtils
} from "./auftrag-edit-utils";
import {
    StatusService
} from "../../../services/status-service";
import {
    UpdateManuelleTexteUtils
} from "../../../utils/update-manuelle-texte-utils";

@fwx.autoinject
export class AuftragEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_AuftragEditUtils: AuftragEditUtils,
        private $f_StatusService: StatusService,
        private $f_UpdateManuelleTexteUtils: UpdateManuelleTexteUtils) {
        super(element, formBaseImport);
        this.id = "auftrag-edit";
        this.title = "auftrag-edit.auftrag-edit_caption";
        this.addModel({
            "id": "$m_Auftrag",
            "webApiAction": "ERP/Verkauf/Auftrag",
            "webApiExpand": {
                'Steuer': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_AuftragspositionRel",
            "webApiAction": "ERP/Verkauf/Auftragsposition",
            "webApiExpand": {
                'Artikel': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
                'Positionsart': null,
                'Auftragsgruppe': null,
                'Angebotsposition': {
                    'expand': {
                        'Angebot': null
                    }
                }
            },
            "webApiWhere": ["IsPositionStuecklistenkomponente", false],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Auftrag.CanSave && !models.data.$m_Auftrag.IsIcAuftrag",
            "filters": []
        });
        this.addModel({
            "id": "$m_AuftragspositionEdit",
            "webApiAction": "ERP/Verkauf/Auftragsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Angebotsposition",
            "webApiAction": "ERP/Verkauf/Angebotsposition",
            "webApiExpand": {
                'Artikel': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
                'Positionsart': null,
                'Angebot': {
                    'expand': {
                        'KundeAdresse': null,
                        'Kunde': null
                    }
                }
            },
            "webApiWhere": [
                ["Status.Typ", "0"],
                ["IsPositionStuecklistenkomponente", false],
                ["Angebot.IdKunde", {
                    "isBound": true,
                    "expression": "models.data.$m_Auftrag.IdKunde"
                }],
                ["Angebot.IdFirma", {
                    "isBound": true,
                    "expression": "models.data.$m_Auftrag.IdFirma"
                }]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Lieferschein",
            "webApiAction": "ERP/Verkauf/Lieferschein",
            "webApiExpand": {
                'Kunde': null,
                'KundeAdresse': null,
                'Status': null
            },
            "webApiWhere": [
                ["Status.Typ", "0"],
                ["IdKunde", {
                    "isBound": true,
                    "expression": "models.data.$m_Auftrag.IdKunde"
                }],
                ["IdFirma", {
                    "isBound": true,
                    "expression": "models.data.$m_Auftrag.IdFirma"
                }],
                ["MergeKey", {
                    "isBound": true,
                    "expression": "models.data.$m_Auftrag.MergeKey"
                }]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "IdAuftrag",
                "webApiCustomValue": "models.data.$m_Auftrag.Id"
            }]
        });
        this.addModel({
            "id": "$m_AbschlagsfakturaRel",
            "webApiAction": "ERP/Verkauf/Abschlagfaktura",
            "webApiExpand": {
                'Faktura': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Auftrag.CanSave && !models.data.$m_Auftrag.IsIcAuftrag",
            "filters": []
        });
        this.addModel({
            "id": "$m_AbschlagsfakturaEdit",
            "webApiAction": "ERP/Verkauf/Abschlagfaktura",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AuftragsgruppeRel",
            "webApiAction": "ERP/Verkauf/Auftragsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Auftrag.CanSave && !models.data.$m_Auftrag.IsIcAuftrag",
            "filters": []
        });
        this.addModel({
            "id": "$m_AuftragsgruppeEdit",
            "webApiAction": "ERP/Verkauf/Auftragsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.showLieferscheinErstellenCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.showLieferscheinErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.bestellungErstellenCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.bestellungErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.icBestellungErstellenCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.icBestellungErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.bestaetigenCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.bestaetigenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.reservierenCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.reservierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.reservierungEntfernenCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.reservierungEntfernenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.doExport",
                "bindToFQ": "functions.$f_AuftragEditUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.showImportAngebotspositionenPopupCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.showImportAngebotspositionenPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.neueKonditionenLaden",
                "bindToFQ": "functions.$f_AuftragEditUtils.neueKonditionenLaden"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.auftragKopieErstellenCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.auftragKopieErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.positionenSortierenCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.positionenSortierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.refreshGewichtCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.refreshGewichtCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragEditUtils.recalcLieferdatumCommand",
                "bindToFQ": "functions.$f_AuftragEditUtils.recalcLieferdatumCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateKopftextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateKopftextCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateFusstextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateFusstextCommand"
            }
        });
        this.addEditPopup({
            "idContent": "auftragspositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_AuftragspositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_AuftragspositionEdit.Id"
                }
            }, {
                "to": "$v_idGeschaeftspartner",
                "binding": {
                    "dataContext": "$m_Auftrag",
                    "bindTo": "IdKunde",
                    "bindToFQ": "models.data.$m_Auftrag.IdKunde"
                }
            }],
            "id": "auftragspositionEditPopup",
            "options": {
                "optionsName": "auftragspositionEditPopupOptions",
                "optionsNameFQ": "auftragspositionEditPopupOptions"
            },
            "caption": "auftrag-edit.auftragspositioneditpopup_caption",
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "abschlagsfakturaEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_AbschlagsfakturaEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_AbschlagsfakturaEdit.Id"
                }
            }],
            "id": "abschlagsfakturaEditPopup",
            "options": {
                "optionsName": "abschlagsfakturaEditPopupOptions",
                "optionsNameFQ": "abschlagsfakturaEditPopupOptions"
            },
            "caption": "auftrag-edit.abschlagsfakturaeditpopup_caption",
            "height": "400px",
            "width": "700px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "auftragsgruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_AuftragsgruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_AuftragsgruppeEdit.Id"
                }
            }],
            "id": "auftragsgruppeEditPopup",
            "options": {
                "optionsName": "auftragsgruppeEditPopupOptions",
                "optionsNameFQ": "auftragsgruppeEditPopupOptions"
            },
            "height": "500px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_AuftragEditUtils", $f_AuftragEditUtils, "functions.$f_AuftragEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_UpdateManuelleTexteUtils", $f_UpdateManuelleTexteUtils, "functions.$f_UpdateManuelleTexteUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "auftrag-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Auftrag.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "auftrag-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Auftrag.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "auftragsart",
            "filters": [],
            "caption": "auftrag-edit.r_auftragsart_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "IdAuftragsart",
                "bindToFQ": "models.data.$m_Auftrag.IdAuftragsart"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_auftragsart",
            "options": {
                "optionsName": "r_auftragsartOptions",
                "optionsNameFQ": "r_auftragsartOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "auftrag-edit.r_datum_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Auftrag.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "auftrag-edit.r_lieferdatum_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "Lieferdatum",
                "bindToFQ": "models.data.$m_Auftrag.Lieferdatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_AuftragEditUtils.onLieferdatumValueChanged",
            "id": "r_lieferdatum",
            "options": {
                "optionsName": "r_lieferdatumOptions",
                "optionsNameFQ": "r_lieferdatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "auftrag-edit.r_berechnlieferdatum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "BerechnetLieferdatum",
                "bindToFQ": "models.data.$m_Auftrag.BerechnetLieferdatum"
            },
            "validationRules": [],
            "id": "r_berechnLieferdatum",
            "options": {
                "optionsName": "r_berechnLieferdatumOptions",
                "optionsNameFQ": "r_berechnLieferdatumOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPositionen",
                "caption": "auftrag-edit.r_tabpositionen_caption"
            }, {
                "id": "r_tabGruppen",
                "caption": "auftrag-edit.r_tabgruppen_caption"
            }, {
                "id": "r_tabAbschlagsfaktura",
                "caption": "auftrag-edit.r_tababschlagsfaktura_caption"
            }, {
                "id": "r_tabAdressen",
                "caption": "auftrag-edit.r_tabadressen_caption"
            }, {
                "id": "r_tabWeitere",
                "caption": "auftrag-edit.r_tabweitere_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_aufpPosNr",
                "caption": "auftrag-edit.r_aufpposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_aufpArtikelNr",
                "caption": "auftrag-edit.r_aufpartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_aufpBezeichnung",
                "caption": "auftrag-edit.r_aufpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_aufpAngebotNr",
                "caption": "auftrag-edit.r_aufpangebotnr_caption",
                "bindTo": "Angebotsposition.Angebot.Nummer",
                "width": "80px"
            }, {
                "id": "r_aufpMengeVerpEH",
                "caption": "auftrag-edit.r_aufpmengeverpeh_caption",
                "bindTo": "MengeVerpEH",
                "width": "auto",
                "format": "n2"
            }, {
                "id": "r_aufpMengeOffenVerpEH",
                "caption": "auftrag-edit.r_aufpmengeoffenverpeh_caption",
                "bindTo": "MengeOffenVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpMengeResVerpEH",
                "caption": "auftrag-edit.r_aufpmengeresverpeh_caption",
                "bindTo": "MengeReserviertVerpEH",
                "width": "80px",
                "format": "n2",
                "onCellPrepared": "functions.$f_AuftragEditUtils.onMengeReserviertCellPrepared"
            }, {
                "id": "r_aufpMengeLieferscheinVerpEH",
                "caption": "auftrag-edit.r_aufpmengelieferscheinverpeh_caption",
                "bindTo": "MengeLieferscheinVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpMengeGeliefertVerpEH",
                "caption": "auftrag-edit.r_aufpmengegeliefertverpeh_caption",
                "bindTo": "MengeGeliefertVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpEinheit",
                "caption": "auftrag-edit.r_aufpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_aufpPositionsart",
                "caption": "auftrag-edit.r_aufppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_aufpAuftragsgruppe",
                "caption": "auftrag-edit.r_aufpauftragsgruppe_caption",
                "bindTo": "Auftragsgruppe.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_aufpWertNetto",
                "caption": "auftrag-edit.r_aufpwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpBerechnLieferdatum",
                "caption": "auftrag-edit.r_aufpberechnlieferdatum_caption",
                "bindTo": "BerechnetLieferdatum",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_AuftragEditUtils.onBerechnLieferdatumCellPrepared"
            }, {
                "id": "r_aufpStatus",
                "caption": "auftrag-edit.r_aufpstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_auftragspositionGridToolbarOptions",
                "optionsNameFQ": "r_auftragspositionGridToolbarOptions"
            },
            "caption": "auftrag-edit.r_auftragspositiongrid_caption",
            "binding": {
                "dataContext": "$m_Auftrag"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_AuftragspositionRel",
                "bindTo": "IdAuftrag",
                "bindToFQ": "models.data.$m_AuftragspositionRel.IdAuftrag"
            },
            "dataModel": "$m_Auftrag",
            "editDataContext": "$m_AuftragspositionEdit",
            "idEditPopup": "auftragspositionEditPopup",
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_Auftrag",
                    "bindTo": "$f_AuftragEditUtils.doCopyPosCommand",
                    "bindToFQ": "functions.$f_AuftragEditUtils.doCopyPosCommand"
                }
            }, {
                "binding": {
                    "dataContext": "$m_Auftrag",
                    "bindTo": "$f_AuftragEditUtils.doDeletePosCommand",
                    "bindToFQ": "functions.$f_AuftragEditUtils.doDeletePosCommand"
                }
            }],
            "id": "r_auftragspositionGrid",
            "options": {
                "optionsName": "r_auftragspositionGridOptions",
                "optionsNameFQ": "r_auftragspositionGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_anggPosNr",
                "caption": "auftrag-edit.r_anggposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_anggBezeichnung",
                "caption": "auftrag-edit.r_anggbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_anggWertnetto",
                "caption": "auftrag-edit.r_anggwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_auftragsgruppeGridToolbarOptions",
                "optionsNameFQ": "r_auftragsgruppeGridToolbarOptions"
            },
            "caption": "auftrag-edit.r_auftragsgruppegrid_caption",
            "binding": {
                "dataContext": "$m_Auftrag"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_AuftragsgruppeRel",
                "bindTo": "IdAuftrag",
                "bindToFQ": "models.data.$m_AuftragsgruppeRel.IdAuftrag"
            },
            "dataModel": "$m_Auftrag",
            "editDataContext": "$m_AuftragsgruppeEdit",
            "idEditPopup": "auftragsgruppeEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_auftragsgruppeGrid",
            "options": {
                "optionsName": "r_auftragsgruppeGridOptions",
                "optionsNameFQ": "r_auftragsgruppeGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_abfBezeichnung",
                "caption": "auftrag-edit.r_abfbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_abfWertNetto",
                "caption": "auftrag-edit.r_abfwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_abfSteuersatz",
                "caption": "auftrag-edit.r_abfsteuersatz_caption",
                "bindTo": "Steuersatz",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_abfVorausinkasso",
                "caption": "auftrag-edit.r_abfvorausinkasso_caption",
                "bindTo": "IsVorausinkasso",
                "width": "100px"
            }, {
                "id": "r_abfIsSchluss",
                "caption": "auftrag-edit.r_abfisschluss_caption",
                "bindTo": "IsSchluss",
                "width": "100px"
            }, {
                "id": "r_abfNummer",
                "caption": "auftrag-edit.r_abfnummer_caption",
                "bindTo": "Faktura.Nummer",
                "width": "80px"
            }, {
                "id": "r_abfDatum",
                "caption": "auftrag-edit.r_abfdatum_caption",
                "bindTo": "Faktura.Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_abfBezahlt",
                "caption": "auftrag-edit.r_abfbezahlt_caption",
                "bindTo": "Faktura.BezahltTyp",
                "width": "100px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.FakturaBezahltTyp"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_abschlagsfakturaGridToolbarOptions",
                "optionsNameFQ": "r_abschlagsfakturaGridToolbarOptions"
            },
            "caption": "auftrag-edit.r_abschlagsfakturagrid_caption",
            "binding": {
                "dataContext": "$m_Auftrag"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_AbschlagsfakturaRel",
                "bindTo": "IdAuftrag",
                "bindToFQ": "models.data.$m_AbschlagsfakturaRel.IdAuftrag"
            },
            "dataModel": "$m_Auftrag",
            "editDataContext": "$m_AbschlagsfakturaEdit",
            "idEditPopup": "abschlagsfakturaEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$f_AuftragEditUtils",
                    "bindTo": "vorauskasseCommand",
                    "bindToFQ": "functions.$f_AuftragEditUtils.vorauskasseCommand"
                }
            }],
            "id": "r_abschlagsfakturaGrid",
            "options": {
                "optionsName": "r_abschlagsfakturaGridOptions",
                "optionsNameFQ": "r_abschlagsfakturaGridOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "auftrag-edit.r_lieferorttelefon_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "LieferortTelefon",
                "bindToFQ": "models.data.$m_Auftrag.LieferortTelefon"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_lieferortTelefon",
            "options": {
                "optionsName": "r_lieferortTelefonOptions",
                "optionsNameFQ": "r_lieferortTelefonOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "auftrag-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.Id > 0",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Auftrag.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filter": ["IdFirma", {
                "isBound": true,
                "expression": "models.data.$m_Auftrag.IdFirma"
            }],
            "filters": [],
            "caption": "auftrag-edit.r_lieferfiliale_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.Id > 0",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "IdLieferfiliale",
                "bindToFQ": "models.data.$m_Auftrag.IdLieferfiliale"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferfiliale",
            "options": {
                "optionsName": "r_lieferfilialeOptions",
                "optionsNameFQ": "r_lieferfilialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerVerkauf",
            "filter": ["IdFiliale", {
                "isBound": true,
                "expression": "models.data.$m_Auftrag.IdLieferfiliale"
            }],
            "filters": [],
            "caption": "auftrag-edit.r_lieferlager_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsStreckengeschaeft || models.data.$m_Auftrag.IsIcAuftrag || models.data.$m_Auftrag.IsIntercompany",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "IdLieferlager",
                "bindToFQ": "models.data.$m_Auftrag.IdLieferlager"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferlager",
            "options": {
                "optionsName": "r_lieferlagerOptions",
                "optionsNameFQ": "r_lieferlagerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "auftrag-edit.r_sachbearbeiter_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Auftrag.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "auftrag-edit.r_waehrung_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Auftrag.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "auftrag-edit.r_preisdatum_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "Preisdatum",
                "bindToFQ": "models.data.$m_Auftrag.Preisdatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_preisdatum",
            "options": {
                "optionsName": "r_preisdatumOptions",
                "optionsNameFQ": "r_preisdatumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "auftrag-edit.r_gewichtbrutto_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "GewichtBrutto",
                "bindToFQ": "models.data.$m_Auftrag.GewichtBrutto"
            },
            "validationRules": [],
            "id": "r_gewichtBrutto",
            "options": {
                "optionsName": "r_gewichtBruttoOptions",
                "optionsNameFQ": "r_gewichtBruttoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "auftrag-edit.r_gewichtverpackung_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "GewichtVerpackung",
                "bindToFQ": "models.data.$m_Auftrag.GewichtVerpackung"
            },
            "validationRules": [],
            "id": "r_gewichtVerpackung",
            "options": {
                "optionsName": "r_gewichtVerpackungOptions",
                "optionsNameFQ": "r_gewichtVerpackungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "intercompany",
            "filters": [],
            "caption": "auftrag-edit.r_intercompany_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "IdIntercompany",
                "bindToFQ": "models.data.$m_Auftrag.IdIntercompany"
            },
            "validationRules": [],
            "id": "r_intercompany",
            "options": {
                "optionsName": "r_intercompanyOptions",
                "optionsNameFQ": "r_intercompanyOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuerTyp",
            "filters": [],
            "caption": "auftrag-edit.r_steuersteuertyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "Steuer.SteuerTyp",
                "bindToFQ": "models.data.$m_Auftrag.Steuer.SteuerTyp",
                "propertyPrefix": "Steuer"
            },
            "validationRules": [],
            "id": "r_steuerSteuertyp",
            "options": {
                "optionsName": "r_steuerSteuertypOptions",
                "optionsNameFQ": "r_steuerSteuertypOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_angebotspositionenPopup",
            "options": {
                "optionsName": "r_angebotspositionenPopupOptions",
                "optionsNameFQ": "r_angebotspositionenPopupOptions"
            },
            "caption": "auftrag-edit.r_angebotspositionenpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_AuftragEditUtils.importAngebotspositionenCommand",
                    "bindToFQ": "functions.$f_AuftragEditUtils.importAngebotspositionenCommand"
                }
            }]
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_angpAngebotNummer",
                "caption": "auftrag-edit.r_angpangebotnummer_caption",
                "bindTo": "Angebot.Nummer",
                "width": "80px"
            }, {
                "id": "r_angpDatum",
                "caption": "auftrag-edit.r_angpdatum_caption",
                "bindTo": "Angebot.Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_angpArtikelNummer",
                "caption": "auftrag-edit.r_angpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_angpBezeichnung",
                "caption": "auftrag-edit.r_angpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_angpMengeVerpEH",
                "caption": "auftrag-edit.r_angpmengeverpeh_caption",
                "bindTo": "MengeVerpEH",
                "width": "auto",
                "format": "n2"
            }, {
                "id": "r_angpEinheit",
                "caption": "auftrag-edit.r_angpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_angpPositionsart",
                "caption": "auftrag-edit.r_angppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_angpStatus",
                "caption": "auftrag-edit.r_angpstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_angebotspositionenGridToolbarOptions",
                "optionsNameFQ": "r_angebotspositionenGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Angebotsposition"
            },
            "dataModel": "$m_Angebotsposition",
            "selectionMode": 2,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_angebotspositionenGrid",
            "options": {
                "optionsName": "r_angebotspositionenGridOptions",
                "optionsNameFQ": "r_angebotspositionenGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_lieferscheinErstellenPopup",
            "options": {
                "optionsName": "r_lieferscheinErstellenPopupOptions",
                "optionsNameFQ": "r_lieferscheinErstellenPopupOptions"
            },
            "caption": "auftrag-edit.r_lieferscheinerstellenpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_AuftragEditUtils.zuMarkiertemLieferscheinHinzufuegenCommand",
                    "bindToFQ": "functions.$f_AuftragEditUtils.zuMarkiertemLieferscheinHinzufuegenCommand"
                }
            }, {
                "binding": {
                    "bindTo": "$f_AuftragEditUtils.neuenLieferscheinErstellenCommand",
                    "bindToFQ": "functions.$f_AuftragEditUtils.neuenLieferscheinErstellenCommand"
                }
            }]
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_liefsLieferscheinNummer",
                "caption": "auftrag-edit.r_liefslieferscheinnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_liefsDatum",
                "caption": "auftrag-edit.r_liefsdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_liefsKundeNummer",
                "caption": "auftrag-edit.r_liefskundenummer_caption",
                "bindTo": "Kunde.Nummer",
                "width": "80px"
            }, {
                "id": "r_liefsFirmenbez1",
                "caption": "auftrag-edit.r_liefsfirmenbez1_caption",
                "bindTo": "KundeAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_liefsStatus",
                "caption": "auftrag-edit.r_liefsstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_lieferscheinGridToolbarOptions",
                "optionsNameFQ": "r_lieferscheinGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Lieferschein"
            },
            "dataModel": "$m_Lieferschein",
            "selectionMode": 1,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lieferscheinGrid",
            "options": {
                "optionsName": "r_lieferscheinGridOptions",
                "optionsNameFQ": "r_lieferscheinGridOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}