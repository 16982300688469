import * as fwx from "../../../../framework/forms/form-export";
import {
    LagerbuchungListNestedUtils
} from "./lagerbuchung-list-nested-utils";

@fwx.autoinject
export class LagerbuchungListNestedForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_LagerbuchungListNestedUtils: LagerbuchungListNestedUtils) {
        super(element, formBaseImport);
        this.id = "lagerbuchung-list-nested";
        this.title = "lagerbuchung-list-nested.lagerbuchung-list-nested_caption";
        this.addModel({
            "id": "$m_Lagerbuchung",
            "webApiAction": "ERP/Lager/Lagerbuchung",
            "webApiExpand": {
                'Artikel': null,
                'LagerVon': {
                    'columns': [
                        'Id',
                        'BezeichnungFQ'
                    ]
                },
                'LagerNach': {
                    'columns': [
                        'Id',
                        'BezeichnungFQ'
                    ]
                },
                'Lagerbuchungsart': {
                    'columns': [
                        'Id',
                        'Bezeichnung'
                    ]
                },
                'VerknuepfteEntitaet': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiWhere": ["Artikel.Id", {
                    "isBound": true,
                    "expression": "variables.data.$v_idArtikel"
                }]
            }]
        });
        this.addModel({
            "id": "$m_LagerbuchungE",
            "filters": []
        });
        this.addVariable({
            "id": "$v_idArtikel"
        });
        this.addFunction("$f_LagerbuchungListNestedUtils", $f_LagerbuchungListNestedUtils, "functions.$f_LagerbuchungListNestedUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_lagpVerkEntBezeichnung",
                "caption": "lagerbuchung-list-nested.r_lagpverkentbezeichnung_caption",
                "bindTo": "IdVerknuepfteEntitaet",
                "minWidth": 100,
                "dataType": "string",
                "onCellPrepared": "functions.$f_LagerbuchungListNestedUtils.onVerknEntitaetCellPrepared",
                "onCalculateFilterExpression": "functions.$f_LagerbuchungListNestedUtils.onVerknEntitaetCalcFilter"
            }, {
                "id": "r_lagbLagerVon",
                "caption": "lagerbuchung-list-nested.r_lagblagervon_caption",
                "bindTo": "LagerVon.BezeichnungFQ",
                "minWidth": 100
            }, {
                "id": "r_lagbLagerNach",
                "caption": "lagerbuchung-list-nested.r_lagblagernach_caption",
                "bindTo": "LagerNach.BezeichnungFQ",
                "minWidth": 100
            }, {
                "id": "r_lagbDatum",
                "caption": "lagerbuchung-list-nested.r_lagbdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_lagbMHD",
                "caption": "lagerbuchung-list-nested.r_lagbmhd_caption",
                "bindTo": "MHD",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_lagbCharge",
                "caption": "lagerbuchung-list-nested.r_lagbcharge_caption",
                "bindTo": "Charge",
                "width": "90px"
            }, {
                "id": "r_lagbMenge",
                "caption": "lagerbuchung-list-nested.r_lagbmenge_caption",
                "bindTo": "Menge",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_lagpLagerbuchungsart",
                "caption": "lagerbuchung-list-nested.r_lagplagerbuchungsart_caption",
                "bindTo": "Lagerbuchungsart.Bezeichnung"
            }, {
                "id": "r_lagbKommentar",
                "caption": "lagerbuchung-list-nested.r_lagbkommentar_caption",
                "bindTo": "Kommentar",
                "minWidth": 100
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_lagerbuchungGridToolbarOptions",
                "optionsNameFQ": "r_lagerbuchungGridToolbarOptions"
            },
            "caption": "lagerbuchung-list-nested.r_lagerbuchunggrid_caption",
            "binding": {
                "dataContext": "$m_Lagerbuchung"
            },
            "dataModel": "$m_Lagerbuchung",
            "editDataContext": "$m_LagerbuchungE",
            "height": "auto",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lagerbuchungGrid",
            "options": {
                "optionsName": "r_lagerbuchungGridOptions",
                "optionsNameFQ": "r_lagerbuchungGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}