import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  ICommandData,
  FormBase,
  PopupInfoService
} from "../../../../framework/forms/export";
import {
  WebEventService
} from "../../../../framework/base/export";
import {
  OnBriefVersendenEvent,
  OnReportRequestEvent,
  BriefVersendenTyp,
} from "../../../../framework-data/events";
import {
  DruckenService, StartupService
} from "../../../services/export";
import { IFormCommandExecuteOptions } from "../../../interfaces/export";

@autoinject
@transient()
export class BriefEditUtils {
  constructor(
    private webEvent: WebEventService,
    private popupInfo: PopupInfoService,
    private drucken: DruckenService,
    private startupService: StartupService
  ) { }

  form: FormBase;
  showEditor: boolean = true;

  showDivAdresse: ICommandData = {
    id: "showDivAdresse",
    idCategory: "brief-entitaet",
    title: "brief.show_div_adresse",
    sortIndex: 10,
    isVisibleExpression: "models.data.$m_Brief.CanSave && !models.data.$m_Brief.HasDiverseAdresse",
    execute: (options) => {
      const model = this.form.models.data.$m_Brief;

      if (!model.IdAdresse && !model.Adresse) {
        model.Adresse = {};
      }

      model.HasDiverseAdresse = true;
    }
  }
  hideDivAdresse: ICommandData = {
    id: "hideDivAdresse",
    idCategory: "brief-entitaet",
    title: "brief.hide_div_adresse",
    sortIndex: 10,
    isVisibleExpression: "models.data.$m_Brief.CanSave && models.data.$m_Brief.HasDiverseAdresse",
    execute: (options) => {
      const model = this.form.models.data.$m_Brief;
      model.HasDiverseAdresse = false;
    }
  }
  doVersendenEmail: ICommandData = {
    id: "doVersendenEmail",
    idCategory: "brief-entitaet",
    title: "brief.send_email",
    icon: "envelope-o",
    sortIndex: 20,
    isEnabledExpression: "models.data.$m_Brief.CanSave",
    execute: (options) => {
      this.execute(BriefVersendenTyp.Email, options);
    }
  }
  doDrucken: ICommandData = {
    id: "doDrucken",
    idCategory: "brief-entitaet",
    title: "brief.print",
    icon: "print",
    sortIndex: 21,
    isEnabled: true,
    isVisible: true,
    execute: (options) => {
      this.execute(BriefVersendenTyp.Drucken, options);
    }
  }
  doDruckvorschau: ICommandData = {
    id: "doDruckvorschau",
    idCategory: "brief-entitaet",
    title: "brief.print-preview",
    icon: "file-text-o",
    sortIndex: 22,
    isEnabled: true,
    execute: (options) => {
      this.preview(options);
    }
  }
  showHtmlBriefOptions: DevExpress.ui.dxButtonOptions = {
    text: "Brief in neuem Fenster öffnen",
    onClick: () => {
      const w = window.open();
      w.document.write(this.form.models.data.$m_Brief.TextHtml);
      w.document.close();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.models.onLoaded.register(e => {
      if (e.model.id == "$m_Brief" && e.data) {
        this.showEditor = !e.data.TextHtml
          || e.data.TextHtml.indexOf("</body>") < 0;
      }

      return Promise.resolve();
    });

    this.doDrucken.isVisible = this.startupService.startupInfo.Mandant.IsDruckJobAktiv;
  }

  private async execute(versandTyp: BriefVersendenTyp, options) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const data: any = this.form.models.data;

    return this.webEvent
      .execute(new OnBriefVersendenEvent({
        IdBrief: this.form.models.data.$m_Brief.Id,
        IdVerknuepfteEntitaet: this.form.models.data.$m_Brief.IdVerknuepfteEntitaet,
        VersandTyp: versandTyp
      }), true)
      .then(() => {
        return this.form.models.reloadAll();
      })
      .then(() => {
        if (this.popupInfo.isPopupOpen()) {
          this.popupInfo.closeCurrentPopup();
        }
      });
  }
  private async preview(options) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const data: any = this.form.models.data;

    const execOptions: IFormCommandExecuteOptions = {
      form: this.form,
      modelData: this.form.models.data.$m_Brief,
      options: { event: options.event }
    };

    this.drucken.executeDruckCommand(execOptions);
  }
}
